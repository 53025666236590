<template>
	<div class="tooltip-hover" @mouseover="mouseover" @mouseout="mouseout">
		<Teleport to="body">
			<div
				class="tooltip-body"
				:class="{ fit, letter, transition }"
				:style="{ top, left, '--left': leftVar }"
				v-if="hover"
				ref="tooltipBody"
			>
				<div class="tooltip-text">
					<slot name="text" />
				</div>
				<img
					:src="require('../../assets/icons/tooltip.svg')"
					class="tooltip-icon"
					:class="{ top: iconTop }"
				/>
			</div>
		</Teleport>
		<div class="tooltip-root" ref="rootSlot">
			<slot name="root" />
		</div>
	</div>
</template>
<script lang="ts">
import { nextTick, toRefs, defineComponent, reactive, ref } from "vue"

export default defineComponent({
	props: {
		fit: {
			type: Boolean,
			default: false
		},
		letter: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		const state = reactive({
			hover: false,
			top: '0px',
			left: '0px',
			leftVar: '0px',
			iconTop: false,
			interval: false,
			transition: false
		})

		const rootSlot = ref<HTMLElement>()
		const tooltipBody = ref<HTMLElement>()

		const calc = (transition = false) => {
			if (!rootSlot.value || !rootSlot.value.firstElementChild) {
				return
			}

			state.transition = transition
			const {
				top,
				left,
				width,
				height
			} = rootSlot.value.firstElementChild.getBoundingClientRect()!

			nextTick(() => {
				nextTick(() => {
					if (!tooltipBody.value) {
						return
					}

					const windowWidth = window.innerWidth

					const {
						width: tooltipWidth,
						height: tooltipHeight,
						left: tooltipLeft,
						top: tooltipTop
					} = tooltipBody.value.getBoundingClientRect()!

					// if overflows right
					const leftDiff = tooltipLeft + tooltipWidth + 10 - windowWidth

					// if overflows left
					const rightDiff = tooltipLeft - 10

					if (leftDiff > 0) {
						state.leftVar = `${leftDiff}px`
					} else if (rightDiff < 0) {
						state.leftVar = `${rightDiff}px`
					}

					// if overflows top
					const topDiff = tooltipTop - 10

					if (topDiff < 0) {
						state.top = `${top + height + 10 + tooltipHeight}px`
						state.iconTop = true
					} else {
						state.iconTop = false
					}
				})
			})

			state.top = `${top - 10}px`
			state.left = `${left + width / 2}px`
		}

		const reset = () => {
			state.iconTop = false
			state.top = '0px'
			state.left = '0px'
			state.leftVar = '0px'
		}

		const mouseover = () => {
			state.hover = true
			calc()
		}

		const mouseout = () => {
			state.hover = false
			reset()
		}

		return {
			...toRefs(state),
			rootSlot,
			tooltipBody,
			calc,
			reset,
			mouseover,
			mouseout
		}
	}
})
</script>
<style scoped>
.tooltip-hover {
	position: relative;
}

.tooltip-body {
	--left: 0px;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 15px;
	padding: 20px;
	width: 250px;
	box-sizing: border-box;
	position: fixed;
	transform: translate(calc(-50% - var(--left)), -100%);
	z-index: 1001;
	backdrop-filter: blur(10px);
}

.tooltip-body.transition {
	/* transition: top 0.25s ease-in-out; */
}

.tooltip-body.fit {
	width: fit-content;
}

.tooltip-body.letter {
	word-break: break-all;
}

.tooltip-hover:hover .tooltip-body {
	display: flex;
}

.tooltip-text {
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #afb1c0;
	opacity: 0.6;
	margin: auto;
}

.tooltip-text b {
	color: #ffffff;
}

.tooltip-icon {
	position: absolute;
	left: calc(50% + var(--left));
	bottom: 0;
	transform: translate(-50%, 100%);
}

.tooltip-icon.top {
	bottom: unset;
	top: -12px;
	transform: translate(-50%, 100%) rotate(180deg);
}

.tooltip-root {
	display: contents;
}
</style>
