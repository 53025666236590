
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAPI } from '../../composables/api'

export default defineComponent({
	props: {
		small: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const { t, locale } = useI18n({ useScope: 'global' })
		const country = ref('RU')
		const api = useAPI()

		const getCountry = async () => {
			try {
				const res = await api({
					path: '/country',
					string: true
				})

				country.value = res
			} catch (error) {
				// silent
			}
		}

		onMounted(async () => {
			await getCountry()
		})
		return {
			t,
			locale,
			country
		}
	}
})
