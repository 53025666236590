
import { LangCode, languages, useLocaleAsync } from '../../composables/useLocale'
import Lang from './Lang.vue'
import { directive as clickOutside } from 'click-outside-vue3'
import { defineComponent, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		full: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Lang
	},
	directives: {
		clickOutside
	},
	setup(props) {
		const state = reactive({
			active: false,
			langCodes: languages().map(({ code }) => code)
		})

		const close = () => {
			state.active = false
		}
		
		const trigger = () => {
			state.active = !state.active
		}

		const setLang = (lang: LangCode) => {
			useLocaleAsync(lang)
		}

		const select = (lang: LangCode) => {
			setLang(lang)
			close()
		}

		const { locale } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			close,
			trigger,
			select,
			setLang,
			locale
		}
	}
})
