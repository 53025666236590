<template>
	<div class="lang-selector" :class="{ active, full, right }" v-click-outside="close">
		<div class="lang-btn lang-select-btn" @click="trigger">
			<Lang :code="modelValue" class="lang-wrap" :full="full" />
			<img :src="require('../../assets/icons/caret.svg')" class="lang-caret" />
		</div>
		<div class="lang-list">
			<div class="list-scrollable">
				<template v-for="code in langCodes">
					<div
						v-if="modelValue !== code"
						:key="code"
						class="lang-item"
						:class="[`country-${code}-btn`]"
						@click="select(code)"
					>
						<Lang :code="code" class="lang-wrap" :full="full || fullList" />
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import countries from '../../composables/countriesList'
import Lang from './Lang.vue'
import { defineComponent, onMounted, reactive } from '@vue/runtime-core'
import { directive as clickOutside } from 'click-outside-vue3'
import { toRefs } from 'vue'

export default defineComponent({
	props: {
		right: {
			type: Boolean,
			default: false
		},
		full: {
			type: Boolean,
			default: false
		},
		fullList: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: String,
			required: true
		}
	},
	components: {
		Lang
	},
	directives: {
		clickOutside
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const state = reactive({
			active: false,
			langCodes: [] as string[]
		})

		onMounted(() => {
			countries.sort((a, b) => {
				if (a.name > b.name) return 1
				if (a.name < b.name) return -1
				return 0
			})

			state.langCodes = countries.map(({ code }) => code)
		})

		const close = () => {
			state.active = false
		}

		const trigger = () => {
			state.active = !state.active
		}

		const select = (code: string) => {
			emit('update:modelValue', code)
			close()
		}

		return {
			...toRefs(state),
			close,
			trigger,
			select
		}
	}
})
</script>
<style scoped>
.lang-selector {
	position: relative;
	-moz-user-select: none;
	-webkit-user-select: none;
	display: flex;
}

.lang-selector.active .lang-btn {
	/* border-radius: 10px 10px 0 0; */
	background: #242731;
	border: 1px solid transparent;
}

.lang-selector.active .lang-list {
	display: flex;
	margin-top: -4.5px;
}

.lang-selector.active .lang-caret {
	transform: rotate(180deg);
	opacity: 1;
}

.lang-btn {
	display: flex;
	border: 1px solid rgba(175, 177, 192, 0.1);
	box-sizing: border-box;
	border-radius: 10px;
	height: 42px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	width: 100%;
}

.lang-selector.borderless .lang-btn {
	border: none;
}

.lang-selector.active .lang-name,
.lang-btn:hover .lang-caret {
	opacity: 1;
}

.lang-selector.active .lang-btn .lang-wrap,
.lang-btn:hover .lang-btn .lang-wrap,
.lang-item:hover .lang-wrap {
	color: #fff;
}

.lang-caret {
	margin: 6px 0 0 auto;
	opacity: 0.3;
	height: 6px;
	width: 10.5px;
}

.lang-list {
	display: none;
	position: absolute;
	background: #242731;
	border-radius: 10px;
	width: fit-content;
	flex-direction: column;
	top: 56px;
	max-height: 250px;
	padding: 5px 0;
	min-width: 0;
}

.lang-selector.right .lang-list {
	right: 0;
}

.list-scrollable {
	overflow-y: scroll;
	overflow-x: hidden;
	margin-right: 5px;
	min-width: 0;
}

.lang-item {
	display: flex;
	padding: 10px 15px;
	cursor: pointer;
}

.lang-selector.full .lang-item {
	padding: 10px 30px;
}

.lang-item >>> .lang-name {
	white-space: normal;
}
</style>
