import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useAPI } from '../composables/api'
import { useUserID } from '../composables/getAuthToken'
import { useAlertStore } from './alert'

export const useUserStore = defineStore({
	id: 'user',
	state() {
		return {
			id: -1,
			name: 'Loading...',
			avatar:
				'https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg',
			balanceUsd: 0,
			role: 0,
			tradetoken: ''
		}
	},
	actions: {
		async get() {
			const userID = useUserID()

			if (!userID) {
				return
			}

			const api = useAPI()

			try {
				this.$state = await api({ path: '/users/current' })

				// check if user is a streamer
				// and try enabling streamer mode
				if (
					this.role === 5 &&
					localStorage.getItem('streamerMode') !== '0'
				) {
					localStorage.setItem('streamerMode', '1')
				}
			} catch (err) {
				const alert = useAlertStore()
				const { t } = useI18n({ useScope: 'global' })

				alert.pushAlert({ type: 'danger', text: t('AppFrame.userError') })
			}
		}
	}
})
