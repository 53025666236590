<template>
	<div
		class="live-withdraw"
		:class="{ active }"
		:style="{
			'--active': color,
			'--background': background,
			'--border': border
		}"
	>
		<div class="withdraw-item">
			<ItemIcon :icon="icon" :size="116" class="item-icon" v-if="!method" />
			<div class="item-icon" v-else>
				<img :src="icon" class="item-method-icon" alt="" />
			</div>
			<div class="item-price">{{ amountNormalized }}</div>
			<svg
				width="6"
				height="6"
				viewBox="0 0 6 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				class="item-corner"
				v-if="active"
			>
				<path
					d="M4 6C1.79086 6 -6.7098e-08 4.20914 -1.49868e-07 2L-2.24801e-07 4.76837e-07L3 3L6 6L4 6Z"
					fill="currentColor"
				/>
			</svg>
		</div>
		<div class="withdraw-user">
			<img :src="avatar" class="user-avatar" :alt="name" />
			<div class="user-name">{{ name }}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import ItemIcon from '../ItemIcon/index.vue'

export default defineComponent({
	props: {
		id: {
			type: Number,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		amount: {
			type: Number,
			required: true
		},
		userID: {
			type: Number,
			required: true
		},
		avatar: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		method: {
			type: Boolean,
			required: true
		}
	},
	components: {
		ItemIcon
	},
	setup(props) {
		const amountNormalized = computed(() => {
			return `$${props.amount / 100}`
		})

		const active = computed(() => {
			return props.amount >= 2000
		})

		const color = computed(() => {
			if (props.amount >= 10000) {
				return '#fe932a'
			}
			if (props.amount >= 5000) {
				return '#9130DE'
			}
			if (props.amount >= 2000) {
				return '#2AAAFE'
			}

			return '#000000'
		})

		const background = computed(() => {
			const r = parseInt(color.value.slice(1, 3), 16)
			const g = parseInt(color.value.slice(3, 5), 16)
			const b = parseInt(color.value.slice(5, 7), 16)

			return `rgba(${r}, ${g}, ${b}, 0.1)`
		})

		const border = computed(() => {
			const r = parseInt(color.value.slice(1, 3), 16)
			const g = parseInt(color.value.slice(3, 5), 16)
			const b = parseInt(color.value.slice(5, 7), 16)

			return `rgba(${r}, ${g}, ${b}, 0.2)`
		})

		return {
			amountNormalized,
			active,
			color,
			background,
			border
		}
	}
})
</script>
<style scoped>
.live-withdraw {
	width: 58px;
	height: 71px;
	position: relative;
	flex-shrink: 0;
	box-sizing: border-box;
	border: 1px solid transparent;
	overflow: hidden;
}

.withdraw-enter-active.live-withdraw {
	transition: all 0.5s ease-out;
}

.withdraw-enter.live-withdraw {
	opacity: 0;
	margin-left: -58px;
}

.live-withdraw.active {
	border: 1px solid var(--border);
	border-radius: 8px;
	width: 64px;
}

.withdraw-item {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.live-withdraw.active .withdraw-item {
	background: linear-gradient(
			1.48deg,
			var(--background) 2.52%,
			rgba(31, 33, 42, 0) 100%
		),
		linear-gradient(180deg, rgba(43, 46, 57, 0) 0%, rgba(43, 46, 57, 0.5) 100%);
}

.live-withdraw:hover {
	background: #1c1d24;
	border: 1px solid transparent;
	border-radius: 8px;
}

.item-icon {
	height: 58px;
	width: 58px;
	margin: -6px auto 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.item-method-icon {
	height: 26px;
	width: 26px;
}

.item-price {
	font-weight: 700;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	margin-top: -6px;
	color: #a2a3ac;
}

.live-withdraw.active .item-price {
	color: var(--active);
}

.item-corner {
	position: absolute;
	bottom: 4px;
	left: 4px;
	color: var(--active);
}

.withdraw-user {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	text-decoration: none;
}

.live-withdraw:hover .withdraw-user {
	display: flex;
}

.live-withdraw:hover .withdraw-item {
	display: none;
}

.user-avatar {
	height: 24px;
	width: 24px;
	border-radius: 8px;
}

.user-name {
	margin-top: 12px;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	color: #ffffff;
	max-width: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
