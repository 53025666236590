
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		skins: {
			type: Number,
			required: true
		},
		total: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const { t } = useI18n({ useScope: 'global' })

		const totalNormalized = computed(() => {
			return `$${props.total / 100}`
		})

		return { t, totalNormalized }
	}
})
