<template>
	<div class="nav-bar-menu-container" v-click-outside="close">
		<div
			class="nav-bar-menu navbar-open-btn"
			:class="{ active: dropdown }"
			@click="trigger"
		>
			<svg
				class="menu-icon"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				<path
					d="M4.49762 12H12.4976"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M4.49762 16.0017H16.4976"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M4.49731 7.99832H19.4973"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			<div class="menu-text">{{ t('NavBar.menu') }}</div>
		</div>
		<div class="dropdown" :class="{ active: dropdown }">
			<div class="dropdown-items">
				<router-link to="/faq" @click="close" class="items-link navbar-support-btn">
					{{ t('NavBar.support') }}
				</router-link>
				<router-link to="/logout" 
					class="items-link navbar-signout-btn"
					v-if="userID"
				>
					{{ t('NavBar.signout') }}
				</router-link>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { directive as clickOutside } from 'click-outside-vue3'
import { useUserID } from '@/composables/getAuthToken'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	directives: {
		clickOutside
	},
	setup() {
		const state = reactive({
			dropdown: false
		})

		const trigger = () => {
			state.dropdown = !state.dropdown
		}

		const close = () => {
			state.dropdown = false
		}

		const userID = useUserID()
		const { t } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			trigger,
			close,
			userID,
			t
		}
	}
})
</script>
<style scoped>
.nav-bar-menu-container {
	position: relative;
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.nav-bar-menu {
	cursor: pointer;
	border: 1px solid rgba(175, 177, 192, 0.1);
	box-sizing: border-box;
	border-radius: 10px;
	display: flex;
	padding: 8px 14px 8px 11px;
	color: #a2a3ac;
}

.nav-bar-menu:hover,
.nav-bar-menu.active {
	background: #1C1D24;
	color: #ffffff;
}

.nav-bar-menu.active {
	background: #1C1D24;
	border: 1px solid transparent;
}

.menu-text {
	margin: auto 0 auto 6px;
	font-weight: 500;
	font-size: 15px;
	line-height: 19px;
}

.menu-icon path {
	transition: 0.2s;
}

.nav-bar-menu:hover .menu-icon :nth-child(1),
.nav-bar-menu.active .menu-icon :nth-child(1) {
	d: path('M4.49762 12H19.4976');
}
.nav-bar-menu:hover .menu-icon :nth-child(2),
.nav-bar-menu.active .menu-icon :nth-child(2) {
	d: path('M4.49762 16.0017H19.4976');
}

.nav-bar-open:hover {
	color: #fff;
}

.dropdown-items {
	display: flex;
	flex-direction: column;
	margin: -10px 0;
}

.dropdown {
	background: #1C1D24;
	border-radius: 10px;
	padding: 20px;
	width: 190px;
	box-sizing: border-box;
	position: absolute;
	top: 57px;
	left: 0px;
}

.dropdown:not(.active) {
	display: none;
}

.items-link {
	display: inline-block;
	font-size: 15px;
	line-height: 140%;
	color: #ffffff;
	cursor: pointer;
	padding: 10px 0;
	font-weight: normal;
	text-decoration: none;
}

.items-link:hover {
	color: #a2a3ac;
}
</style>
