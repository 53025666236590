
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		modelValue: {
			type: Boolean,
			required: true
		}
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const click = () => {
			emit('update:modelValue', !props.modelValue)
		}

		return {
			click
		}
	}
})
