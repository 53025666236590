import Centrifuge from 'centrifuge'
import { onBeforeUnmount } from 'vue'
import { useAuthToken } from './getAuthToken'

const host = process.env.VUE_APP_VERCEL_URL ? 'live.inshop.pw' : 'live.inshop.gg'

interface SubscriptionArgs<T> {
	data: T
	event: number
}

const ws = new Centrifuge(`wss://${host}/connection/websocket`, { debug: true })

const token = useAuthToken()

if (token) {
	ws.setToken(token)
}

const promise = ws.connect()

export async function useSubscription<T>(channel: string, dataHandler: (...args: SubscriptionArgs<T>[]) => void) {
	await promise

	const subscription = ws.subscribe(channel, dataHandler)

	onBeforeUnmount(() => {
		subscription.unsubscribe()
	})
}
