<template>
	<div class="inventory-item" :class="{ active, selectable, hover }" :style="{ '--color': color }">
		<div class="item-overflow">
			<div class="item-bg" />
			<div class="item-exterior" v-if="exteriorNormalized">{{ exteriorNormalized }}</div>
			<div class="item-volume" :class="{ danger }">
				<slot name="volume">
					<svg width="15" height="14" viewBox="0 0 15 14" fill="none" class="volume-icon">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M12.3409 4.08333H1.8409C1.5189 4.08333 1.25757 3.822 1.25757 3.5V2.33333C1.25757 2.01133 1.5189 1.75 1.8409 1.75H12.3409C12.6629 1.75 12.9242 2.01133 12.9242 2.33333V3.5C12.9242 3.822 12.6629 4.08333 12.3409 4.08333Z"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M5.34094 6.41667H8.84094"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M12.1467 4.08334V11.0833C12.1467 11.7279 11.6246 12.25 10.98 12.25H3.20182C2.55724 12.25 2.03516 11.7279 2.03516 11.0833V4.08334"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<div class="volume-text">{{ volumeNormalized }}</div>
				</slot>
			</div>
			<ItemIcon :icon="icon" :size="260" :zoom="zoom" class="item-image" />
			<div class="item-price">{{ priceNormalized }}</div>
			<div class="item-type">{{ typeNormalized }}</div>
			<div class="item-name">{{ name }}</div>
			<div class="item-glow-top">
				<svg width="300" height="300" viewBox="0 0 300 300" fill="none">
					<g filter="url(#filter0_fff)">
						<circle cx="150" cy="150" r="23" :fill="color" />
					</g>
					<defs>
						<filter
							id="filter0_fff"
							x="0"
							y="0"
							width="300"
							height="300"
							filterUnits="userSpaceOnUse"
							color-interpolation-filters="sRGB"
						>
							<feFlood flood-opacity="0" result="BackgroundImageFix" />
							<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
							<feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur" />
						</filter>
					</defs>
				</svg>
			</div>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="item-arrow">
				<path
					opacity="0.5"
					d="M6 12C2.68629 12 -1.00647e-07 9.31371 -2.24801e-07 6L-4.49603e-07 9.53674e-07L6 6L12 12L6 12Z"
					:fill="color"
				/>
			</svg>
			<div class="item-overlay" :class="{ show: showOverlay }">
				<slot name="overlay" />
			</div>
			<div class="item-border" />
		</div>
	</div>
</template>
<script lang="ts">
import ItemIcon from '../ItemIcon/index.vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
	props: {
		id: {
			type: Number,
			required: true
		},
		active: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		stattrak: {
			type: Boolean,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		price: {
			type: Number,
			required: true
		},
		color: {
			type: String,
			required: true,
			validator: (color: string) => {
				if (color[0] !== '#') {
					return false
				}
				if (color.length !== 7) {
					return false
				}

				return true
			}
		},
		exterior: {
			type: String
		},
		volume: {
			type: Number,
			required: true
		},
		selectable: {
			type: Boolean,
			default: true
		},
		hoverable: {
			type: Boolean,
			default: true
		},
		imageSize: {
			type: Number,
			default: 233
		},
		noHover: {
			type: Boolean,
			default: false
		},
		showOverlay: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ItemIcon
	},
	setup(props) {
		const danger = computed(() => {
			return !isNaN(props.volume) && props.volume <= 3
		})

		const priceNormalized = computed(() => {
			return `$${(props.price / 100).toFixed(2)}`
		})

		const exteriorNormalized = computed(() => {
			if (props.exterior === 'Battle-Scarred') {
				return 'BS'
			}
			if (props.exterior === 'Well-Worn') {
				return 'WW'
			}
			if (props.exterior === 'Field-Tested') {
				return 'FT'
			}
			if (props.exterior === 'Minimal Wear') {
				return 'MW'
			}
			if (props.exterior === 'Factory New') {
				return 'FN'
			}

			return ''
		})

		const volumeNormalized = computed(() => {
			if (props.volume > 99) {
				return '99+'
			}

			return props.volume
		})

		const typeNormalized = computed(() => {
			return `${props.stattrak ? 'ST™ ' : ''}${props.type}`
		})

		const hover = computed(() => {
			return props.hoverable
		})

		const zoom = computed(() => {
			return props.type ? (props.type.includes('Graffiti') ? 1.5 : 1) : 1
		})

		return {
			danger,
			priceNormalized,
			exteriorNormalized,
			volumeNormalized,
			typeNormalized,
			hover,
			zoom
		}
	}
})
</script>
<style scoped>
.inventory-item {
	height: 220px;
	background: #1e2029;
	border-radius: 6px;
	position: relative;
	cursor: pointer;
	box-sizing: border-box;
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	transition: opacity 0.25s ease-in-out;
}

.mobile .inventory-item {
	background: #242731;
	height: 160px;
	flex: 1;
}

.item-overflow {
	overflow: hidden;
	border-radius: 6px;
	height: 100%;
	padding: 15px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
}

.inventory-item:not(.selectable) {
	opacity: 0.2;
	cursor: not-allowed;
}

.item-bg {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	opacity: 0.3;
	background: linear-gradient(
		43.02deg,
		var(--color) -50%,
		rgba(32, 33, 42, 0) 55.75%
	);
}

.item-exterior {
	font-family: "Montserrat";
	position: absolute;
	top: 12px;
	left: 13px;
	font-weight: 700;
	font-size: 8px;
	line-height: 10px;
	color: #a2a3ac;
	padding: 2px 3px;
	text-align: center;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 4px;
	z-index: 2;
}

.mobile .item-exterior {
	background: #1f212a;
	border-radius: 8px;
	padding: 4px 3px;
	top: 10px;
	left: 10px;
}

.item-volume {
	position: absolute;
	top: 10px;
	right: 10px;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	color: #97ed4c;
	box-sizing: border-box;
	border-radius: 8px;
	z-index: 2;
	display: flex;
	align-items: center;
}

.volume-icon {
	margin-right: 6px;
}

.volume-text {
	height: 10px;
}

.item-volume.danger {
	color: #fe932a;
}

.item-bonus {
	background: #fe932a;
	position: absolute;
	top: 10px;
	right: 10px;
	font-weight: bold;
	font-size: 8px;
	line-height: 10px;
	color: #242731;
	padding: 4px 7px;
	text-align: center;
	height: 18px;
	box-sizing: border-box;
	border-radius: 8px;
	z-index: 2;
	text-transform: uppercase;
}

.mobile .item-overflow {
	padding: 10px 15px;
}

.item-border {
	border: 1px solid transparent;
	position: absolute;
	left: 0;
	top: 0;
	height: calc(100% - 2px);
	width: calc(100% - 2px);
	border-radius: 10px;
	z-index: 12;
	pointer-events: none;
}

.inventory-item.active .item-border {
	border: 1px solid var(--color);
}

.inventory-item.hover:hover .item-glow-top {
	visibility: visible;
}

.item-image {
	width: 130px;
	height: 130px;
	margin: 0 auto;
	z-index: 1;
	position: relative;
	margin-top: -5px;
}

.mobile .item-image {
	width: 62px;
	height: 62px;
	margin: 10px auto 0 auto;
}

.item-type {
	margin-top: 10px;
	color: var(--color);
	font-weight: normal;
	font-size: 11px;
	line-height: 15px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	position: relative;
	z-index: 1;
}

.mobile .item-type {
	order: 1;
}

.item-name {
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-top: 2px;
	position: relative;
	z-index: 1;
}

.mobile .item-name {
	font-weight: 500;
	font-size: 13px;
	line-height: 13px;
	order: 2;
	white-space: normal;
	display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.item-price {
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
	color: #ffffff;
	margin-top: 4px;
	z-index: 2;
	position: relative;
}

.mobile .item-price {
	margin-top: 5px;
	font-size: 16px;
	line-height: 22px;
	order: 3;
}

.item-glow {
	position: absolute;
	bottom: -4px;
	left: 0px;
	z-index: 0;
}

.item-glow-top {
	position: absolute;
	top: -80px;
	left: 50%;
	transform: translate(-50%, 0);
	margin: 0 auto;
	z-index: 0;
	visibility: hidden;
}

.mobile .item-glow-top {
	top: -50px;
}

.item-glow svg,
.item-glow-top svg {
	z-index: 0;
}

.mobile .item-glow-top svg {
	width: 200px;
	height: 200px;
}

.item-arrow {
	position: absolute;
	bottom: 5px;
	left: 5px;
	z-index: 1;
}

.item-lock {
	position: absolute;
	top: calc(50% - 18px);
	z-index: 13;
}

.item-lock.left {
	left: -23px;
}

.item-lock.right {
	right: -23px;
}

.item-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
}

.show .overlay-bonus,
.inventory-item:hover .overlay-bonus {
	display: flex;
}

.overlay-bonus {
	background: #242731;
	height: 100%;
	box-sizing: border-box;
	padding: 10px;
	display: none;
	flex-direction: column;
	position: relative;
}

.mobile .overlay-bonus {
	padding: 5px 10px 9px 10px;
}

.info-wrap {
	position: absolute;
	top: 0px;
	right: 0px;
}

.info-tooltip-wrap {
	z-index: 10;
}

.mobile .info-wrap {
	top: 2px;
	right: 2px;
}

.progress-circle-wrap {
	margin: auto auto 0 auto;
	visibility: hidden;
	z-index: 0;
}

.show .progress-circle-wrap,
.inventory-item:hover .progress-circle-wrap {
	visibility: visible;
}

.bonus-text {
	font-size: 11px;
	line-height: 15px;
	text-align: center;
	color: #a2a3ac;
	margin-top: 10px;
	margin-bottom: auto;
	z-index: 1;
}

.mobile .bonus-text {
	font-size: 10px;
	margin-top: 5px;
}
</style>
