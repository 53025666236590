<template>
	<div class="center-wrap" :class="{ mobile }">
		<div class="left-wrap" v-if="!mobile">
			<div class="left-title">
				<div class="title-text">{{ t('Home.cart') }}</div>
				<div class="title-btn" @click="clear">
					<svg width="26" height="26" viewBox="0 0 26 26" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M19.0025 22.0038H6.99754C6.46684 22.0039 5.95783 21.7931 5.58257 21.4179C5.20731 21.0426 4.9967 20.5336 4.9967 20.0029V9.49855C4.99701 8.6699 5.66868 7.99823 6.49733 7.99792H19.5027C20.3314 7.99823 21.0031 8.6699 21.0034 9.49855V20.0029C21.0034 20.5336 20.7928 21.0426 20.4175 21.4179C20.0422 21.7931 19.5332 22.0039 19.0025 22.0038Z"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M8.99829 7.99791V7.74781V7.74781C8.99829 5.6759 10.6779 3.9963 12.7499 3.99625H13.2501C15.322 3.9963 17.0016 5.6759 17.0016 7.74781V7.74781V7.99791"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M14.2506 13.9466L11.7495 16.4477"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M11.7495 13.9466L14.2506 16.4477"
							stroke="currentColor"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
					<div class="btn-text">{{ t('Home.clear') }}</div>
				</div>
			</div>
			<div class="left-skins">
				<div class="skins-wrap" v-if="selectedItems.length">
					<CartItem
						v-for="item in selectedItems"
						:key="item.id"
						:name="item.item.name"
						:icon="item.item.icon"
						:price="item.item.priceUsd"
						:exterior="item.item.exterior"
						:stattrak="item.item.stattrak"
						:color="item.item.color"
						:type="item.item.type"
						@remove="select(item)"
					/>
				</div>
				<div class="skins-empty" v-if="!selectedItems.length">
					{{ t('Home.empty') }}
				</div>
			</div>
			<div class="cart-bottom">
				<CartStats :skins="skins" :total="total" />
				<BaseButton
					class="b-8 buy-btn"
					:class="[buyBtnClass]"
					@click="buy"
					:loading="buying"
					>{{ buyBtnText }}</BaseButton
				>
			</div>
		</div>
		<div class="content">
			<div class="content-top">
				<div class="content-title">
					<div class="title-left">{{ t('Home.title') }}</div>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						class="title-right"
						@click="toggleFilters"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M5 3.75C4.30921 3.75 3.75 4.30921 3.75 5V19C3.75 19.6908 4.30921 20.25 5 20.25H19C19.6908 20.25 20.25 19.6908 20.25 19V5C20.25 4.30921 19.6908 3.75 19 3.75H5ZM2.25 5C2.25 3.48079 3.48079 2.25 5 2.25H19C20.5192 2.25 21.75 3.48079 21.75 5V19C21.75 20.5192 20.5192 21.75 19 21.75H5C3.48079 21.75 2.25 20.5192 2.25 19V5ZM9 6.25C9.41421 6.25 9.75 6.58579 9.75 7V8.25H10C10.9662 8.25 11.75 9.03379 11.75 10V11C11.75 11.9662 10.9662 12.75 10 12.75H9.75V17C9.75 17.4142 9.41421 17.75 9 17.75C8.58579 17.75 8.25 17.4142 8.25 17V12.75H8C7.03379 12.75 6.25 11.9662 6.25 11V10C6.25 9.03379 7.03379 8.25 8 8.25H8.25V7C8.25 6.58579 8.58579 6.25 9 6.25ZM10 11.25H9H8C7.86221 11.25 7.75 11.1378 7.75 11V10C7.75 9.86221 7.86221 9.75 8 9.75H9H10C10.1378 9.75 10.25 9.86221 10.25 10V11C10.25 11.1378 10.1378 11.25 10 11.25ZM15 6.25C15.4142 6.25 15.75 6.58579 15.75 7V11.25H16C16.9662 11.25 17.75 12.0338 17.75 13V14C17.75 14.9662 16.9662 15.75 16 15.75H15.75V17C15.75 17.4142 15.4142 17.75 15 17.75C14.5858 17.75 14.25 17.4142 14.25 17V15.75H14C13.0338 15.75 12.25 14.9662 12.25 14V13C12.25 12.0338 13.0338 11.25 14 11.25H14.25V7C14.25 6.58579 14.5858 6.25 15 6.25ZM15 14.25H16C16.1378 14.25 16.25 14.1378 16.25 14V13C16.25 12.8622 16.1378 12.75 16 12.75H15H14C13.8622 12.75 13.75 12.8622 13.75 13V14C13.75 14.1378 13.8622 14.25 14 14.25H15Z"
							fill="#AFB1C0"
						/>
					</svg>
				</div>
				<div class="content-filters" :class="{ active: filters }">
					<InputSmall
						v-model="search"
						:placeholder="t('Home.search')"
						class="filters-search"
					>
						<template #left>
							<svg width="26" height="26" viewBox="0 0 26 26" fill="none">
								<path
									d="M21.0033 21.0033L17.752 17.752"
									stroke="#3F8CFF"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M17.749 7.18456C20.6662 10.1018 20.6662 14.8316 17.749 17.7489C14.8317 20.6661 10.1019 20.6661 7.18466 17.7489C4.2674 14.8316 4.26738 10.1018 7.18462 7.18456C8.58554 5.78362 10.4856 4.99658 12.4668 4.99658C14.448 4.99658 16.3481 5.78362 17.749 7.18456"
									stroke="#3F8CFF"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M12.4698 8.96832V12.1917"
									stroke="#3F8CFF"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M15.9712 12.97C15.9712 12.5401 15.6227 12.1917 15.1929 12.1917H9.74659C9.31673 12.1917 8.96826 12.5401 8.96826 12.97"
									stroke="#3F8CFF"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M9.96868 15.9712H14.9708C15.5233 15.9712 15.9712 15.5233 15.9712 14.9708V11.4043C15.9711 10.9989 15.8891 10.5977 15.7301 10.2248L15.452 9.57558C15.2946 9.20743 14.9329 8.96854 14.5326 8.96832H10.4069C10.0061 8.96814 9.64396 9.20709 9.48648 9.57558L9.20936 10.2238C9.05087 10.5955 8.96887 10.9953 8.96826 11.3993V14.9708C8.96826 15.5233 9.41616 15.9712 9.96868 15.9712Z"
									stroke="#3F8CFF"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</template>
					</InputSmall>
					<FilterDropdown
						v-model="type"
						:placeholder="t('Home.type')"
						:items="types"
					/>
					<FilterDropdown
						v-model="rarity"
						:placeholder="t('Home.rarity')"
						:items="rarities"
					/>
					<div class="filters-price">
						<InputSmall v-model="minPrice" :placeholder="t('Home.minPrice')">
							<template #left>$</template>
						</InputSmall>
						<div class="divider" />
						<InputSmall v-model="maxPrice" :placeholder="t('Home.maxPrice')">
							<template #left>$</template>
						</InputSmall>
					</div>
				</div>
			</div>
			<ItemsContainer
				class="items-container-wrap"
				ref="itemsContainer"
				:items="items"
				:selectedItems="selectedItemsComputed"
				@select="select"
				@scroll="scrollHandler('itemsContainer')"
			/>
			<div class="content-bottom" v-if="mobile" :class="{ active: cart }">
				<div class="bottom-cart">
					<div class="cart-nob" v-touch:swipe.bottom="closeCart">
						<div class="nob" />
					</div>
					<div class="left-title">
						<div class="title-text">{{ t('Home.cart') }}</div>
						<div class="title-btn" @click="clear">
							<svg width="26" height="26" viewBox="0 0 26 26" fill="none">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M19.0025 22.0038H6.99754C6.46684 22.0039 5.95783 21.7931 5.58257 21.4179C5.20731 21.0426 4.9967 20.5336 4.9967 20.0029V9.49855C4.99701 8.6699 5.66868 7.99823 6.49733 7.99792H19.5027C20.3314 7.99823 21.0031 8.6699 21.0034 9.49855V20.0029C21.0034 20.5336 20.7928 21.0426 20.4175 21.4179C20.0422 21.7931 19.5332 22.0039 19.0025 22.0038Z"
									stroke="currentColor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M8.99829 7.99791V7.74781V7.74781C8.99829 5.6759 10.6779 3.9963 12.7499 3.99625H13.2501C15.322 3.9963 17.0016 5.6759 17.0016 7.74781V7.74781V7.99791"
									stroke="currentColor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M14.2506 13.9466L11.7495 16.4477"
									stroke="currentColor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
								<path
									d="M11.7495 13.9466L14.2506 16.4477"
									stroke="currentColor"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<div class="btn-text">{{ t('Home.clear') }}</div>
						</div>
					</div>
					<div class="left-skins">
						<div class="skins-wrap" v-if="selectedItems.length">
							<CartItem
								v-for="item in selectedItems"
								:key="item.id"
								:name="item.item.name"
								:icon="item.item.icon"
								:price="item.item.priceUsd"
								:exterior="item.item.exterior"
								:stattrak="item.item.stattrak"
								:color="item.item.color"
								:type="item.item.type"
								@remove="select(item)"
							/>
						</div>
						<div class="skins-empty" v-if="!selectedItems.length">
							{{ t('Home.empty') }}
						</div>
					</div>
				</div>
				<CartStats :skins="skins" :total="total" class="cart-stats-wrap" />
				<div class="bottom-btns">
					<BaseButton
						class="b-8 buy-btn-mobile"
						:class="[buyBtnClass]"
						@click="buy"
						:loading="buying"
						>{{ buyBtnText }}</BaseButton
					>
					<BaseButton
						class="b-8 success-dark cart-btn-mobile"
						@click="toggleCart"
					>
						<svg width="30" height="30" viewBox="0 0 30 30" fill="none">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M22.2001 25.8H7.80015C6.47415 25.8 5.40015 24.726 5.40015 23.4V10.8C5.40015 9.8064 6.20655 9 7.20015 9H22.8001C23.7937 9 24.6001 9.8064 24.6001 10.8V23.4C24.6001 24.726 23.5261 25.8 22.2001 25.8Z"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M19.799 12.96C19.6334 12.96 19.499 13.0944 19.5002 13.26C19.5002 13.4256 19.6346 13.56 19.8002 13.56C19.9658 13.56 20.1002 13.4256 20.1002 13.26C20.1002 13.0944 19.9658 12.96 19.799 12.96"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10.1989 12.96C10.0333 12.96 9.8989 13.0944 9.9001 13.26C9.9001 13.4256 10.0345 13.56 10.2001 13.56C10.3657 13.56 10.5001 13.4256 10.5001 13.26C10.5001 13.0944 10.3657 12.96 10.1989 12.96"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10.2002 9.00001V8.70001V8.70001C10.2002 6.21481 12.215 4.20001 14.7002 4.20001H15.3002C17.7854 4.20001 19.8002 6.21481 19.8002 8.70001V8.70001V9.00001"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</BaseButton>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { useResponsiveStore } from '@/store/responsive'
import {
	ComponentPublicInstance,
	computed,
	defineComponent,
	onDeactivated,
	onMounted,
	PropType,
	reactive,
	ref,
	toRefs,
	watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import ItemsContainer from '@/components/ItemsContainer/index.vue'
import { useAPI } from '@/composables/api'
import { ItemEntity } from '@/api'
import { useAlertStore } from '@/store/alert'
import CartStats from '@/components/CartStats/index.vue'
import CartItem from '@/components/CartItem/index.vue'
import BaseButton from '@/components/BaseButton/index.vue'
import InputSmall from '@/components/InputSmall/index.vue'
import { useDebounce } from '@/composables/useDebounce'
import FilterDropdown from '@/components/FilterDropdown/index.vue'
import { useInventoryStore, ShopEntity } from '@/store/inventory'
import { useUserID } from '../../composables/getAuthToken'
import { useRouter } from 'vue-router'

export default defineComponent({
	props: {
		center: {
			type: Object as PropType<HTMLElement>,
			required: true
		}
	},
	components: {
		ItemsContainer,
		CartStats,
		CartItem,
		BaseButton,
		InputSmall,
		FilterDropdown
	},
	setup(props) {
		const { mobile } = toRefs(useResponsiveStore())
		const api = useAPI()
		const { t } = useI18n({ useScope: 'global' })
		const alert = useAlertStore()
		const inv = useInventoryStore()

		const inputState = reactive({
			minPrice: '',
			maxPrice: '',
			search: '',
			type: '',
			rarity: ''
		})

		const shopState = reactive({
			page: 0,
			loading: false,
			items: [] as ShopEntity[],
			types: [] as string[],
			rarities: [] as string[],
			filters: true,
			cart: false
		})

		const getRarities = async () => {
			try {
				const rarities: string[] = await api({
					path: '/items/rarities'
				})

				shopState.rarities = rarities
			} catch (err) {
				console.error(err)
			}
		}

		const getTypes = async () => {
			try {
				const types: string[] = await api({
					path: '/items/types'
				})

				shopState.types = types
			} catch (err) {
				console.error(err)
			}
		}

		const getShop = async (clear = false) => {
			if (clear) {
				shopState.page = 0
			}

			const minPrice =
				!inputState.minPrice || isNaN(parseInt(inputState.minPrice, 10))
					? 0
					: Math.floor(+inputState.minPrice * 100)
			const maxPrice =
				!inputState.maxPrice || isNaN(parseInt(inputState.maxPrice, 10))
					? 99999999
					: Math.floor(+inputState.maxPrice * 100)
			const page = shopState.page
			const name = inputState.search
			const type = inputState.type
			const rarity = inputState.rarity

			shopState.loading = true

			try {
				const items: ItemEntity[] = await api({
					path: '/shop/find',
					method: 'POST',
					body: {
						page,
						minPrice,
						maxPrice,
						name,
						type,
						rarity,
						inStock: true
					}
				})

				if (clear) {
					shopState.items = []
				}

				shopState.items.push(
					...items.map(item => {
						return {
							id: item.id,
							item: { ...item }
						}
					})
				)

				console.log('shopState', shopState.items)

				shopState.loading = false
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('ShopContainer.errors.getShop')}: ${err.message}`
				})

				console.error(err)
			}
		}

		const updateItems = useDebounce(() => getShop(true), 100)

		watch(
			() => [Object.values({ ...toRefs(inputState) })],
			() => {
				updateItems()
			}
		)

		// scroll handling
		const itemsContainer = ref<ComponentPublicInstance<HTMLElement>>()

		const scrollHandler = async (container: string) => {
			let bottomOfWindow = false

			if (container === 'itemsContainer' && !mobile.value) {
				bottomOfWindow =
					itemsContainer.value?.$el?.scrollHeight -
						Math.abs(itemsContainer.value?.$el?.scrollTop) <=
					itemsContainer.value?.$el?.clientHeight + 750
			} else if (container === 'center' && mobile.value) {
				bottomOfWindow =
					!!props.center &&
					!!props.center.scrollTop &&
					!!props.center.scrollHeight &&
					!!props.center.clientHeight &&
					props.center.scrollHeight - Math.abs(props.center.scrollTop) <=
						props.center.clientHeight + 750
			}

			if (bottomOfWindow && !shopState.loading) {
				shopState.page++

				await getShop()

				return true
			}

			return false
		}

		const loadRecursive = async (container: string) => {
			const result = await scrollHandler(container)
			if (result) {
				loadRecursive(container)
			}
		}

		onMounted(async () => {
			await getShop(true)
			loadRecursive('itemsContainer')
			loadRecursive('center')
		})

		const selectedItemsComputed = computed(() => {
			return inv.selectedItems.map(item => item.id)
		})

		const select = (item: ShopEntity) => {
			inv.select(item)
		}

		const clear = () => {
			inv.clear()
		}

		onMounted(() => {
			getRarities()
			getTypes()
		})

		// cart stats
		const skins = computed(() => {
			return inv.selectedItems.length
		})

		const total = computed(() => {
			return inv.selectedItems.reduce((a, b) => a + b.item.priceUsd, 0)
		})

		// mobile
		const toggleFilters = () => {
			shopState.filters = !shopState.filters
		}

		const toggleCart = () => {
			shopState.cart = !shopState.cart
		}

		const closeCart = () => {
			shopState.cart = false
		}

		const { selectedItems } = toRefs(inv)

		const buyState = reactive({
			buying: false
		})

		const userID = useUserID()

		const buyBtnText = computed(() => {
			if (!userID) {
				return t('Home.auth')
			}
			if (!inv.selectedItems.map(item => item.id).length) {
				return t('Home.select')
			}

			return t('Home.buy')
		})

		const buyBtnClass = computed(() => {
			if (!userID) {
				return 'primary'
			}
			if (!inv.selectedItems.map(item => item.id).length) {
				return 'disabled'
			}

			return 'success'
		})

		const router = useRouter()

		const buy = async () => {
			if (!userID) {
				return router.push('/auth')
			}

			const itemIds = inv.selectedItems.map(item => item.id)

			if (!itemIds.length) {
				return alert.pushAlert({
					type: 'danger',
					text: t('ShopContainer.errors.oneItem')
				})
			}

			try {
				await api({
					path: '/shop/buy',
					method: 'POST',
					body: { itemIds }
				})

				inv.clear()
				alert.pushAlert({
					type: 'buy',
					text: t('ShopContainer.bought')
				})
				localStorage.setItem('shop.bought', '1')
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('ShopContainer.errors.buy')}: ${err.message}`
				})
			}
		}

		return {
			mobile,
			t,
			...toRefs(shopState),
			...toRefs(inputState),
			selectedItems,
			selectedItemsComputed,
			select,
			clear,
			skins,
			total,
			scrollHandler,
			itemsContainer,
			toggleFilters,
			toggleCart,
			closeCart,
			...toRefs(buyState),
			buy,
			buyBtnText,
			buyBtnClass
		}
	}
})
</script>
<style scoped>
@import '../../assets/styles/view.css';

.left-wrap {
	position: relative;
}

.left-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title-text {
	color: #fff;
	font-weight: normal;
	font-size: 36px;
	line-height: 30px;
	height: 30px;
}

.title-btn {
	opacity: 0.3;
	color: #fff;
	cursor: pointer;
	display: flex;
}

.title-btn:hover {
	opacity: 1;
}

.btn-text {
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	margin: 4px 0 0 6px;
}

.left-skins {
	flex: 1;
	overflow-y: scroll;
	overflow-x: hidden;
	margin-top: 30px;
	padding-right: 5px;
	margin-right: -9px;
	display: flex;
	flex-direction: column;
}

.skins-wrap {
	display: grid;
	grid-auto-rows: 90px;
	gap: 10px;
}

.skins-empty {
	font-style: normal;
	font-weight: 300;
	font-size: 36px;
	line-height: 46px;
	text-align: center;
	color: rgba(255, 255, 255, 0.2);
	margin: auto;
	width: 170px;
}

.cart-bottom {
	margin-top: 30px;
}

.buy-btn {
	margin-top: 10px;
	height: 48px;
}

.content-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.title-left {
	font-weight: normal;
	font-size: 36px;
	line-height: 30px;
	color: #ffffff;
}

.title-right {
	display: none;
	opacity: 0.5;
}

.title-right:hover {
	opacity: 1;
}

.content-filters {
	background: #1e2029;
	border-radius: 8px;
	padding: 10px;
	margin-top: 30px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 20px;
	z-index: 13;
}

.filters-price {
	display: flex;
	align-items: center;
}

.divider {
	height: 1px;
	width: 13px;
	flex-shrink: 0;
	margin: 0 10px;
	background: rgba(255, 255, 255, 0.2);
}

.items-container-wrap {
	margin-top: 10px;
}

.content-bottom {
	position: fixed;
	top: calc(100vh - 199px);
	top: calc(calc(var(--vh, 1vh) * 100) - 199px);
	bottom: 0;
	right: 0;
	left: 0;
	background: #242731;
	box-shadow: 0px -17px 80px rgba(14, 15, 20, 0.16),
		0px -7.10219px 33.4221px rgba(14, 15, 20, 0.115017),
		0px -3.79717px 17.869px rgba(14, 15, 20, 0.0953772),
		0px -2.12866px 10.0172px rgba(14, 15, 20, 0.08),
		0px -1.13052px 5.32008px rgba(14, 15, 20, 0.0646228),
		0px -0.470434px 2.21381px rgba(14, 15, 20, 0.0449833);
	z-index: 13;
	padding: 10px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	transition: top 0.25s ease-in-out;
}

.content-bottom.active {
	top: 104px;
}

.bottom-cart {
	flex-shrink: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1;
	opacity: 0;
	transition: opacity 0.25s ease-in-out;
}

.content-bottom.active .bottom-cart {
	opacity: 1;
}

.cart-nob {
	display: flex;
	margin: -10px 0 0 0;
	padding: 10px 0 16px 0;
}

.nob {
	width: 39px;
	height: 4px;
	background: rgba(162, 163, 172, 0.2);
	border-radius: 10px;
	margin: 0 auto;
}

.cart-stats-wrap {
	flex-shrink: 0;
}

.bottom-btns {
	margin-top: 10px;
	display: flex;
	height: 44px;
	flex-shrink: 0;
}

.cart-btn-mobile {
	width: 50px;
	margin-left: 5px;
	flex-shrink: 0;
}

@media (max-width: 950px) {
	.content-title {
		margin-left: 10px;
	}

	.title-left {
		font-weight: normal;
		font-size: 30px;
		line-height: 33px;
	}

	.title-right {
		display: block;
	}

	.items-container-wrap {
		background: #1e2029;
		margin: 0 -10px 0 -10px;
		padding: 10px;
	}

	.content-filters {
		padding: 0;
		background: none;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: 10px;
		margin-top: 26px;
	}

	.content-filters:not(.active) {
		display: none;
	}

	.filters-search {
		grid-area: 1 / 1 / auto / span 2;
	}

	.filters-price {
		grid-area: 3 / 1 / auto / span 2;
	}

	.content-top {
		position: sticky;
		top: -50px;
		z-index: 13;
		background: #242731;
		margin: 0 -10px;
		padding: 0 10px 30px 10px;
	}

	.content {
		padding-top: 14px;
		padding-bottom: 145px;
	}
}
</style>
