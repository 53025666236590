<template>
	<div class="lang">
		<Flag class="lang-flag" :code="flag" v-if="flag" />
		<div class="lang-name" v-if="full">{{ countryFull }}</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import countries from '../../composables/countriesList'
import Flag from './Flag.vue'

export default defineComponent({
	props: {
		code: {
			type: String,
			required: true
		},
		full: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Flag
	},
	setup(props) {
		const countryFull = computed(() => {
			const [country] = countries.filter(({ code }) => code === props.code)
			
			if (!country) {
				return 'Loading...'
			}

			return country.name
		})

		const flag = computed(() => {
			const [country] = countries.filter(({ code }) => code === props.code)
			
			if (!country) {
				return ''
			}

			return country.flag ? country.flag : country.code
		})

		return {
			countryFull,
			flag
		}
	}
})
</script>
<style scoped>
.lang {
	display: flex;
	color: #A2A3AC;
	align-items: center;
	min-width: 0;
}

.lang-flag {
	margin-right: 7px;
}

.lang-name {
	font-weight: 500;
	font-size: 15px;
	line-height: 15px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
