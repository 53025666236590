import { defineStore } from 'pinia'

export const useModalStore = defineStore({
	id: 'modal',
	state() {
		return {
			deposit: {
				enabled: false,
				loading: false,
				active: false
			}
		}
	}
})
