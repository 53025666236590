
import { defineComponent, reactive, toRefs } from 'vue'
import { directive as clickOutside } from 'click-outside-vue3'
import { useUserID } from '@/composables/getAuthToken'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	directives: {
		clickOutside
	},
	setup() {
		const state = reactive({
			dropdown: false
		})

		const trigger = () => {
			state.dropdown = !state.dropdown
		}

		const close = () => {
			state.dropdown = false
		}

		const userID = useUserID()
		const { t } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			trigger,
			close,
			userID,
			t
		}
	}
})
