
import { useUserID } from '@/composables/getAuthToken'
import { computed, defineComponent } from '@vue/runtime-core'

export default defineComponent({
	props: {
		avatar: {
			type: String,
			required: true
		},
		userID: {
			type: Number,
			required: true
		},
		prefix: {
			type: String,
			default: ''
		}
	},
	setup(props) {
		const userID = useUserID()

		const me = computed(() => {
			return props.userID !== userID
		})
		const link = computed(() => {
			return me.value ? `/user/${props.userID}` : '/profile'
		})
		const blur = computed(() => {
			return localStorage.getItem('streamerMode') === '1' && me.value
		})

		return {
			me,
			link,
			blur
		}
	}
})
