import { onDeactivated, reactive } from 'vue'
import { useResponsiveStore } from '../store/responsive'

type E = UIEvent | { target: Window, srcElement?: Window }

export const useResizeTracking = (): void => {
	const responsive = useResponsiveStore()
	const resizeHandler = (e: any) => {
		const target = e.target || e.srcElement

		const width = target?.outerWidth
		const vh = target?.innerHeight * 0.01
		
		responsive.$patch({ vh: `${vh}px` })

		if (responsive.mobile && width <= 950) {
			return
		}
		if (!responsive.mobile && width > 950) {
			return
		}

		responsive.$patch({ mobile: width <= 950 })
	}

	const resizeState = reactive({
		windowInterval: setInterval(() => {
			resizeHandler({ target: window })
		}, 1000),
	})

	window.addEventListener('resize', resizeHandler)
	resizeHandler({ target: window })

	onDeactivated(() => {
		window.removeEventListener('resize', resizeHandler)
		if (resizeState.windowInterval) {
			clearInterval(resizeState.windowInterval)
		}
	})
}
