<template>
	<div class="deposit-method" :class="{ active, disabled }">
		<TooltipHover class="method-wrap" v-if="disabled">
			<template #root>
				<div class="method-icon-wrap">
					<img :src="icon" class="method-icon" />
				</div>
				<div class="method-name">{{ nameNormalized }}</div>
				<img :src="require('../../assets/icons/warning.svg')" class="method-warning" />
			</template>
			<template #text>
				{{ t('DepositContainer.disabled') }}
			</template>
		</TooltipHover>
		<div class="method-wrap" v-if="!disabled">
			<div class="method-icon-wrap">
				<img :src="icon" class="method-icon" />
			</div>
			<div class="method-name">{{ nameNormalized }}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import TooltipHover from '../TooltipHover/index.vue'

export default defineComponent({
	props: {
		group: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			required: true
		},
		displayName: {
			type: String,
			required: true
		},
		active: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: ''
		}
	},
	components: {
		TooltipHover
	},
	setup(props) {
		const { t } = useI18n({ useScope: 'global' })

		const nameNormalized = computed(() => {
			return (props.displayName ? props.displayName : (props.group ? props.group : props.name)).replace('blocked', 'adv').replace(/enot_/g, '').replace(/_/g, ' ')
		})

		return {
			nameNormalized,
			t
		}
	}
})
</script>
<style scoped>
.deposit-method {
	height: 95px;
	background: #242731;
	box-sizing: border-box;
	cursor: pointer;
	border-radius: 10px;
	padding-bottom: 20px;
}

.deposit-method.disabled {
	cursor: not-allowed;
}

.deposit-method:hover:not(.disabled) {
	background: #1c1d24;
}

.deposit-method.active:not(.disabled) {
	border: 1px solid #97ed4c;
}

.method-wrap {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.method-icon-wrap {
	margin: auto auto 0 auto;
	display: flex;
	height: 40px;
	width: 40px;
}

.method-icon {
	margin: auto;
	max-width: 40px;
}

.method-name {
	margin: 5px 0 0 0;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	text-align: center;
	text-transform: uppercase;
	color: #a2a3ac;
}

.deposit-method.active:not(.disabled) .method-name {
	color: #fff;
}

.method-warning {
	position: absolute;
	top: 10px;
	right: 10px;
}
</style>
