
import { defineComponent } from "vue"

export default defineComponent({
	name: 'Spinner',
	props: {
		width: String,
		height: String
	}
})
