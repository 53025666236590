
import { computed, defineComponent } from 'vue'
import ItemIcon from '../ItemIcon/index.vue'

export default defineComponent({
	props: {
		icon: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		stattrak: {
			type: Boolean,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		price: {
			type: Number,
			required: true
		},
		color: {
			type: String,
			required: true,
			validator: (color: string) => {
				if (color[0] !== "#") {
					return false;
				}
				if (color.length !== 7) {
					return false;
				}
				return true;
			}
		},
		exterior: {
			type: String
		}
	},
	emits: ["remove"],
	setup(props, { emit }) {
		const priceNormalized = computed(() => {
			return `$${(props.price / 100).toFixed(2)}`;
		});
		const exteriorNormalized = computed(() => {
			if (props.exterior === "Battle-Scarred") {
				return "BS";
			}
			if (props.exterior === "Well-Worn") {
				return "WW";
			}
			if (props.exterior === "Field-Tested") {
				return "FT";
			}
			if (props.exterior === "Minimal Wear") {
				return "MW";
			}
			if (props.exterior === "Factory New") {
				return "FN";
			}
			return "";
		});
		const typeNormalized = computed(() => {
			return `${props.stattrak ? "ST™ " : ""}${props.type}`;
		});
		const zoom = computed(() => {
			return props.type.includes("Graffiti") ? 1.5 : 1;
		});
		const remove = () => {
			emit("remove");
		};
		return {
			priceNormalized,
			exteriorNormalized,
			typeNormalized,
			zoom,
			remove
		};
	},
	components: { ItemIcon }
})
