<template>
	<div class="mini-profile" :class="{ hoverable }">
		<router-link to="/profile" custom v-slot="{ navigate }">
			<div @click="goto(navigate, $event)" />
		</router-link>
		<router-link to="/auth" custom v-slot="{ navigate }" v-if="!userID">
			<BaseButton
				class="base-button-wrap auth-btn primary b-14 lg"
				@click="navigate"
			>
				<template #left>
					<img :src="require('../../assets/icons/steam.svg')" />
				</template>
				{{ t('MiniProfile.signin') }}
			</BaseButton>
		</router-link>
		<div class="profile-container" v-if="userID">
			<router-link to="/profile" custom v-slot="{ navigate }">
				<UserAvatar
					@click="goto(navigate, $event)"
					class="user-avatar-wrap"
					:avatar="avatar"
					:lvl="lvl"
					:userID="id ? id : userID"
				/>
			</router-link>
			<router-link to="/profile" custom v-slot="{ navigate }">
				<div @click="goto(navigate, $event)" class="profile-left">
					<div class="left-username">{{ username }}</div>
					<div class="left-slot">
						<slot name="left" />
					</div>
				</div>
			</router-link>
			<div class="profile-slot">
				<slot name="right" />
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import UserAvatar from '../UserAvatar/index.vue'
import BaseButton from '../BaseButton/index.vue'
import { defineComponent } from '@vue/runtime-core'
import { useUserID } from '@/composables/getAuthToken'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		id: {
			type: Number
		},
		username: {
			type: String,
			default: 'Loading...'
		},
		avatar: {
			type: String,
			default:
				'https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg'
		},
		lvl: {
			type: Number,
			default: 1
		},
		lvlPercent: {
			type: Number,
			default: 1
		},
		hoverable: {
			type: Boolean,
			default: false
		},
		nolink: {
			type: Boolean,
			default: false
		}
	},
	components: {
		UserAvatar,
		BaseButton
	},
	setup(props) {
		const { t } = useI18n()

		const goto = (navigate: (...args: any[]) => any, $event: any): void => {
			if (props.nolink) {
				return
			}

			navigate($event)
		}

		const userID = useUserID()

		return {
			goto,
			userID,
			t
		}
	}
})
</script>
<style scoped>
.mini-profile {
	display: flex;
	padding: 0 20px;
	box-sizing: border-box;
	position: relative;
}

.mini-profile.active {
	box-shadow: 0px 14px 44px rgba(25, 28, 56, 0.2),
		0px 3px 5px rgba(17, 19, 36, 0.3);
}

/* .profile-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #242731;
	border-radius: 15px;
	cursor: pointer;
}

.mini-profile.hoverable:hover .profile-bg {
	background: #2e303a;
} */

.profile-container {
	display: grid;
	grid-template-columns: 40px 18px 120px 1fr auto;
	margin: auto 0;
	width: 100%;
	z-index: 1;
	cursor: pointer;
	align-items: center;
}

.user-avatar-wrap {
	grid-area: 1 / 1;
}

.profile-left {
	grid-area: 1 / 3;
}

.profile-slot {
	grid-area: 1 / 5;
	margin: auto 0;
	height: fit-content;
	display: grid;
	grid-auto-columns: auto;
	grid-auto-flow: column;
	grid-gap: 5px;
}

.left-username {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	color: #ffffff;
	overflow: hidden;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.progress-bar-wrap {
	margin-top: 9px;
}

.base-button-wrap {
	height: 43px;
	margin: auto 0;
}
</style>
