<template>
	<div class="lang-selector" :class="{ active }" v-click-outside="close">
		<div class="lang-btn lang-select-btn" @click="trigger">
			<Lang :lang="locale" class="lang-wrap" :full="full" />
			<img :src="require('../../assets/icons/caret.svg')" class="lang-caret" />
		</div>
		<div class="lang-list">
			<div class="list-scrollable">
				<template v-for="code in langCodes">
					<div
						v-if="locale !== code"
						:key="code"
						class="lang-item lang-en-btn"
						:class="[`lang-${code}-btn`]"
						@click="select(code)"
					>
						<Lang :lang="code" class="lang-wrap" :full="full" />
					</div>
				</template>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { LangCode, languages, useLocaleAsync } from '../../composables/useLocale'
import Lang from './Lang.vue'
import { directive as clickOutside } from 'click-outside-vue3'
import { defineComponent, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		full: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Lang
	},
	directives: {
		clickOutside
	},
	setup(props) {
		const state = reactive({
			active: false,
			langCodes: languages().map(({ code }) => code)
		})

		const close = () => {
			state.active = false
		}
		
		const trigger = () => {
			state.active = !state.active
		}

		const setLang = (lang: LangCode) => {
			useLocaleAsync(lang)
		}

		const select = (lang: LangCode) => {
			setLang(lang)
			close()
		}

		const { locale } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			close,
			trigger,
			select,
			setLang,
			locale
		}
	}
})
</script>
<style scoped>
.lang-selector {
	position: relative;
	-moz-user-select: none;
	-webkit-user-select: none;
	display: flex;
}

.lang-selector.active .lang-btn {
	/* border-radius: 10px 10px 0 0; */
	background: #1c1d24;
	border: 1px solid transparent;
}

.lang-selector.active .lang-list {
	display: flex;
	margin-top: -4.5px;
}

.lang-selector.active .lang-caret {
	transform: rotate(180deg);
	opacity: 1;
}

.lang-btn {
	display: flex;
	border: 1px solid rgba(175, 177, 192, 0.1);
	box-sizing: border-box;
	border-radius: 10px;
	height: 42px;
	padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 20px;
	padding-right: 20px;
	cursor: pointer;
	width: 100%;
}

.lang-selector.borderless .lang-btn {
	border: none;
}

.lang-selector.active .lang-name,
.lang-btn:hover .lang-caret {
	opacity: 1;
}

.lang-selector.active .lang-btn .lang-wrap,
.lang-btn:hover .lang-btn .lang-wrap,
.lang-item:hover .lang-wrap {
	color: #fff;
}

.lang-caret {
	margin: 6px 0 0 5px;
	opacity: 0.3;
	height: 6px;
	width: 10.5px;
}

.lang-list {
	display: none;
	position: absolute;
	background: #1c1d24;
	border-radius: 10px;
	width: fit-content;
	flex-direction: column;
	top: 56px;
	max-height: 150px;
	padding: 5px 0;
}

.list-scrollable {
	overflow-y: scroll;
	overflow-x: hidden;
	margin-right: 5px;
}

.lang-item {
	display: flex;
	padding: 10px 30px;
	cursor: pointer;
}
</style>
