<template>
	<div class="modal">
		<div class="modal-body" v-click-outside="close">
			<div class="body-nob-wrap" v-touch:swipe.bottom="close">
				<div class="nob" />
			</div>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="modal-close" @click="close">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M7.69696 6.00015L11.6485 2.04865C12.1177 1.57946 12.1177 0.821079 11.6485 0.351891C11.1793 -0.117297 10.4209 -0.117297 9.95171 0.351891L6.00019 4.30339L2.04866 0.351891C1.57947 -0.117297 0.821085 -0.117297 0.351893 0.351891C-0.117298 0.821079 -0.117298 1.57946 0.351893 2.04865L4.30342 6.00015L0.351893 9.95165C-0.117298 10.4208 -0.117298 11.1792 0.351893 11.6484C0.585889 11.8824 0.893083 12 1.20028 12C1.50747 12 1.81467 11.8824 2.04866 11.6484L6.00019 7.69691L9.95171 11.6484C10.1857 11.8824 10.4929 12 10.8001 12C11.1073 12 11.4145 11.8824 11.6485 11.6484C12.1177 11.1792 12.1177 10.4208 11.6485 9.95165L7.69696 6.00015Z"
					fill="currentColor"
				/>
			</svg>
			<slot />
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { directive as clickOutside } from 'click-outside-vue3'

export default defineComponent({
	directives: {
		clickOutside
	},
	setup(_, { emit }) {
		const close = (el: MouseEvent) => {
			emit('close', el)
		}

		return {
			close
		}
	}
})
</script>
<style scoped>
.modal {
	padding: 10px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
	width: 100vw;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(3px);
	display: flex;
	z-index: 1000;
	transition: opacity 0.5s ease-in-out;
	overflow-y: scroll;
}

.v-enter-from.modal,
.v-leave-to.modal {
	opacity: 0;
}

.modal-body {
	position: relative;
	margin: auto;
	background: radial-gradient(
			34.53% 51.21% at 0% 0%,
			rgba(6, 121, 255, 0.5) 0%,
			rgba(6, 121, 255, 0) 100%
		),
		radial-gradient(
			27.85% 41.3% at 0.81% 45.41%,
			rgba(255, 63, 201, 0.3) 0%,
			rgba(255, 63, 201, 0) 100%
		),
		rgba(28, 29, 36, 0.8);
	border-radius: 45px;
	overflow: hidden;
	transition: transform 0.25s ease-in-out;
	transform: scale(1);
}

.v-enter-from .modal-body,
.v-leave-to .modal-body {
	transform: scale(0);
}

.modal-close {
	position: absolute;
	top: 50px;
	right: 50px;
	color: #afb1c0;
	cursor: pointer;
}

.modal-close:hover {
	color: #fff;
}

.body-nob-wrap {
	display: none;
	height: 24px;
	align-items: center;
	justify-content: center;
	padding-bottom: 7px;
}

.nob {
	width: 39px;
	height: 4px;
	background: rgba(162, 163, 172, 0.2);
	border-radius: 10px;
}

@media (max-width: 950px) {
	.v-enter-from.modal,
	.v-leave-to.modal {
		opacity: 1;
	}

	.v-enter-from .modal-body,
	.v-leave-to .modal-body {
		max-height: 0px;
		transform: scale(1);
	}

	.body-nob-wrap {
		display: flex;
	}

	.modal {
		padding: 0;
		background: none;
		backdrop-filter: none;
		overflow: hidden;
	}
	.modal-close {
		display: none;
	}

	.modal-body {
		max-height: 1000px;
		transition: max-height 0.5s ease-in-out;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		border-radius: 0;
		background: #242731;
		box-shadow: 0px -17px 80px rgba(14, 15, 20, 0.16),
			0px -7.10219px 33.4221px rgba(14, 15, 20, 0.115017),
			0px -3.79717px 17.869px rgba(14, 15, 20, 0.0953772),
			0px -2.12866px 10.0172px rgba(14, 15, 20, 0.08),
			0px -1.13052px 5.32008px rgba(14, 15, 20, 0.0646228),
			0px -0.470434px 2.21381px rgba(14, 15, 20, 0.0449833);
		padding: 0 10px 10px 10px;
	}
}
</style>
