<template>
	<footer class="footer" :class="{ small }">
		<div class="footer-left">
			<svg width="28" height="18" viewBox="0 0 28 18" fill="none">
				<path
					opacity="0.7"
					d="M17.6524 2.30695H10.4352V15.6882H17.6524V2.30695Z"
					fill="#63656F"
				/>
				<path
					d="M10.8934 8.99756C10.8923 7.70885 11.1753 6.43675 11.7211 5.27759C12.2669 4.11842 13.0612 3.10256 14.0438 2.30692C12.827 1.32014 11.3656 0.706469 9.82679 0.536061C8.28795 0.365652 6.73369 0.645372 5.34165 1.34326C3.94961 2.04115 2.77596 3.12905 1.95485 4.4826C1.13374 5.83615 0.698303 7.40075 0.698303 8.99756C0.698303 10.5944 1.13374 12.159 1.95485 13.5125C2.77596 14.8661 3.94961 15.954 5.34165 16.6519C6.73369 17.3497 8.28795 17.6295 9.82679 17.4591C11.3656 17.2886 12.827 16.675 14.0438 15.6882C13.0612 14.8925 12.2669 13.8767 11.7211 12.7175C11.1753 11.5584 10.8923 10.2863 10.8934 8.99756Z"
					fill="#494A55"
				/>
				<path
					d="M27.3889 8.99757C27.3889 10.5944 26.9535 12.1589 26.1325 13.5125C25.3114 14.8661 24.1378 15.954 22.7458 16.6519C21.3538 17.3497 19.7995 17.6295 18.2607 17.4591C16.7219 17.2887 15.2606 16.675 14.0438 15.6882C15.0255 14.8918 15.8192 13.8757 16.3649 12.7168C16.9106 11.5578 17.1942 10.2861 17.1942 8.99757C17.1942 7.70906 16.9106 6.43736 16.3649 5.27838C15.8192 4.11939 15.0255 3.10337 14.0438 2.30693C15.2606 1.32014 16.7219 0.706465 18.2607 0.536059C19.7995 0.365652 21.3538 0.645384 22.7458 1.34328C24.1378 2.04118 25.3114 3.12908 26.1325 4.48263C26.9535 5.83619 27.3889 7.40078 27.3889 8.99757Z"
					fill="#63656F"
				/>
			</svg>
			<svg width="40" height="14" viewBox="0 0 40 14" fill="none">
				<path
					d="M17.3404 13.397H14.0765L16.1164 0.860394H19.3806L17.3404 13.397ZM11.3307 0.860394L8.21898 9.48315L7.85077 7.62633L7.85111 7.627L6.75285 1.98917C6.75285 1.98917 6.62005 0.860394 5.20456 0.860394H0.0603623L0 1.07267C0 1.07267 1.57311 1.39997 3.41415 2.5056L6.24983 13.3973H9.65057L14.8434 0.860394H11.3307ZM37.003 13.397H40L37.387 0.860059H34.7632C33.5516 0.860059 33.2565 1.79433 33.2565 1.79433L28.3887 13.397H31.7911L32.4715 11.5348H36.6207L37.003 13.397ZM33.4115 8.96235L35.1264 4.27086L36.0912 8.96235H33.4115ZM28.6439 3.87515L29.1097 1.183C29.1097 1.183 27.6724 0.636383 26.174 0.636383C24.5543 0.636383 20.7079 1.3443 20.7079 4.78662C20.7079 8.0254 25.2223 8.06564 25.2223 9.76685C25.2223 11.4681 21.173 11.1632 19.8367 10.0905L19.3514 12.9054C19.3514 12.9054 20.8089 13.6133 23.0355 13.6133C25.2629 13.6133 28.6231 12.46 28.6231 9.32117C28.6231 6.06161 24.0681 5.75812 24.0681 4.34095C24.0684 2.92344 27.2472 3.10553 28.6439 3.87515Z"
					fill="#63656F"
				/>
				<path
					d="M7.85098 7.62668L6.75274 1.98885C6.75274 1.98885 6.61995 0.860077 5.20448 0.860077H0.0603613L0 1.07235C0 1.07235 2.47246 1.58476 4.84399 3.50461C7.11156 5.33962 7.85098 7.62668 7.85098 7.62668Z"
					fill="#494A55"
				/>
			</svg>
		</div>
		<div class="footer-center">
			<router-link custom v-slot="{ navigate }" to="/tos">
				<div @click="navigate" class="footer-item">{{ t('Footer.tos') }}</div>
			</router-link>
			<router-link custom v-slot="{ navigate }" to="/privacy">
				<div @click="navigate" class="footer-item">
					{{ t('Footer.privacy') }}
				</div>
			</router-link>
			<router-link custom v-slot="{ navigate }" to="/agent">
				<div @click="navigate" class="footer-item">{{ t('Footer.agent') }}</div>
			</router-link>
			<router-link custom v-slot="{ navigate }" to="/contacts">
				<div @click="navigate" class="footer-item">{{ t('Footer.contacts') }}</div>
			</router-link>
		</div>
	</footer>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAPI } from '../../composables/api'

export default defineComponent({
	props: {
		small: {
			type: Boolean,
			default: false
		}
	},
	setup() {
		const { t, locale } = useI18n({ useScope: 'global' })
		const country = ref('RU')
		const api = useAPI()

		const getCountry = async () => {
			try {
				const res = await api({
					path: '/country',
					string: true
				})

				country.value = res
			} catch (error) {
				// silent
			}
		}

		onMounted(async () => {
			await getCountry()
		})
		return {
			t,
			locale,
			country
		}
	}
})
</script>
<style scoped>
.footer {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	position: absolute;
	padding: 12px 11px 12px 5px;
	bottom: 15px;
	left: 30px;
	right: 30px;
	min-height: 54px;
	align-items: center;
	border: 1px dashed rgba(162, 163, 172, 0.1);
	background: #242731;
	border-radius: 10px;
	z-index: 2;
	justify-content: center;
}

.footer.small {
	left: 380px;
	right: 30px;
}

.footer-left {
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, auto);
	gap: 15px;
	margin-left: 13px;
}

.footer-item {
	padding: 0 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #a2a3ac;
	opacity: 0.5;
	text-align: center;
}

.footer-center {
	width: fit-content;
	display: flex;
	align-items: center;
	margin: 0 auto;
	flex-wrap: wrap;
	justify-content: center;
}

.footer-item:hover:not(.nohover) {
	opacity: 1;
	cursor: pointer;
}

@media (max-width: 950px) {
	.footer-right {
		display: none;
	}

	.footer-left {
		margin-bottom: 5px;
	}
}
</style>
