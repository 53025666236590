
import { defineComponent, reactive, toRefs } from 'vue'
import Spinner from '../Spinner/index.vue'

export default defineComponent({
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		loadingNoText: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Spinner
	},
	setup() {
		const state = reactive({
			shake: false,
			shine: false,
			grow: false,
			hover: false
		})

		const shakeOnce = () => {
			state.shake = true

			setTimeout(() => {
				state.shake = false
			}, 1000)
		}

		const shineOnce = () => {
			state.shine = true

			setTimeout(() => {
				state.shine = false
			}, 2000)
		}

		const highlightOnce = () => {
			state.grow = true
			state.hover = true

			setTimeout(() => {
				state.shine = true
			}, 350)

			setTimeout(() => {
				state.shine = false
				state.grow = false
				state.hover = false
			}, 2000)
		}

		return {
			...toRefs(state),
			shakeOnce,
			shineOnce,
			highlightOnce
		}
	}
})
