<template>
	<div class="flag">
		<img :src="require(`../../assets/icons/flags/${lang}.svg`)" class="icon" />
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import validator from './LangValidator'

export default defineComponent({
	props: {
		lang: {
			type: String,
			required: true,
			validator
		}
	}
})
</script>
<style scoped>
.flag {
	height: 18px;
}
</style>
