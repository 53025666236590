
import UserAvatar from '../UserAvatar/index.vue'
import BaseButton from '../BaseButton/index.vue'
import { defineComponent } from '@vue/runtime-core'
import { useUserID } from '@/composables/getAuthToken'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		id: {
			type: Number
		},
		username: {
			type: String,
			default: 'Loading...'
		},
		avatar: {
			type: String,
			default:
				'https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/avatars/fe/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg'
		},
		lvl: {
			type: Number,
			default: 1
		},
		lvlPercent: {
			type: Number,
			default: 1
		},
		hoverable: {
			type: Boolean,
			default: false
		},
		nolink: {
			type: Boolean,
			default: false
		}
	},
	components: {
		UserAvatar,
		BaseButton
	},
	setup(props) {
		const { t } = useI18n()

		const goto = (navigate: (...args: any[]) => any, $event: any): void => {
			if (props.nolink) {
				return
			}

			navigate($event)
		}

		const userID = useUserID()

		return {
			goto,
			userID,
			t
		}
	}
})
