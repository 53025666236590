<template>
	<div
		class="input-small"
		:class="{ shake, error, success, edit: state === 'EDITING' }"
	>
		<div class="input-left">
			<slot name="left" />
		</div>
		<input
			type="text"
			class="input-field"
			:placeholder="placeholder"
			:value="modelValue"
			@input="input"
			@focus="focus"
			@focusout="focusOut"
			@keydown="keydown"
			:disabled="disabled"
			ref="inputElement"
			v-if="!mask"
		/>
		<input
			type="text"
			class="input-field"
			:placeholder="placeholder"
			:value="modelValue"
			@input="input"
			@focus="focus"
			@focusout="focusOut"
			@keydown="keydown"
			:disabled="disabled"
			v-mask="mask"
			ref="inputElement"
			v-if="mask"
		/>
		<div class="input-right">
			<slot name="right" />
		</div>
	</div>
</template>
<script lang="ts">
import { useInput } from '@/composables/useInput'
import { defineComponent } from 'vue'
import { mask } from 'vue-the-mask'

export default defineComponent({
	props: {
		placeholder: {
			type: String,
			default: ''
		},
		modelValue: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		mask: {
			type: [String, Array],
			default: ''
		}
	},
	directives: {
		mask
	},
	emits: ['update:modelValue', 'enter'],
	setup(_, ctx) {
		return useInput(ctx)
	}
})
</script>
<style scoped>
.input-small {
	background: #1c1d24;
	border-radius: 10px;
	height: 44px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
	transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
	border: 1.5px solid #1c1d24;
	display: flex;
	align-items: center;
}

.input-small.error {
	border: 1.5px solid #ff6339;
}

.input-small.success {
	border: 1.5px solid #2aaafe;
}

.input-field {
	height: 100%;
	width: 100%;
	padding: 0 10px;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	display: flex;
	align-items: center;
	color: #fff;
	border: none;
	outline: none;
	cursor: text;
	box-sizing: border-box;
	background: none;
	transition: color 0.5s ease-in-out;
}

.input-icon.error .input-field {
	color: #ff6339;
}

.input-field::placeholder {
	font-weight: normal;
	color: #a2a3ac;
	opacity: 1;
}

.input-field:-ms-input-placeholder {
	font-weight: normal;
	color: #a2a3ac;
}

.input-field::-ms-input-placeholder {
	font-weight: normal;
	color: #a2a3ac;
}

.input-left:not(:empty) {
	margin-left: 8.5px;
	display: flex;
	align-items: center;
	color: rgba(162, 163, 172, 0.5);
	transition: color 0.5s ease-in-out;
}

.input-small.edit .input-left {
	color: #fff;
}

.input-small.error .input-left {
	color: #ff6339;
}

.input-small.success .input-left {
	color: #2aaafe;
}

.input-right:not(:empty) {
	padding-right: 8.5px;
	display: flex;
	align-items: center;
	overflow: hidden;
	flex-shrink: 0;
}
</style>
