import { reactive, SetupContext, toRefs } from 'vue'

export const useInput = ({ emit }: SetupContext<('update:modelValue' | 'enter')[]>) => {
	const state = reactive({
		state: 'INACTIVE',
		shake: false,
		error: false,
		success: false,
		successTimeout: null as NodeJS.Timeout | null,
		inputElement: null as HTMLElement | null
	})

	const click = () => {
		state.inputElement?.focus()
	}
	const enter = () => {
		emit('enter')
	}
	const input = (e: Event) => {
		emit('update:modelValue', (e.target as HTMLInputElement).value)
	}
	const focus = () => {
		state.state = 'EDITING'
		state.error = false
		state.success = false
	}
	const focusOut = () => {
		state.state = 'INACTIVE'
		enter()
	}
	const keydown = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			enter()
			e.preventDefault()
		}
	}
	const setSuccess = () => {
		state.success = true
		state.error = false

		if (state.successTimeout) {
			clearTimeout(state.successTimeout)
		}
		state.successTimeout = setTimeout(() => {
			state.success = false
		}, 3000)
	}
	const shakeOnce = () => {
		state.shake = true
		state.error = true
		state.success = false

		setTimeout(() => {
			state.shake = false
		}, 1000)
	}

	return {
		...toRefs(state),
		enter,
		input,
		focus,
		focusOut,
		keydown,
		shakeOnce,
		setSuccess,
		click
	}
}
