
import { computed, defineComponent } from 'vue'
import countries from '../../composables/countriesList'
import Flag from './Flag.vue'

export default defineComponent({
	props: {
		code: {
			type: String,
			required: true
		},
		full: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Flag
	},
	setup(props) {
		const countryFull = computed(() => {
			const [country] = countries.filter(({ code }) => code === props.code)
			
			if (!country) {
				return 'Loading...'
			}

			return country.name
		})

		const flag = computed(() => {
			const [country] = countries.filter(({ code }) => code === props.code)
			
			if (!country) {
				return ''
			}

			return country.flag ? country.flag : country.code
		})

		return {
			countryFull,
			flag
		}
	}
})
