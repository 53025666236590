
import { useNormalizeDate } from '@/composables/useNormalizeDate'
import { defineComponent, onDeactivated, PropType, toRefs } from '@vue/runtime-core'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		type: {
			type: String,
			required: true,
			validator: (type: string) =>
				['deposit', 'trade', 'danger', 'check', 'ref', 'buy', 'swap', 'copy'].includes(type)
		},
		text: {
			type: String,
			required: true
		},
		time: {
			type: Object as PropType<Date>,
			required: true
		},
		action: {
			type: Boolean,
			default: false
		},
		actionText: {
			type: String
		}
	},
	emits: ['action', 'close'],
	setup(props, { emit }) {
		const { t } = useI18n({ useScope: 'global' })
		const { normalizeDate } = useNormalizeDate()

		const state = reactive({
			timeNormalized: t('Alert.lessminute'),
			interval: setInterval(() => {
				state.timeNormalized = normalizeDate(props.time)
			}, 60000)
		})

		onDeactivated(() => {
			clearInterval(state.interval)
		})

		const actionEvent = () => emit('action')
		const close = () => emit('close')

		const actionTextNormalized = computed(() => {
			return props.actionText ? props.actionText : t('Alert.accept')
		})

		const iconNormalized = computed(() => {
			return require(`../../assets/icons/alert_${props.type}.svg`)
		})

		const alertClass = computed(() => {
			if (props.type === 'danger') {
				return 'danger'
			}

			return ''
		})

		const color = computed(() => {
			if (props.type === 'danger') {
				return 'red'
			}

			return 'green'
		})

		return {
			...toRefs(state),
			t,
			actionEvent,
			close,
			actionTextNormalized,
			iconNormalized,
			alertClass,
			color
		}
	}
})
