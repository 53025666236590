
import { WithdrawEntity } from '@/api'
import { useAPI } from '@/composables/api'
import { useSubscription } from '@/composables/useSubscription'
import {
	computed,
	defineComponent,
	nextTick,
	onMounted,
	reactive,
	toRefs
} from 'vue'
import { useI18n } from 'vue-i18n'
import Item from './Item.vue'

interface MethodProperties {
	amountUsd: number
	method?: { imageUrl: string }
}

type WithdrawLiveEntity = WithdrawEntity & MethodProperties

export default defineComponent({
	components: {
		Item
	},
	setup() {
		const api = useAPI()

		const state = reactive({
			liveWithdrawsSubscription: null,
			withdraws: [] as WithdrawLiveEntity[],
			transitions: false,
			total: 0
		})

		const getWithdraws = async () => {
			try {
				const { history, totalUsd } = await api({
					path: '/withdrawals/history'
				})

				state.withdraws = history
					.filter((item: any) => !item.amountUsd)
					.map((item: any) => {
						return {
							...item,
							amountUsd: item.amountUsd ? item.amountUsd : item.item.priceUsd
						}
					})

				state.total = parseInt(totalUsd, 10)

				await nextTick()
				await nextTick()

				state.transitions = true
			} catch (err) {
				// empty
			}
		}

		useSubscription<WithdrawLiveEntity>(
			'withdrawals',
			({ data }) => {
				if (data.amountUsd) {
					return
				}

				state.withdraws.splice(state.withdraws.length - 1, 1)
				state.withdraws.unshift({
					...data,
					amountUsd: data.amountUsd ? data.amountUsd : data.item.priceUsd
				})
				state.total += data.amountUsd ? data.amountUsd : data.item.priceUsd
			}
		)

		onMounted(() => {
			getWithdraws()
		})

		const totalNormalized = computed(() => {
			return Math.floor(state.total) / 100
		})

		const { t } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			totalNormalized,
			t
		}
	}
})
