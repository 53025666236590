import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home/index.vue'

const routes: RouteRecordRaw[] = [
	{
		path: '/',
		component: Home
	},
	{
		path: '/tos',
		component: () => import('../views/TOS/index.vue')
	},
	{
		path: '/privacy',
		component: () => import('../views/Privacy/index.vue')
	},
	{
		path: '/agent',
		component: () => import('../views/Agent/index.vue')
	},
	{
		path: '/contacts',
		component: () => import('../views/Contacts/index.vue')
	},
	{
		path: '/profile',
		component: () => import('../views/Profile/index.vue'),
		children: [
			{
				path: '',
				component: () => import('../views/Profile/Items.vue')
			},
			{
				path: 'transactions',
				component: () => import('../views/Profile/Transactions.vue')
			}
		]
	},
	{
		path: '/auth',
		name: 'auth',
		component: Home
	},
	{
		path: '/logout',
		name: 'logout',
		component: Home,
		beforeEnter() {
			window.location.href = `https://${process.env.VUE_APP_VERCEL_URL ? 'api.inshop.pw' : 'api.inshop.gg'}/auth/logout`
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: 'active'
})

export default router
