<template>
	<div class="items-container">
		<div class="items-scroll">
			<div class="items-wrap">
				<Item
					v-for="item in items"
					:key="item.id"
					:id="item.id"
					:icon="item.item.icon"
					:type="item.item.type"
					:name="item.item.name"
					:price="item.item.priceUsd"
					:color="item.item.color"
					:volume="(item.buyPriceUsd && item.item.priceUsd && item.item.priceUsd > item.buyPriceUsd) ? 0 : (item.item.marketVolume || 0)"
					:exterior="item.item.exterior"
					:stattrak="item.item.stattrak"
					:active="selectedItems.includes(item.id)"
					:imageSize="imageSize"
					:gamesCount="item.gamesCount"
					:totalGamesCount="item.totalGamesCount"
					@click="select(item)"
				/>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { InventoryEntityExtended } from '@/store/inventory'
import { defineComponent, PropType } from 'vue'
import Item from '../InventoryItem/index.vue'

export default defineComponent({
	props: {
		items: {
			type: Object as PropType<InventoryEntityExtended[]>,
			required: true
		},
		selectedItems: {
			type: Object as PropType<number[]>,
			required: true
		},
		imageSize: {
			type: Number,
			default: 466
		}
	},
	components: {
		Item
	},
	emits: {
		select: (payload: InventoryEntityExtended) => {
			return true
		}
	},
	setup(props, { emit }) {
		const select = (item: InventoryEntityExtended) => {
			if (typeof item.selectable !== 'undefined' && !item.selectable && !props.selectedItems.includes(item.id)) {
				return
			}

			emit('select', item)
		}

		return {
			select
		}
	}
})
</script>
<style scoped>
.items-container {
	display: flex;
	margin-right: -9px;
	overflow-x: hidden;
	overflow-y: auto;
}

.items-scroll {
	display: flex;
	margin-right: 5px;
	padding-bottom: 50px;
	overflow-x: hidden;
	height: fit-content;
	width: 100%;
}

.items-wrap {
	display: grid;
	width: 100%;
	gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
	align-content: baseline;
}

@media (max-width: 950px) {
	.items-scroll {
		margin-right: 0;
		padding-bottom: 0;
	}

	.items-wrap {
		grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
	}
}
</style>
