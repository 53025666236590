<template>
	<div class="flag">
		<img :src="`https://www.countryflagicons.com/FLAT/48/${code}.png`" class="icon" />
	</div>
</template>
<script>
export default {
	props: {
		code: {
			type: String,
			required: true
		}
	}
}
</script>
<style scoped>
.flag {
	display: flex;
	align-items: center;
	height: 21px;
}

.icon {
	height: 100%;
	border-radius: 8px;
}
</style>
