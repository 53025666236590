import { onErrorCaptured } from 'vue'
import { useAlertStore } from '../store/alert'

export function useSuspenseErrorCatch() {
	const alert = useAlertStore()

	onErrorCaptured(error => {
		console.error(error)
		
		alert.pushAlert({ type: 'danger', text: (error as Error).message, timeout: 3000 })

		return true
	})
}
