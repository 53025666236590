
import { defineComponent } from 'vue'
import { directive as clickOutside } from 'click-outside-vue3'

export default defineComponent({
	directives: {
		clickOutside
	},
	setup(_, { emit }) {
		const close = (el: MouseEvent) => {
			emit('close', el)
		}

		return {
			close
		}
	}
})
