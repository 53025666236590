
import { defineComponent, reactive, toRefs } from "vue"

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			required: true
		}
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const state = reactive({
			active: false
		})

		const select = (value: string) => {
			emit('update:modelValue', value)
		}

		return {
			...toRefs(state),
			select
		}
	}
})
