<template>
	<div class="alert" :class="[alertClass, color]">
		<div class="alert-body">
			<div class="body-border" />
			<img :src="iconNormalized" class="body-icon" width="22" height="22" />
			<div class="body-text">
				<div class="text-value">{{ text }}</div>
				<div class="text-time">{{ timeNormalized }}</div>
			</div>
		</div>
		<div class="alert-btn" v-if="action" @click="actionEvent">
			<div class="btn-text">{{ actionTextNormalized }}</div>
		</div>
		<div class="alert-btn" v-else @click="close">
			<div class="btn-text">{{ t('Alert.close') }}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { useNormalizeDate } from '@/composables/useNormalizeDate'
import { defineComponent, onDeactivated, PropType, toRefs } from '@vue/runtime-core'
import { computed, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		type: {
			type: String,
			required: true,
			validator: (type: string) =>
				['deposit', 'trade', 'danger', 'check', 'ref', 'buy', 'swap', 'copy'].includes(type)
		},
		text: {
			type: String,
			required: true
		},
		time: {
			type: Object as PropType<Date>,
			required: true
		},
		action: {
			type: Boolean,
			default: false
		},
		actionText: {
			type: String
		}
	},
	emits: ['action', 'close'],
	setup(props, { emit }) {
		const { t } = useI18n({ useScope: 'global' })
		const { normalizeDate } = useNormalizeDate()

		const state = reactive({
			timeNormalized: t('Alert.lessminute'),
			interval: setInterval(() => {
				state.timeNormalized = normalizeDate(props.time)
			}, 60000)
		})

		onDeactivated(() => {
			clearInterval(state.interval)
		})

		const actionEvent = () => emit('action')
		const close = () => emit('close')

		const actionTextNormalized = computed(() => {
			return props.actionText ? props.actionText : t('Alert.accept')
		})

		const iconNormalized = computed(() => {
			return require(`../../assets/icons/alert_${props.type}.svg`)
		})

		const alertClass = computed(() => {
			if (props.type === 'danger') {
				return 'danger'
			}

			return ''
		})

		const color = computed(() => {
			if (props.type === 'danger') {
				return 'red'
			}

			return 'green'
		})

		return {
			...toRefs(state),
			t,
			actionEvent,
			close,
			actionTextNormalized,
			iconNormalized,
			alertClass,
			color
		}
	}
})
</script>
<style scoped>
.alert {
	background: #1c1d24;
	border-radius: 16px;
	padding: 13px 20px;
	top: 10px;
	opacity: 1;
	position: relative;
	box-sizing: border-box;
	backdrop-filter: blur(3px);
	max-height: 400px;
}

.v-enter-active.alert {
	transition: all 0.5s ease-out;
}

.v-leave-active.alert {
	transition: all 0.5s ease-out, opacity 0.25s ease-out,
		max-height 0.5s ease-out, padding 0.3s ease-out 0.15s, margin 0.5s ease-out;
}

.v-enter.alert,
.v-leave-to.alert {
	margin: 0;
	opacity: 0;
	top: -150px;
}

.v-leave-to.alert {
	max-height: 0px;
	padding: 0;
}

.alert-close {
	position: absolute;
	top: 10px;
	right: 10px;
	opacity: 0.5;
	cursor: pointer;
}

.alert-close:hover {
	opacity: 1;
}

.alert-body {
	display: flex;
	align-items: center;
}

.body-border {
	border-radius: 20px;
	width: 3px;
	align-self: stretch;
	flex-shrink: 0;
}

.alert.red .body-border{
	background: #FF6339;
}

.alert.green .body-border{
	background: #97ED4C;
}

.body-icon {
	width: 24px;
	height: 24px;
	margin-left: 15px;
}

.body-text {
	margin: 2px 0 2px 15px;
}

.text-value {
	font-size: 13px;
	line-height: 16px;
	color: #fff;
	padding-right: 16px;
}

.text-time {
	font-size: 11px;
	line-height: 13px;
	color: #a2a3ac;
	margin-top: 5px;
}

.alert-btn {
	display: flex;
	margin-top: 10px;
	background: rgba(60, 67, 120, 0.5);
	border-radius: 7px;
	height: 38px;
	padding: 0 20px;
	cursor: pointer;
	width: 100%;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.alert.red .alert-btn {
	background: rgba(255, 99, 57, 0.1);
}

.alert.red .alert-btn:hover {
	background: rgba(255, 99, 57, 0.2);
}

.alert.green .alert-btn {
	background: rgba(151, 237, 76, 0.1);
}

.alert.green .alert-btn:hover {
	background: rgba(151, 237, 76, 0.2);
}

.btn-text {
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
}

.alert.red .btn-text {
	color: #ff6339;
}

.alert.green .btn-text {
	color: #97ed4c;
}
</style>
