<template>
	<div class="auth-modal">
		<div class="modal-title">{{ t('AuthModal.title') }}</div>
		<div class="modal-text">
			{{ t('AuthModal.text1') }}
			<router-link to="/tos" class="text-link">
				{{ t('AuthModal.tos') }}
			</router-link>
		</div>
		<div class="modal-check" @click="check">
			<CheckBox class="check-box-wrap" v-model="accept">
				<span v-html="t('AuthModal.confirm')" />
			</CheckBox>
		</div>
		<div class="modal-buttons">
			<BaseButton
				class="base-button-wrap b-16 primary"
				:class="{ disabled: !accept }"
				@click="auth"
			>
				{{ t('AuthModal.auth') }}
			</BaseButton>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseButton from '../BaseButton/index.vue'
import CheckBox from '../CheckBox/index.vue'

export default defineComponent({
	components: {
		CheckBox,
		BaseButton
	},
	emits: ['close'],
	setup(props, { emit }) {
		const state = reactive({
			accept: false
		})

		const auth = () => {
			if (!state.accept) {
				return
			}

			window.location.href = `https://${process.env.VUE_APP_VERCEL_URL ? 'api.inshop.pw' : 'api.inshop.gg'}/auth/steam`
		}

		const close = () => {
			emit('close')
		}

		const check = () => {
			state.accept = !state.accept
		}

		const { t } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			auth,
			close,
			check,
			t
		}
	}
})
</script>
<style scoped>
.auth-modal {
	padding: 50px;
	width: fit-content;
	max-width: 600px;
	box-sizing: border-box;
}

.modal-title {
	font-weight: bold;
	font-size: 44px;
	line-height: 54px;
	color: #fff;
}

.modal-text {
	font-weight: normal;
	font-size: 15px;
	line-height: 21px;
	color: #ffffff;
	margin-top: 30px;
}

.text-link {
	font-weight: 600;
	color: #2aaafe;
	text-decoration: none;
}

.modal-continue {
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	text-align: center;
	color: #ffffff;
	margin-top: 30px;
}

.modal-buttons {
	display: flex;
	margin-top: 20px;
	justify-content: center;
	width: 100%;
	grid-gap: 20px;
}

.base-button-wrap {
	height: 48px;
	box-sizing: border-box;
}

.modal-check {
	background: rgba(162, 163, 172, 0.05);
	border-radius: 16px;
	margin-top: 20px;
	height: 48px;
	padding: 0 15px;
	width: 100%;
	display: flex;
	box-sizing: border-box;
	cursor: pointer;
}

.check-box-wrap {
	margin: auto;
	pointer-events: none;
}

.modal-check:hover .check-box-wrap ::v-deep .box-text {
	opacity: 1;
}
</style>
