import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { i18n } from './composables/useLocale'
import Vue2TouchEvents from 'vue3-touch-events'

const app = createApp(App)

app
	.use(Vue2TouchEvents)
	.use(i18n)
	.use(createPinia())
	.use(router)
	.mount('#app')
