
import { useResponsiveStore } from '@/store/responsive'
import {
	ComponentPublicInstance,
	computed,
	defineComponent,
	onDeactivated,
	onMounted,
	PropType,
	reactive,
	ref,
	toRefs,
	watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import ItemsContainer from '@/components/ItemsContainer/index.vue'
import { useAPI } from '@/composables/api'
import { ItemEntity } from '@/api'
import { useAlertStore } from '@/store/alert'
import CartStats from '@/components/CartStats/index.vue'
import CartItem from '@/components/CartItem/index.vue'
import BaseButton from '@/components/BaseButton/index.vue'
import InputSmall from '@/components/InputSmall/index.vue'
import { useDebounce } from '@/composables/useDebounce'
import FilterDropdown from '@/components/FilterDropdown/index.vue'
import { useInventoryStore, ShopEntity } from '@/store/inventory'
import { useUserID } from '../../composables/getAuthToken'
import { useRouter } from 'vue-router'

export default defineComponent({
	props: {
		center: {
			type: Object as PropType<HTMLElement>,
			required: true
		}
	},
	components: {
		ItemsContainer,
		CartStats,
		CartItem,
		BaseButton,
		InputSmall,
		FilterDropdown
	},
	setup(props) {
		const { mobile } = toRefs(useResponsiveStore())
		const api = useAPI()
		const { t } = useI18n({ useScope: 'global' })
		const alert = useAlertStore()
		const inv = useInventoryStore()

		const inputState = reactive({
			minPrice: '',
			maxPrice: '',
			search: '',
			type: '',
			rarity: ''
		})

		const shopState = reactive({
			page: 0,
			loading: false,
			items: [] as ShopEntity[],
			types: [] as string[],
			rarities: [] as string[],
			filters: true,
			cart: false
		})

		const getRarities = async () => {
			try {
				const rarities: string[] = await api({
					path: '/items/rarities'
				})

				shopState.rarities = rarities
			} catch (err) {
				console.error(err)
			}
		}

		const getTypes = async () => {
			try {
				const types: string[] = await api({
					path: '/items/types'
				})

				shopState.types = types
			} catch (err) {
				console.error(err)
			}
		}

		const getShop = async (clear = false) => {
			if (clear) {
				shopState.page = 0
			}

			const minPrice =
				!inputState.minPrice || isNaN(parseInt(inputState.minPrice, 10))
					? 0
					: Math.floor(+inputState.minPrice * 100)
			const maxPrice =
				!inputState.maxPrice || isNaN(parseInt(inputState.maxPrice, 10))
					? 99999999
					: Math.floor(+inputState.maxPrice * 100)
			const page = shopState.page
			const name = inputState.search
			const type = inputState.type
			const rarity = inputState.rarity

			shopState.loading = true

			try {
				const items: ItemEntity[] = await api({
					path: '/shop/find',
					method: 'POST',
					body: {
						page,
						minPrice,
						maxPrice,
						name,
						type,
						rarity,
						inStock: true
					}
				})

				if (clear) {
					shopState.items = []
				}

				shopState.items.push(
					...items.map(item => {
						return {
							id: item.id,
							item: { ...item }
						}
					})
				)

				console.log('shopState', shopState.items)

				shopState.loading = false
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('ShopContainer.errors.getShop')}: ${err.message}`
				})

				console.error(err)
			}
		}

		const updateItems = useDebounce(() => getShop(true), 100)

		watch(
			() => [Object.values({ ...toRefs(inputState) })],
			() => {
				updateItems()
			}
		)

		// scroll handling
		const itemsContainer = ref<ComponentPublicInstance<HTMLElement>>()

		const scrollHandler = async (container: string) => {
			let bottomOfWindow = false

			if (container === 'itemsContainer' && !mobile.value) {
				bottomOfWindow =
					itemsContainer.value?.$el?.scrollHeight -
						Math.abs(itemsContainer.value?.$el?.scrollTop) <=
					itemsContainer.value?.$el?.clientHeight + 750
			} else if (container === 'center' && mobile.value) {
				bottomOfWindow =
					!!props.center &&
					!!props.center.scrollTop &&
					!!props.center.scrollHeight &&
					!!props.center.clientHeight &&
					props.center.scrollHeight - Math.abs(props.center.scrollTop) <=
						props.center.clientHeight + 750
			}

			if (bottomOfWindow && !shopState.loading) {
				shopState.page++

				await getShop()

				return true
			}

			return false
		}

		const loadRecursive = async (container: string) => {
			const result = await scrollHandler(container)
			if (result) {
				loadRecursive(container)
			}
		}

		onMounted(async () => {
			await getShop(true)
			loadRecursive('itemsContainer')
			loadRecursive('center')
		})

		const selectedItemsComputed = computed(() => {
			return inv.selectedItems.map(item => item.id)
		})

		const select = (item: ShopEntity) => {
			inv.select(item)
		}

		const clear = () => {
			inv.clear()
		}

		onMounted(() => {
			getRarities()
			getTypes()
		})

		// cart stats
		const skins = computed(() => {
			return inv.selectedItems.length
		})

		const total = computed(() => {
			return inv.selectedItems.reduce((a, b) => a + b.item.priceUsd, 0)
		})

		// mobile
		const toggleFilters = () => {
			shopState.filters = !shopState.filters
		}

		const toggleCart = () => {
			shopState.cart = !shopState.cart
		}

		const closeCart = () => {
			shopState.cart = false
		}

		const { selectedItems } = toRefs(inv)

		const buyState = reactive({
			buying: false
		})

		const userID = useUserID()

		const buyBtnText = computed(() => {
			if (!userID) {
				return t('Home.auth')
			}
			if (!inv.selectedItems.map(item => item.id).length) {
				return t('Home.select')
			}

			return t('Home.buy')
		})

		const buyBtnClass = computed(() => {
			if (!userID) {
				return 'primary'
			}
			if (!inv.selectedItems.map(item => item.id).length) {
				return 'disabled'
			}

			return 'success'
		})

		const router = useRouter()

		const buy = async () => {
			if (!userID) {
				return router.push('/auth')
			}

			const itemIds = inv.selectedItems.map(item => item.id)

			if (!itemIds.length) {
				return alert.pushAlert({
					type: 'danger',
					text: t('ShopContainer.errors.oneItem')
				})
			}

			try {
				await api({
					path: '/shop/buy',
					method: 'POST',
					body: { itemIds }
				})

				inv.clear()
				alert.pushAlert({
					type: 'buy',
					text: t('ShopContainer.bought')
				})
				localStorage.setItem('shop.bought', '1')
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('ShopContainer.errors.buy')}: ${err.message}`
				})
			}
		}

		return {
			mobile,
			t,
			...toRefs(shopState),
			...toRefs(inputState),
			selectedItems,
			selectedItemsComputed,
			select,
			clear,
			skins,
			total,
			scrollHandler,
			itemsContainer,
			toggleFilters,
			toggleCart,
			closeCart,
			...toRefs(buyState),
			buy,
			buyBtnText,
			buyBtnClass
		}
	}
})
