
import { nextTick, toRefs, defineComponent, reactive, ref } from "vue"

export default defineComponent({
	props: {
		fit: {
			type: Boolean,
			default: false
		},
		letter: {
			type: Boolean,
			default: false
		}
	},
	setup(props) {
		const state = reactive({
			hover: false,
			top: '0px',
			left: '0px',
			leftVar: '0px',
			iconTop: false,
			interval: false,
			transition: false
		})

		const rootSlot = ref<HTMLElement>()
		const tooltipBody = ref<HTMLElement>()

		const calc = (transition = false) => {
			if (!rootSlot.value || !rootSlot.value.firstElementChild) {
				return
			}

			state.transition = transition
			const {
				top,
				left,
				width,
				height
			} = rootSlot.value.firstElementChild.getBoundingClientRect()!

			nextTick(() => {
				nextTick(() => {
					if (!tooltipBody.value) {
						return
					}

					const windowWidth = window.innerWidth

					const {
						width: tooltipWidth,
						height: tooltipHeight,
						left: tooltipLeft,
						top: tooltipTop
					} = tooltipBody.value.getBoundingClientRect()!

					// if overflows right
					const leftDiff = tooltipLeft + tooltipWidth + 10 - windowWidth

					// if overflows left
					const rightDiff = tooltipLeft - 10

					if (leftDiff > 0) {
						state.leftVar = `${leftDiff}px`
					} else if (rightDiff < 0) {
						state.leftVar = `${rightDiff}px`
					}

					// if overflows top
					const topDiff = tooltipTop - 10

					if (topDiff < 0) {
						state.top = `${top + height + 10 + tooltipHeight}px`
						state.iconTop = true
					} else {
						state.iconTop = false
					}
				})
			})

			state.top = `${top - 10}px`
			state.left = `${left + width / 2}px`
		}

		const reset = () => {
			state.iconTop = false
			state.top = '0px'
			state.left = '0px'
			state.leftVar = '0px'
		}

		const mouseover = () => {
			state.hover = true
			calc()
		}

		const mouseout = () => {
			state.hover = false
			reset()
		}

		return {
			...toRefs(state),
			rootSlot,
			tooltipBody,
			calc,
			reset,
			mouseover,
			mouseout
		}
	}
})
