
import { computed, defineComponent } from 'vue'
import { languages } from '../../composables/useLocale'
import Flag from './Flag.vue'
import validator from './LangValidator'

export default defineComponent({
	props: {
		lang: {
			type: String,
			required: true,
			validator
		},
		full: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Flag
	},
	setup(props) {
		const langFull = computed(() => {
			const [lang] = languages().filter(({ code }) => code === props.lang)
			
			if (!lang) {
				return 'Unknown'
			}

			return lang.name
		})

		return {
			langFull
		}
	}
})
