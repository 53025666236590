<template>
	<div class="live-withdraws">
		<div class="withdraws-header">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				class="header-icon"
			>
				<g opacity="0.5">
					<path
						d="M12 21V12"
						stroke="#A2A3AC"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10 19L12 21L14 19"
						stroke="#A2A3AC"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M3 8H21"
						stroke="#A2A3AC"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M8 16H6C4.343 16 3 14.657 3 13V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V13C21 14.657 19.657 16 18 16H16"
						stroke="#A2A3AC"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</svg>
			<div class="header-text">
				<div class="text-top">{{ t('LiveWithdraws.title') }}</div>
				<div class="text-bottom">
					{{ t('LiveWithdraws.total', [totalNormalized]) }}
				</div>
			</div>
		</div>
		<transition-group
			tag="div"
			class="withdraws-items"
			:name="transitions ? 'withdraw' : 'v'"
			:duration="500"
		>
			<Item
				v-for="withdraw in withdraws"
				:key="withdraw.id"
				:id="withdraw.id"
				:method="!!withdraw.method"
				:icon="withdraw.method ? withdraw.method.imageUrl : withdraw.item.icon"
				:amount="withdraw.amountUsd"
				:userID="withdraw.user.id"
				:avatar="withdraw.user.avatar"
				:name="withdraw.user.name"
			/>
		</transition-group>
	</div>
</template>
<script lang="ts">
import { WithdrawEntity } from '@/api'
import { useAPI } from '@/composables/api'
import { useSubscription } from '@/composables/useSubscription'
import {
	computed,
	defineComponent,
	nextTick,
	onMounted,
	reactive,
	toRefs
} from 'vue'
import { useI18n } from 'vue-i18n'
import Item from './Item.vue'

interface MethodProperties {
	amountUsd: number
	method?: { imageUrl: string }
}

type WithdrawLiveEntity = WithdrawEntity & MethodProperties

export default defineComponent({
	components: {
		Item
	},
	setup() {
		const api = useAPI()

		const state = reactive({
			liveWithdrawsSubscription: null,
			withdraws: [] as WithdrawLiveEntity[],
			transitions: false,
			total: 0
		})

		const getWithdraws = async () => {
			try {
				const { history, totalUsd } = await api({
					path: '/withdrawals/history'
				})

				state.withdraws = history
					.filter((item: any) => !item.amountUsd)
					.map((item: any) => {
						return {
							...item,
							amountUsd: item.amountUsd ? item.amountUsd : item.item.priceUsd
						}
					})

				state.total = parseInt(totalUsd, 10)

				await nextTick()
				await nextTick()

				state.transitions = true
			} catch (err) {
				// empty
			}
		}

		useSubscription<WithdrawLiveEntity>(
			'withdrawals',
			({ data }) => {
				if (data.amountUsd) {
					return
				}

				state.withdraws.splice(state.withdraws.length - 1, 1)
				state.withdraws.unshift({
					...data,
					amountUsd: data.amountUsd ? data.amountUsd : data.item.priceUsd
				})
				state.total += data.amountUsd ? data.amountUsd : data.item.priceUsd
			}
		)

		onMounted(() => {
			getWithdraws()
		})

		const totalNormalized = computed(() => {
			return Math.floor(state.total) / 100
		})

		const { t } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			totalNormalized,
			t
		}
	}
})
</script>
<style scoped>
.live-withdraws {
	height: 83px;
	display: flex;
	overflow: hidden;
	min-width: 0;
}

.withdraws-header {
	display: flex;
	padding: 20px;
	min-width: fit-content;
}

.header-icon {
	margin: auto 0;
}

.header-text {
	margin-left: 11px;
}

.text-top {
	font-weight: 500;
	font-size: 15px;
	line-height: 21px;
	color: #ffffff;
}

.text-bottom {
	margin-top: 4px;
	font-weight: normal;
	font-size: 11px;
	line-height: 15px;
	color: rgba(162, 163, 172, 0.5);
}

.withdraws-items {
	display: flex;
	min-width: 0;
	padding: 6px 0;
	gap: 15px;
}
</style>
