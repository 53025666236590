
import ItemIcon from '../ItemIcon/index.vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
	props: {
		id: {
			type: Number,
			required: true
		},
		active: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		stattrak: {
			type: Boolean,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		price: {
			type: Number,
			required: true
		},
		color: {
			type: String,
			required: true,
			validator: (color: string) => {
				if (color[0] !== '#') {
					return false
				}
				if (color.length !== 7) {
					return false
				}

				return true
			}
		},
		exterior: {
			type: String
		},
		volume: {
			type: Number,
			required: true
		},
		selectable: {
			type: Boolean,
			default: true
		},
		hoverable: {
			type: Boolean,
			default: true
		},
		imageSize: {
			type: Number,
			default: 233
		},
		noHover: {
			type: Boolean,
			default: false
		},
		showOverlay: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ItemIcon
	},
	setup(props) {
		const danger = computed(() => {
			return !isNaN(props.volume) && props.volume <= 3
		})

		const priceNormalized = computed(() => {
			return `$${(props.price / 100).toFixed(2)}`
		})

		const exteriorNormalized = computed(() => {
			if (props.exterior === 'Battle-Scarred') {
				return 'BS'
			}
			if (props.exterior === 'Well-Worn') {
				return 'WW'
			}
			if (props.exterior === 'Field-Tested') {
				return 'FT'
			}
			if (props.exterior === 'Minimal Wear') {
				return 'MW'
			}
			if (props.exterior === 'Factory New') {
				return 'FN'
			}

			return ''
		})

		const volumeNormalized = computed(() => {
			if (props.volume > 99) {
				return '99+'
			}

			return props.volume
		})

		const typeNormalized = computed(() => {
			return `${props.stattrak ? 'ST™ ' : ''}${props.type}`
		})

		const hover = computed(() => {
			return props.hoverable
		})

		const zoom = computed(() => {
			return props.type ? (props.type.includes('Graffiti') ? 1.5 : 1) : 1
		})

		return {
			danger,
			priceNormalized,
			exteriorNormalized,
			volumeNormalized,
			typeNormalized,
			hover,
			zoom
		}
	}
})
