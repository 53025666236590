
import { defineComponent, reactive, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseButton from '../BaseButton/index.vue'
import CheckBox from '../CheckBox/index.vue'

export default defineComponent({
	components: {
		CheckBox,
		BaseButton
	},
	emits: ['close'],
	setup(props, { emit }) {
		const state = reactive({
			accept: false
		})

		const auth = () => {
			if (!state.accept) {
				return
			}

			window.location.href = `https://${process.env.VUE_APP_VERCEL_URL ? 'api.inshop.pw' : 'api.inshop.gg'}/auth/steam`
		}

		const close = () => {
			emit('close')
		}

		const check = () => {
			state.accept = !state.accept
		}

		const { t } = useI18n({ useScope: 'global' })

		return {
			...toRefs(state),
			auth,
			close,
			check,
			t
		}
	}
})
