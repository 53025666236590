import { useI18n } from 'vue-i18n'
import { useAuthToken, getCookieValue } from './getAuthToken'

const host = process.env.VUE_APP_VERCEL_URL ? 'api.inshop.pw' : 'api.inshop.gg'

export const useAPI = () => {
	const { t } = useI18n()

	return async ({
		baseUrl = host,
		method = 'GET',
		path = '/',
		body = {},
		credentials = true,
		string = false
	}) => {
		const base = `https://${baseUrl}`
		const token = useAuthToken()
		const postParams = method !== 'GET' ? {
			body: JSON.stringify(body)
		} : {}

		const headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		} as {
			[key: string]: string
		}

		if (credentials) {
			headers.Authorization = `Bearer ${token}`
		}

		const res = await fetch(base + path, {
			method,
			credentials: credentials ? 'include' : 'omit',
			headers,
			...postParams
		})

		if (res.status > 300) {
			let text = ''

			try {
				const json = await res.json()

				if (json.error) {
					text = json.error.key
				}
			} catch (err) {
				// do nothing
			}

			if (!text) {
				text = 'Status: ' + res.status
			}

			if (text === 'GLOBAL.FORBIDDEN' && getCookieValue('token')) {
				localStorage.removeItem('token')
				window.location.href = 'https://api.inshop.gg/auth/steam'
				return
			}

			const key = `errors.${text}`
			const error = t(key)
			text = error !== key ? error : text

			console.error(path, postParams, text)

			const err = new Error(text)

			throw err
		}

		if (!string) {
			try {
				const json = await res.json()

				return json
			} catch (err) {
				return null
			}
		} else {
			try {
				const text = await res.text()

				return text
			} catch (err) {
				return ''
			}
		}
	}
}
