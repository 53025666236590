<template>
	<div class="base-button" :class="{ disabled: loading, shake, shine, grow, hover }">
		<div class="button-body">
			<transition>
				<Spinner
					key="spinner"
					v-if="loading"
					class="spinner-wrap"
					:class="{ transition: !loadingNoText }"
				/>
			</transition>
			<div class="body-left" key="body-left" v-if="!loadingNoText || !loading">
				<slot name="left" />
			</div>
			<div class="body-text" v-if="!loadingNoText || !loading"><slot /></div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
import Spinner from '../Spinner/index.vue'

export default defineComponent({
	props: {
		loading: {
			type: Boolean,
			default: false
		},
		loadingNoText: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Spinner
	},
	setup() {
		const state = reactive({
			shake: false,
			shine: false,
			grow: false,
			hover: false
		})

		const shakeOnce = () => {
			state.shake = true

			setTimeout(() => {
				state.shake = false
			}, 1000)
		}

		const shineOnce = () => {
			state.shine = true

			setTimeout(() => {
				state.shine = false
			}, 2000)
		}

		const highlightOnce = () => {
			state.grow = true
			state.hover = true

			setTimeout(() => {
				state.shine = true
			}, 350)

			setTimeout(() => {
				state.shine = false
				state.grow = false
				state.hover = false
			}, 2000)
		}

		return {
			...toRefs(state),
			shakeOnce,
			shineOnce,
			highlightOnce
		}
	}
})
</script>
<style scoped>
.base-button {
	height: 100%;
	width: 100%;
	border-radius: 20px;
	font-weight: 600;
	font-size: 15px;
	line-height: 17px;
	display: flex;
	cursor: pointer;
	position: relative;
	background: #242731;
	overflow: hidden;
	transition: transform 0.5s ease;
	align-items: center;
	justify-content: center;
}

.base-button.grow {
	transform: scale(1.35);
}

/* HOVER */
.base-button::before {
	content: '';
	position: absolute;
	top: 50%;
	left: -110%;
	transform: translate(0, -50%);
	width: 110%;
	height: 200px;
	border-radius: 50%;
	background: #1c1d24;
	opacity: 0.3;
	transition: all 0.3s ease-in-out;
}

.base-button:hover::before,
.base-button.hover::before {
	left: -5%;
}

.base-button.no-bg {
	background: none;
	border: 1px solid rgba(83, 85, 97, 0.25);
	box-sizing: border-box;
}

/* PRIMARY */
.base-button.primary {
	background: linear-gradient(90deg, #9130de 0%, #3f8cff 100%);
}

.base-button.primary-2 {
	background: linear-gradient(
		102.49deg,
		#1ff7fd 0%,
		#b33bf6 49.07%,
		#ff844c 84.59%,
		#ff844b 100%
	);
}

/* SUCCESS-DARK */
.base-button.success-dark {
	background: #1c1d24;
}

.base-button.success-dark .body-text {
	color: #97ed4c;
}

.base-button.success-dark::before {
	display: none;
}

.base-button.success-dark:hover,
.base-button.success-dark.hover {
	background: #97ed4c;
}

.base-button.success-dark:hover .body-left,
.base-button.success-dark.hover .body-left {
	fill: #1f222b;
	color: #97ed4c;
}

.base-button.success-dark:hover .body-text,
.base-button.success-dark.hover .body-text {
	color: #1f222b;
}

/* SUCCESS */
.base-button.success::before {
	display: none;
}

.base-button.success {
	background: #97ed4c;
	transition: background 0.15s ease-in-out;
}

.base-button.success .body-left,
.base-button.success .body-text {
	color: #1f222b;
	transition: color 0.15s ease-in-out;
}

.base-button.success:hover .body-text,
.base-button.success.hover .body-text,
.base-button.success:hover .body-left,
.base-button.success.hover .body-left {
	color: #fff;
}

.base-button.success:hover,
.base-button.success.hover {
	background: #2AAAFE;
}

/* SUCCESS */
/* .base-button.success::before {
	display: none;
}

.base-button.success {
	background: #97ed4c;
	box-sizing: border-box;
}

.base-button.success .body-left {
	color: #1f222b;
}

.base-button.success .body-text {
	color: #1f222b;
}

.base-button.success:hover .body-text,
.base-button.success.hover .body-text {
	color: #fff;
}

.base-button.success:hover,
.base-button.success.hover {
	background: none;
	border: 1px solid rgba(83, 85, 97, 0.25);
}

.base-button.success:hover .body-left,
.base-button.success.hover .body-left {
	color: #97ed4c;
} */

/* WARNING */
.base-button.warning .body-left {
	color: #fe932a;
}

.base-button.warning.color .body-text {
	color: #fe932a;
}

.base-button.warning.color:hover .body-text {
	color: #fff;
}

.base-button.warning::before {
	display: none;
}

.base-button.warning:hover,
.base-button.warning.hover {
	background: #fe932a;
}

.base-button.warning:hover .body-left,
.base-button.warning.hover .body-left {
	color: #fff;
}

/* WARNING-ALT */
.base-button.warning-alt {
	background: #fe932a;
}

.base-button.warning-alt .body-left,
.base-button.warning-alt .body-text {
	color: #fff;
}

.base-button.warning-alt::before {
	display: none;
}

.base-button.warning-alt:hover,
.base-button.warning-alt.hover {
	background: #242731;
}

/* SECONDARY */
.base-button.secondary .body-text {
	color: #a2a3ac;
}

.base-button.secondary .body-left {
	color: rgba(122, 124, 139, 0.27);
}

.base-button.secondary:hover,
.base-button.secondary.hover {
	background: rgba(83, 85, 97, 0.2);
}

.base-button.secondary:hover .body-text,
.base-button.secondary.hover .body-text {
	color: #ffffff;
}

.base-button.secondary.active .body-left {
	color: #2aaafe;
}

/* INFO */
.base-button.info {
	background: #8a24ff;
}

.base-button.info::before {
	background: linear-gradient(
		102.49deg,
		#1ff7fd 0%,
		#b33bf6 49.07%,
		#ff844c 84.59%,
		#ff844b 100%
	);
	opacity: 1;
}

/* WHITE */
.base-button.white {
	background: #ffffff;
}

.base-button.white::before {
	background: #2aaafe;
	opacity: 1;
}

.base-button.white .body-text,
.base-button.white .body-left {
	color: #1f222b;
	transition: color 0.25s ease-in-out;
}

.base-button.white:hover .body-text,
.base-button.white:hover .body-left,
.base-button.white.hover .body-text,
.base-button.white.hover .body-left {
	color: #fff;
}

/* DANGER */
.base-button.danger {
	background: #ff6339;
}

.base-button.danger::before {
	background: #e84f26;
	opacity: 1;
}

/* DISABLED */
.base-button.disabled,
.base-button.disabled:hover,
.base-button.disabled.hover {
	background: #313543;
	cursor: not-allowed;
}

.base-button.disabled .body-text,
.base-button.disabled .spinner-wrap {
	color: rgba(255, 255, 255, 0.2);
}

/* SIZES */
.base-button.lg {
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
}

.base-button.smb {
	font-weight: 600;
	font-size: 13px;
	line-height: 15px;
}

.base-button.sm {
	font-weight: normal;
	font-size: 14px;
	line-height: 10px;
}

.base-button.xs {
	font-weight: 600;
	font-size: 11px;
	line-height: 14px;
}

.base-button.xs .body-text {
	height: 12px;
}

/* BORDERS */
.base-button.b-4 {
	border-radius: 4px;
}

.base-button.b-8 {
	border-radius: 8px;
}

.base-button.b-10 {
	border-radius: 10px;
}

.base-button.b-14 {
	border-radius: 14px;
}

.base-button.b-16 {
	border-radius: 16px;
}

/* STYLE */
.base-button.uc {
	text-transform: uppercase;
}

/* WEIGHT */
.base-button.b {
	font-weight: bold;
}

.button-body {
	display: flex;
	z-index: 1;
	align-items: center;
	pointer-events: none;
}

.body-left:not(:empty) {
	display: flex;
	margin: auto 8px auto 0;
}

.base-button.xs .body-left:not(:empty) {
	display: flex;
	margin: auto 5px auto 0;
}

.body-text {
	margin: auto;
	color: #fff;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.spinner-wrap {
	margin: auto 0;
	color: #fff;
}

.spinner-wrap.transition {
	transition: all 0.25s ease-in-out;
	margin: auto 10px auto 0;
}

.v-enter.spinner-wrap.transition,
.v-leave-to.spinner-wrap.transition {
	opacity: -2;
	margin: auto -24px auto 0;
}

/* SHINE */
.base-button.shine::after {
	content: '';
	position: absolute;
	top: 0;
	left: -200%;
	width: 200%;
	height: 100%;
	transform: skewX(-20deg);
	background-image: linear-gradient(
		to right,
		transparent,
		rgba(255, 255, 255, 0.7),
		transparent
	);
	animation: shine 1.6s ease;
}

@keyframes shine {
	0% {
		left: -200%;
	}
	100% {
		left: 200%;
	}
}
</style>
