
import { computed, defineComponent, PropType } from '@vue/runtime-core'
import { useI18n } from 'vue-i18n'
import TooltipHover from '../TooltipHover/index.vue'

export default defineComponent({
	props: {
		group: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			required: true
		},
		displayName: {
			type: String,
			required: true
		},
		active: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: ''
		}
	},
	components: {
		TooltipHover
	},
	setup(props) {
		const { t } = useI18n({ useScope: 'global' })

		const nameNormalized = computed(() => {
			return (props.displayName ? props.displayName : (props.group ? props.group : props.name)).replace('blocked', 'adv').replace(/enot_/g, '').replace(/_/g, ' ')
		})

		return {
			nameNormalized,
			t
		}
	}
})
