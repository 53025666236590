import { defineStore } from 'pinia'

type AlertType =
	'success' |
	'danger' |
	'ref' |
	'buy' |
	'swap' |
	'copy' |
	'trade' |
	'deposit'

interface AlertPayload {
	text: string
	action?: string
	actionText?: string
	type: AlertType
	timeout?: number
	timeoutID?: NodeJS.Timeout
	meta?: any
}

interface Alert extends AlertPayload {
	id: number
	time: Date
}

export const useAlertStore = defineStore({
	id: 'alert',
	state() {
		return {
			alerts: [] as Alert[]
		}
	},
	actions: {
		pushAlert(payload: AlertPayload): void {
			const id = Date.now()
			const alert = { id, ...payload, time: new Date() }

			if (payload.timeout) {
				alert.timeoutID = setTimeout(() => {
					this.removeAlert(id)
				}, payload.timeout)
			}

			this.alerts.push(alert)
		},
		removeAlert(id: number): void {
			for (let i = 0; i < this.alerts.length; i++) {
				if (this.alerts[i].id === id) {
					this.alerts.splice(i, 1)
					break
				}
			}
		},
		cancelAutoRemove(id: number): void {
			for (let i = 0; i < this.alerts.length; i++) {
				if (this.alerts[i].id === id) {
					clearTimeout(this.alerts[i].timeoutID as NodeJS.Timeout)
					break
				}
			}
		}
	}
})
