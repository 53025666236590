export const useDebounce = (func: (...args: any[]) => any, wait: number, immediate = false): (...args: any[]) => void => {
	let timeout: NodeJS.Timeout | null = null

	return function (...args: any[]): void {
		const later = () => {
			timeout = null
			if (!immediate) {
				func(...args)
			}
		}

		const callNow = immediate && !timeout

		if (timeout) {
			clearTimeout(timeout)
		}
		timeout = setTimeout(later, wait)

		if (callNow) {
			func(...args)
		}
	}
}
