<template>
	<div class="nav-bar">
		<LiveWithdraws class="live-withdraws-wrap" />
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import LiveWithdraws from '../LiveWithdraws/index.vue'

export default defineComponent({
	components: {
		LiveWithdraws
	}
})
</script>
<style scoped>
.nav-bar {
	height: 83px;
	margin: 0 0 0 350px;
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	display: flex;
	min-width: 0;
}

.menu-wrap {
	margin: auto 0;
}

.live-withdraws-wrap {
	flex: 1;
}
</style>
