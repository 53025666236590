<template>
	<div class="item-icon" :style="{ 'background-image': background, 'background-size': backgroundSize }" />
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, toRefs, watch } from 'vue'

export default defineComponent({
	props: {
		icon: {
			type: String
		},
		zoom: {
			type: Number
		},
		size: {
			type: Number,
			default: 106
		}
	},
	setup(props) {
		const state = reactive({
			imageLoaded: false
		})

		const loadImage = () => {
			if (!props.icon) {
				return
			}

			const imgLarge = new Image()
			imgLarge.src = `https://steamcommunity-a.akamaihd.net/economy/image/${props.icon}/${props.size}fx${props.size}f`
			imgLarge.onload = () => {
				state.imageLoaded = true
			}
		}

		watch(
			() => props.icon,
			() => loadImage()
		)
		watch(
			() => props.size,
			() => loadImage()
		)
		onMounted(
			() => loadImage()
		)

		const background = computed(() => {
			if (!state.imageLoaded) {
				return false
			}

			return `url(https://steamcommunity-a.akamaihd.net/economy/image/${props.icon}/${props.size}fx${props.size}f)`
		})

		const backgroundSize = computed(() => {
			if (!props.zoom || !state.imageLoaded) {
				return false
			}

			return `${props.zoom * 100}% ${props.zoom * 100}%`
		})

		return {
			...toRefs(state),
			background,
			backgroundSize
		}
	}
})
</script>
<style scoped>
.item-icon {
	background-image: url('../../assets/icons/item_placeholder.svg');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
</style>
