
import Method from './Method.vue'
import BaseButton from '../BaseButton/index.vue'
import Spinner from '../Spinner/index.vue'
import CountrySelector from '../CountrySelector/index.vue'
import CurrencyDropdown from './CurrencyDropdown.vue'
import InputSmall from '../InputSmall/index.vue'
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { useModalStore } from '@/store/modal'
import { useAPI } from '@/composables/api'
import { useAlertStore } from '@/store/alert'
import { useI18n } from 'vue-i18n'
import { useLocaleAsync } from '../../composables/useLocale'

interface MethodEntity {
	id: number
	name: string
	currency: string
	available: boolean
	enabled: boolean
	provider: string
	minSumUsd: number
	imageUrl: string | null
	languages: string[]
	category: 'skins' | 'main' | 'banks' | 'sms' | 'crypto' | 'other'
	order: number | null
	group: string | null
	displayName: string | null
}

interface IMethod extends MethodEntity {
	iconType: 'svg' | 'png' | 'fallback'
}

interface Categories {
	[key: string]: IMethod[]
}

export default defineComponent({
	components: {
		Method,
		BaseButton,
		CurrencyDropdown,
		InputSmall,
		CountrySelector,
		Spinner
	},
	emits: ['close'],
	setup(_, { emit }) {
		const alert = useAlertStore()
		const api = useAPI()
		const { t, locale } = useI18n({ useScope: 'global' })

		const close = () => emit('close')

		const modal = useModalStore()

		const loading = computed(() => {
			return modal.deposit.loading
		})

		onMounted(() => {
			modal.deposit.loading = false
		})

		// amount and inputs
		const amountState = reactive({
			rates: {
				RUB: 1,
				EUR: 1
			},
			amount: '1.00',
			amountEur: '1.00',
			amountUsd: '1.00',
			amountRub: '1.00',
			currency: 'USD',
			mainCurrency: 'RUB',
			wallet: ''
		})

		const handleInput = () => {
			try {
				if (amountState.currency === 'USD') {
					amountState.amountUsd = amountState.amount
					amountState.amountRub = (Math.round(parseInt(amountState.amount, 10) * amountState.rates.RUB * 100) / 100).toString()
					amountState.amountEur = (Math.round(parseInt(amountState.amount, 10) * amountState.rates.EUR * 100) / 100).toString()
				} else if (amountState.currency === 'RUB') {
					amountState.amountUsd =
						(Math.round(parseInt(amountState.amount, 10) / amountState.rates.RUB * 100) / 100).toString()
					amountState.amountRub = amountState.amount
					amountState.amountEur =
						(Math.round(parseInt(amountState.amountUsd, 10) * amountState.rates.EUR * 100) / 100).toString()
				} else if (amountState.currency === 'EUR') {
					amountState.amountEur = amountState.amount
					amountState.amountUsd =
						(Math.round(parseInt(amountState.amount, 10) / amountState.rates.EUR * 100) / 100).toString()
					amountState.amountRub =
						(Math.round(parseInt(amountState.amountUsd, 10) * amountState.rates.RUB * 100) / 100).toString()
				}
			} catch (err) {
				// catch
			}
		}

		onMounted(() => async () => {
			try {
				amountState.rates = await api({ path: '/payments/rates' })
				handleInput()
			} catch (err) {
				// catch
			}
		})

		watch(
			() => amountState.amount,
			() => handleInput()
		)
		watch(
			() => amountState.currency,
			() => {
				if (amountState.currency === 'RUB') {
					amountState.amount = amountState.amountRub
				} else if (amountState.currency === 'USD') {
					amountState.amount = amountState.amountUsd
				} else if (amountState.currency === 'EUR') {
					amountState.amount = amountState.amountEur
				}
			}
		)

		// categories
		const catState = reactive({
			activeCategory: 'popular',
			activeMethod: null as IMethod | null,

			categories: {
				skins: [],
				main: [],
				crypto: [],
				banks: [],
				sms: [],
				other: []
			} as Categories
		})

		const setActive = (method: IMethod) => {
			catState.activeMethod = method
		}

		const setActiveCategory = (category: string) => {
			catState.activeCategory = category
			catState.activeMethod = null
		}

		const getUnmaskedWallet = () => {
			return amountState.wallet
				.replace(/ /g, '')
				.replace(/\(/g, '')
				.replace(/\)/g, '')
				.replace(/-/g, '')
		}

		const walletInput = computed(() => {
			return ['qiwi', 'mts', 'megafon', 'tele2', 'beeline', 'kassa24'].includes(
				catState.activeMethod ? catState.activeMethod.name : ''
			)
		})

		const walletMask = computed(() => {
			if (walletInput.value) {
				return ['# (###) ###-##-##', '### (###) ###-##-##']
			}

			return ''
		})

		const walletPlaceholder = computed(() => {
			if (walletInput.value) {
				return '7 (999) 000-00-00'
			}

			return ''
		})

		const amountInput = computed(() => {
			if (['skinpay', 'skinsback'].includes(catState.activeMethod ? catState.activeMethod.name : '')) {
				return false
			}

			return true
		})

		const href = computed(() => {
			let amountUsd = isNaN(parseFloat(amountState.amountUsd))
				? 0
				: Math.floor(parseFloat(amountState.amountUsd) * 100)
			const id = catState.activeMethod ? catState.activeMethod.id : null
			const wallet = getUnmaskedWallet()

			if (!amountInput.value) {
				amountUsd = 10
			}

			return `https://${process.env.VUE_APP_VERCEL_URL ? 'api.inshop.pw' : 'api.inshop.gg'}/payments/create?amountUsd=${amountUsd}&id=${id}&wallet=${wallet}`
		})

		const methodsLoading = ref(true)

		const getPaymentMethods = async () => {
			methodsLoading.value = true

			try {
				const methods: MethodEntity[] = await api({
					path: '/payments/methods',
					method: 'POST',
					body: {
						country: country.value
					}
				})

				const categories: {
					[key: string]: any[]
				} = {
					skins: [],
					main: [],
					banks: [],
					sms: [],
					crypto: [],
					other: []
				}

				for (let i = 0; i < methods.length; i++) {
					const m = methods[i]
					let { category } = m

					category = category.toLowerCase() as 'skins' | 'main' | 'banks' | 'sms' | 'crypto' | 'other'

					if (!['skins', 'main', 'banks', 'sms', 'crypto', 'other'].includes(category)) {
						console.error('unknown category', category, m)
						continue
					}

					categories[category].push({ ...m })
				}

				const keys = Object.keys(categories)
				for (let key of keys) {
					categories[key]
						.sort((a, b) => {
							if (a.order > b.order) return -1
							if (a.order < b.order) return 1
							return 0
						})
				}

				catState.categories = categories

				methodsLoading.value = false
			} catch (err) {
				console.error(err)
			}
		}

		const applyURLParams = async () => {
			try {
				const url = new URL(location.href)
				const amountUsd = url.searchParams.get('amountUsd')
				const wallet = url.searchParams.get('wallet')
				const id = url.searchParams.get('id')
				const lang = url.searchParams.get('lang')
				const countryParam = url.searchParams.get('country')

				if (lang) {
					await useLocaleAsync(lang)
				}

				if (countryParam) {
					country.value = countryParam
				}

				amountState.amount = amountUsd ? `${(parseInt(amountUsd, 10) / 100).toFixed(2)}` : '1.00'
				amountState.currency = 'USD'
				amountState.wallet = wallet ? wallet : ''
				handleInput()

				if (id) {
					const [method] = getAllMethods().filter(method => method.id === parseInt(id, 10))

					setActive(method)
				}

			} catch (error) {
				console.error(error)
			}
		}

		const getAllMethods = () => {
			const uniqueMethods = {} as { [key: string]: IMethod }

			for (const key in catState.categories) {
				for (let i = 0; i < catState.categories[key].length; i++) {
					uniqueMethods[catState.categories[key][i].name] = {
						...catState.categories[key][i]
					}
				}
			}

			const methods = []

			for (const method in uniqueMethods) {
				methods.push({
					...uniqueMethods[method]
				})
			}

			return methods
		}

		const methods = computed(() => {
			if (catState.activeCategory === 'popular') {
				const methods = getAllMethods()

				return methods.sort((a, b) => {
					if (a.order! > b.order!) return -1
					if (a.order! < b.order!) return 1
					return 0
				})
			}

			return catState.categories[catState.activeCategory]
		})

		const borderWallet = computed(() => {
			return !amountState.wallet
		})

		const borderAmount = computed(() => {
			return !amountState.amountUsd
		})

		const controls = computed(() => {
			return !!catState.activeMethod
		})

		const disabled = computed(() => {
			const amountUsd = isNaN(parseInt(amountState.amountUsd, 10))
				? 0
				: Math.floor(parseInt(amountState.amountUsd, 10) * 100)

			return (
				!catState.activeMethod ||
				(!amountUsd && amountInput.value) ||
				(walletInput.value && !amountState.wallet)
			)
		})

		// validates the parameters on click
		const paymentCreate = async (e: Event) => {
			if (disabled.value) {
				return e.preventDefault()
			}

			const minUsdCard = 100
			const minUsdBtc = 1800
			const minUsdEth = 1000

			let amountUsd = isNaN(parseInt(amountState.amountUsd, 10))
				? 0
				: Math.floor(parseInt(amountState.amountUsd, 10) * 100)
			const amountRub = Math.floor((amountUsd + 1) * amountState.rates.RUB)
			const method = catState.activeMethod
			const wallet = getUnmaskedWallet()

			if (!amountInput.value) {
				amountUsd = 10
			}

			if (!amountUsd) {
				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.enterSum') })

				return e.preventDefault()
			}
			if (!method) {
				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.chooseMethod') })

				return e.preventDefault()
			}
			if (!wallet && walletMask.value) {
				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.enterWallet') })

				return e.preventDefault()
			}
			if (amountUsd < method.minSumUsd) {
				const min = `$${(method.minSumUsd / 100).toFixed(2)}`

				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.minDeposit', [min]) })

				return e.preventDefault()
			}

			modal.deposit.loading = true
		}

		const banks = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.banks.length
		})

		const main = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.main.length
		})

		const sms = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.sms.length
		})

		const cryptoCategory = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.crypto.length
		})

		const skins = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.skins.length
		})

		const country = ref('')

		const getCountry = async () => {
			if (localStorage.getItem('country')) {
				country.value = localStorage.getItem('country') as string
				return
			}

			try {
				country.value = await api({ path: '/country', string: true })
			} catch (err) {
				// empty
			}
		}

		watch(country, (to, from) => {
			if (from) {
				localStorage.setItem('country', country.value)
			}

			getPaymentMethods()
		})

		onMounted(async () => {
			try {
				await getCountry()
				await getPaymentMethods()
				applyURLParams()
			} catch (error) {
				console.error(error)
			}
		})

		return {
			close,
			loading,
			paymentCreate,
			...toRefs(amountState),
			...toRefs(catState),
			setActive,
			setActiveCategory,
			href,
			methods,
			walletMask,
			walletPlaceholder,
			walletInput,
			amountInput,
			borderWallet,
			borderAmount,
			controls,
			disabled,
			t,
			banks,
			main,
			sms,
			cryptoCategory,
			skins,
			country,
			methodsLoading
		}
	}
})
