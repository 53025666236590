<template>
	<div class="lang">
		<Flag class="lang-flag" :lang="lang" />
		<div class="lang-name" v-if="full">{{ langFull }}</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { languages } from '../../composables/useLocale'
import Flag from './Flag.vue'
import validator from './LangValidator'

export default defineComponent({
	props: {
		lang: {
			type: String,
			required: true,
			validator
		},
		full: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Flag
	},
	setup(props) {
		const langFull = computed(() => {
			const [lang] = languages().filter(({ code }) => code === props.lang)
			
			if (!lang) {
				return 'Unknown'
			}

			return lang.name
		})

		return {
			langFull
		}
	}
})
</script>
<style scoped>
.lang {
	display: flex;
	color: #A2A3AC;
	align-items: center;
}

.lang-flag {
	/* margin-top: -1px; */
}

.lang-name {
	font-weight: 500;
	font-size: 15px;
	line-height: 15px;
	margin-left: 7px;
}
</style>
