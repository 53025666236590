import { useI18n } from 'vue-i18n'

export const useNormalizeDate = () => {
	const { t, locale } = useI18n()

	const declense = (count: number, declenses: [string, string, string]): string => {
		const hundred = count % 100
		const ten = count % 10

		if (hundred > 5 && hundred < 21) {
			return `${count} ${declenses[0]}`
		}
		if (ten === 1) {
			return `${count} ${declenses[1]}`
		}
		if (ten > 1 && ten < 5) {
			return `${count} ${declenses[2]}`
		}

		return `${count} ${declenses[0]}`
	}

	const minutesCount = (count: number): string => {
		if (locale.value === 'ru') {
			return declense(count, ['минут', 'минута', 'минуты'])
		}

		return `${count} ${count < 2 ? t('normalizeDate.minute') : t('normalizeDate.minutes')}`
	}

	const hoursCount = (count: number): string => {
		if (locale.value === 'ru') {
			return declense(count, ['часов', 'час', 'часа'])
		}

		return `${count} ${count < 2 ? t('normalizeDate.hour') : t('normalizeDate.hours')}`
	}

	const daysCount = (count: number): string => {
		if (locale.value === 'ru') {
			return declense(count, ['дней', 'день', 'дня'])
		}

		return `${count} ${count < 2 ? t('normalizeDate.day') : t('normalizeDate.days')}`
	}

	const addZero = (value: number): string => {
		if (value < 10) {
			return '0' + value
		}

		return value.toString()
	}

	const normalizeDate = (date: Date, suffix = '', noTime = false): string => {
		const s = Math.floor((Date.now() - date.getTime()) / 1000)

		suffix = suffix ? suffix : t('normalizeDate.suffix')
		const ifSuffix = suffix ? ` ${suffix}` : ''

		if (s < 60) {
			return t('normalizeDate.less') + ifSuffix
		}
		if (s < 3600) {
			return minutesCount(Math.floor(s / 60)) + ifSuffix
		}
		if (s < 86400) {
			return hoursCount(Math.floor(s / 3600)) + ifSuffix
		}
		if (s < 7 * 86400) {
			return daysCount(Math.floor(s / 86400)) + ifSuffix
		}

		const h = addZero(date.getHours())
		const m = addZero(date.getMinutes())
		const y = date.getFullYear().toString().slice(2, 4)
		const mo = addZero(date.getMonth() + 1)
		const d = addZero(date.getDate())

		if (noTime) {
			return `${d}.${mo}.${y}`
		}

		return `${h}:${m} ${d}.${mo}.${y}`
	}

	const getDate = (date: Date): string => {
		const y = date.getFullYear().toString().slice(2, 4)
		const mo = addZero(date.getMonth() + 1)
		const d = addZero(date.getDate())

		return `${d}.${mo}.${y}`
	}

	return {
		normalizeDate,
		getDate
	}
}
