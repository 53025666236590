
import { defineComponent } from 'vue'
import validator from './LangValidator'

export default defineComponent({
	props: {
		lang: {
			type: String,
			required: true,
			validator
		}
	}
})
