<template>
	<div class="deposit-container">
		<div class="deposit-main" :class="{ 'mt-10': alert }">
			<div class="main-menu">
				<CountrySelector class="country-desktop" v-model="country" full />
				<div class="menu-title" @click="setActiveCategory('popular')">{{ t('DepositContainer.title') }}</div>
				<div class="menu-title mobile" @click="setActiveCategory('popular')">
					<div class="title-text">{{ t('DepositContainer.titleShort') }}</div>
					<CountrySelector class="title-country" v-model="country" fullList right />
				</div>
				<div class="menu-categories">
					<div
						class="menu-category dep-method-banks-cat-btn"
						:class="{ active: activeCategory === 'main' }"
						@click="setActiveCategory('main')"
						v-if="main"
					>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="category-icon">
							<path
								d="M5 16H7"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M17 8H19"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M20 19H4C2.895 19 2 18.105 2 17V7C2 5.895 2.895 5 4 5H20C21.105 5 22 5.895 22 7V17C22 18.105 21.105 19 20 19Z"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M13.7678 10.2322C14.7441 11.2085 14.7441 12.7914 13.7678 13.7677C12.7915 14.744 11.2086 14.744 10.2323 13.7677C9.25595 12.7914 9.25595 11.2085 10.2323 10.2322C11.2086 9.25589 12.7915 9.25589 13.7678 10.2322"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="category-text">{{ t('DepositContainer.main') }}</div>
					</div>
					<div
						class="menu-category dep-method-money-cat-btn"
						:class="{ active: activeCategory === 'banks' }"
						@click="setActiveCategory('banks')"
						v-if="banks"
					>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="category-icon">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M20 22H4C2.895 22 2 21.105 2 20V4C2 2.895 2.895 2 4 2H20C21.105 2 22 2.895 22 4V20C22 21.105 21.105 22 20 22Z"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M18 19H6C5.448 19 5 18.552 5 18V6C5 5.448 5.448 5 6 5H18C18.552 5 19 5.448 19 6V18C19 18.552 18.552 19 18 19Z"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M11.4142 10.5858C12.1952 11.3669 12.1952 12.6332 11.4142 13.4142C10.6331 14.1953 9.36681 14.1953 8.58576 13.4142C7.80471 12.6332 7.80471 11.3669 8.58576 10.5858C9.36681 9.80475 10.6331 9.80475 11.4142 10.5858"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M19 10H17"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M19 14H17"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="category-text">{{ t('DepositContainer.banks') }}</div>
					</div>
					<div
						class="menu-category dep-method-sms-cat-btn"
						:class="{ active: activeCategory === 'sms' }"
						@click="setActiveCategory('sms')"
						v-if="sms"
					>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="category-icon">
							<path
								d="M14 15V19.129C14 20.162 13.162 21 12.129 21H4.871C3.838 21 3 20.162 3 19.129V4.871C3 3.838 3.838 3 4.871 3H12.128C13.162 3 14 3.838 14 4.871V7"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M6.29999 18.5H10.7"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M10.333 7H19.666C20.403 7 21 7.597 21 8.333V13.666C21 14.403 20.403 15 19.667 15H10.334C9.597 15 9 14.403 9 13.667V8.333C9 7.597 9.597 7 10.333 7Z"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M9 10H21"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="category-text">{{ t('DepositContainer.sms') }}</div>
					</div>
					<div
						class="menu-category dep-method-skins-cat-btn"
						:class="{ active: activeCategory === 'skins' }"
						@click="setActiveCategory('skins')"
						v-if="skins"
					>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="category-icon">
							<path
								d="M12.667 6.88679L15.0526 7.68197C15.5768 7.85669 16.1433 7.57342 16.318 7.04926L17.0021 4.99708L15.5873 3.58228C15.2121 3.20705 14.7031 2.99625 14.1725 2.99625H9.82753C9.29688 2.99625 8.78795 3.20705 8.41272 3.58228L6.99792 4.99708L7.68198 7.04926C7.85671 7.5734 8.42323 7.85669 8.94741 7.68204L11.333 6.88679"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M12 7.99833V22.0042"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M17.99 12L17.9901 19.5462C18 20.4446 17.4024 21.2365 16.5358 21.4734C13.5449 22.1811 10.4301 22.1811 7.4392 21.4734C6.57258 21.2365 5.97499 20.4446 5.98489 19.5462L5.985 12"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M6.99761 4.99708L4.97958 5.66975C4.36301 5.87528 3.88467 6.36748 3.69685 6.98968L2.16657 12.0589C1.97987 12.6774 1.94568 13.3318 2.06692 13.9664L2.87393 18.1902C2.96403 18.6618 3.37646 19.0029 3.8566 19.0029H5.9972"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M17.0024 4.99708L19.0204 5.66975C19.637 5.87528 20.1153 6.36748 20.3031 6.98968L21.8334 12.0589C22.0201 12.6774 22.0543 13.3318 21.9331 13.9664L21.1261 18.1902C21.036 18.6618 20.6235 19.0029 20.1434 19.0029H18.0028"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M9.07779 3.1273L12 7.99833L14.9222 3.1273"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="category-text">{{ t('DepositContainer.skins') }}</div>
					</div>
					<div
						class="menu-category dep-method-crypto-cat-btn"
						:class="{ active: activeCategory === 'crypto' }"
						@click="setActiveCategory('crypto')"
						v-if="cryptoCategory"
					>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="category-icon">
							<path
								d="M18.364 5.63604C21.8787 9.15076 21.8787 14.8492 18.364 18.3639C14.8493 21.8787 9.1508 21.8787 5.6361 18.3639C2.12138 14.8492 2.12138 9.15074 5.6361 5.63604C9.15082 2.12132 14.8493 2.12132 18.364 5.63604"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13.705 15.333H10.898C10.28 15.333 9.77802 14.832 9.77802 14.213V12.051H13.705C14.604 12.051 15.334 12.78 15.334 13.68V13.705C15.333 14.604 14.604 15.333 13.705 15.333V15.333Z"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M13.31 12.051H9.77802V9.804C9.77802 9.176 10.287 8.667 10.915 8.667H13.31C14.223 8.667 14.964 9.407 14.964 10.321V10.398C14.964 11.311 14.224 12.051 13.31 12.051Z"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10.89 8.67H8.67001"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10.9 15.33H8.67001"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M13.23 7.56V8.67"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10.5 7.56V8.67"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M10.5 16.44V15.33"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M13.23 16.44V15.33"
								stroke="currentColor"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
						<div class="category-text">{{ t('DepositContainer.crypto') }}</div>
					</div>
					<div class="menu-padding" />
				</div>
				<div class="menu-fade" />
			</div>
			<div class="main-content">
				<div class="content-methods" v-if="!methodsLoading">
					<Method
						class="method-wrap deposit-method-btn"
						:class="{
							[`deposit-method-${method.name}-btn`]: true
						}"
						v-for="(method, i) in methods"
						:key="`m-${String(i)}`"
						:group="method.group"
						:name="method.name"
						:displayName="method.displayName"
						:active="activeMethod && method.id === activeMethod.id"
						:icon="method.imageUrl"
						@click="setActive(method)"
					/>
				</div>
				<Spinner v-else class="spinner-wrap" :width="64" :height="64" />
				<div class="content-controls-wrap">
					<div class="content-controls one" v-if="walletInput">
						<InputSmall :mask="walletMask" :placeholder="walletPlaceholder" v-model="wallet">
							<template #left>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path
										d="M16.5952 13.125C16.3882 13.126 16.2212 13.294 16.2212 13.501C16.2212 13.708 16.3892 13.876 16.5962 13.875C16.8032 13.875 16.9712 13.707 16.9712 13.5C16.9712 13.293 16.8032 13.125 16.5952 13.125"
										stroke="currentColor"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
									<path
										d="M3 5V18.5C3 19.605 3.895 20.5 5 20.5H19C20.105 20.5 21 19.605 21 18.5V8.5C21 7.395 20.105 6.5 19 6.5H4.5C3.672 6.5 3 5.828 3 5V5C3 4.172 3.672 3.5 4.5 3.5H17"
										stroke="currentColor"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</template>
						</InputSmall>
					</div>
					<div class="content-controls" :class="{ one: !amountInput }">
						<InputSmall v-model="amount" v-if="amountInput" placeholder="123,00">
							<template #left>
								<CurrencyDropdown class="currency-dropdown-wrap" v-model="currency" />
							</template>
						</InputSmall>
						<a @click="paymentCreate" :href="href" target="_blank" class="controls-link-wrap">
							<BaseButton
								class="success b-10 checkout-btn"
								:class="{ disabled }"
								:loading="loading"
							>{{ t('DepositContainer.deposit') }}</BaseButton>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import Method from './Method.vue'
import BaseButton from '../BaseButton/index.vue'
import Spinner from '../Spinner/index.vue'
import CountrySelector from '../CountrySelector/index.vue'
import CurrencyDropdown from './CurrencyDropdown.vue'
import InputSmall from '../InputSmall/index.vue'
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { useModalStore } from '@/store/modal'
import { useAPI } from '@/composables/api'
import { useAlertStore } from '@/store/alert'
import { useI18n } from 'vue-i18n'
import { useLocaleAsync } from '../../composables/useLocale'

interface MethodEntity {
	id: number
	name: string
	currency: string
	available: boolean
	enabled: boolean
	provider: string
	minSumUsd: number
	imageUrl: string | null
	languages: string[]
	category: 'skins' | 'main' | 'banks' | 'sms' | 'crypto' | 'other'
	order: number | null
	group: string | null
	displayName: string | null
}

interface IMethod extends MethodEntity {
	iconType: 'svg' | 'png' | 'fallback'
}

interface Categories {
	[key: string]: IMethod[]
}

export default defineComponent({
	components: {
		Method,
		BaseButton,
		CurrencyDropdown,
		InputSmall,
		CountrySelector,
		Spinner
	},
	emits: ['close'],
	setup(_, { emit }) {
		const alert = useAlertStore()
		const api = useAPI()
		const { t, locale } = useI18n({ useScope: 'global' })

		const close = () => emit('close')

		const modal = useModalStore()

		const loading = computed(() => {
			return modal.deposit.loading
		})

		onMounted(() => {
			modal.deposit.loading = false
		})

		// amount and inputs
		const amountState = reactive({
			rates: {
				RUB: 1,
				EUR: 1
			},
			amount: '1.00',
			amountEur: '1.00',
			amountUsd: '1.00',
			amountRub: '1.00',
			currency: 'USD',
			mainCurrency: 'RUB',
			wallet: ''
		})

		const handleInput = () => {
			try {
				if (amountState.currency === 'USD') {
					amountState.amountUsd = amountState.amount
					amountState.amountRub = (Math.round(parseInt(amountState.amount, 10) * amountState.rates.RUB * 100) / 100).toString()
					amountState.amountEur = (Math.round(parseInt(amountState.amount, 10) * amountState.rates.EUR * 100) / 100).toString()
				} else if (amountState.currency === 'RUB') {
					amountState.amountUsd =
						(Math.round(parseInt(amountState.amount, 10) / amountState.rates.RUB * 100) / 100).toString()
					amountState.amountRub = amountState.amount
					amountState.amountEur =
						(Math.round(parseInt(amountState.amountUsd, 10) * amountState.rates.EUR * 100) / 100).toString()
				} else if (amountState.currency === 'EUR') {
					amountState.amountEur = amountState.amount
					amountState.amountUsd =
						(Math.round(parseInt(amountState.amount, 10) / amountState.rates.EUR * 100) / 100).toString()
					amountState.amountRub =
						(Math.round(parseInt(amountState.amountUsd, 10) * amountState.rates.RUB * 100) / 100).toString()
				}
			} catch (err) {
				// catch
			}
		}

		onMounted(() => async () => {
			try {
				amountState.rates = await api({ path: '/payments/rates' })
				handleInput()
			} catch (err) {
				// catch
			}
		})

		watch(
			() => amountState.amount,
			() => handleInput()
		)
		watch(
			() => amountState.currency,
			() => {
				if (amountState.currency === 'RUB') {
					amountState.amount = amountState.amountRub
				} else if (amountState.currency === 'USD') {
					amountState.amount = amountState.amountUsd
				} else if (amountState.currency === 'EUR') {
					amountState.amount = amountState.amountEur
				}
			}
		)

		// categories
		const catState = reactive({
			activeCategory: 'popular',
			activeMethod: null as IMethod | null,

			categories: {
				skins: [],
				main: [],
				crypto: [],
				banks: [],
				sms: [],
				other: []
			} as Categories
		})

		const setActive = (method: IMethod) => {
			catState.activeMethod = method
		}

		const setActiveCategory = (category: string) => {
			catState.activeCategory = category
			catState.activeMethod = null
		}

		const getUnmaskedWallet = () => {
			return amountState.wallet
				.replace(/ /g, '')
				.replace(/\(/g, '')
				.replace(/\)/g, '')
				.replace(/-/g, '')
		}

		const walletInput = computed(() => {
			return ['qiwi', 'mts', 'megafon', 'tele2', 'beeline', 'kassa24'].includes(
				catState.activeMethod ? catState.activeMethod.name : ''
			)
		})

		const walletMask = computed(() => {
			if (walletInput.value) {
				return ['# (###) ###-##-##', '### (###) ###-##-##']
			}

			return ''
		})

		const walletPlaceholder = computed(() => {
			if (walletInput.value) {
				return '7 (999) 000-00-00'
			}

			return ''
		})

		const amountInput = computed(() => {
			if (['skinpay', 'skinsback'].includes(catState.activeMethod ? catState.activeMethod.name : '')) {
				return false
			}

			return true
		})

		const href = computed(() => {
			let amountUsd = isNaN(parseFloat(amountState.amountUsd))
				? 0
				: Math.floor(parseFloat(amountState.amountUsd) * 100)
			const id = catState.activeMethod ? catState.activeMethod.id : null
			const wallet = getUnmaskedWallet()

			if (!amountInput.value) {
				amountUsd = 10
			}

			return `https://${process.env.VUE_APP_VERCEL_URL ? 'api.inshop.pw' : 'api.inshop.gg'}/payments/create?amountUsd=${amountUsd}&id=${id}&wallet=${wallet}`
		})

		const methodsLoading = ref(true)

		const getPaymentMethods = async () => {
			methodsLoading.value = true

			try {
				const methods: MethodEntity[] = await api({
					path: '/payments/methods',
					method: 'POST',
					body: {
						country: country.value
					}
				})

				const categories: {
					[key: string]: any[]
				} = {
					skins: [],
					main: [],
					banks: [],
					sms: [],
					crypto: [],
					other: []
				}

				for (let i = 0; i < methods.length; i++) {
					const m = methods[i]
					let { category } = m

					category = category.toLowerCase() as 'skins' | 'main' | 'banks' | 'sms' | 'crypto' | 'other'

					if (!['skins', 'main', 'banks', 'sms', 'crypto', 'other'].includes(category)) {
						console.error('unknown category', category, m)
						continue
					}

					categories[category].push({ ...m })
				}

				const keys = Object.keys(categories)
				for (let key of keys) {
					categories[key]
						.sort((a, b) => {
							if (a.order > b.order) return -1
							if (a.order < b.order) return 1
							return 0
						})
				}

				catState.categories = categories

				methodsLoading.value = false
			} catch (err) {
				console.error(err)
			}
		}

		const applyURLParams = async () => {
			try {
				const url = new URL(location.href)
				const amountUsd = url.searchParams.get('amountUsd')
				const wallet = url.searchParams.get('wallet')
				const id = url.searchParams.get('id')
				const lang = url.searchParams.get('lang')
				const countryParam = url.searchParams.get('country')

				if (lang) {
					await useLocaleAsync(lang)
				}

				if (countryParam) {
					country.value = countryParam
				}

				amountState.amount = amountUsd ? `${(parseInt(amountUsd, 10) / 100).toFixed(2)}` : '1.00'
				amountState.currency = 'USD'
				amountState.wallet = wallet ? wallet : ''
				handleInput()

				if (id) {
					const [method] = getAllMethods().filter(method => method.id === parseInt(id, 10))

					setActive(method)
				}

			} catch (error) {
				console.error(error)
			}
		}

		const getAllMethods = () => {
			const uniqueMethods = {} as { [key: string]: IMethod }

			for (const key in catState.categories) {
				for (let i = 0; i < catState.categories[key].length; i++) {
					uniqueMethods[catState.categories[key][i].name] = {
						...catState.categories[key][i]
					}
				}
			}

			const methods = []

			for (const method in uniqueMethods) {
				methods.push({
					...uniqueMethods[method]
				})
			}

			return methods
		}

		const methods = computed(() => {
			if (catState.activeCategory === 'popular') {
				const methods = getAllMethods()

				return methods.sort((a, b) => {
					if (a.order! > b.order!) return -1
					if (a.order! < b.order!) return 1
					return 0
				})
			}

			return catState.categories[catState.activeCategory]
		})

		const borderWallet = computed(() => {
			return !amountState.wallet
		})

		const borderAmount = computed(() => {
			return !amountState.amountUsd
		})

		const controls = computed(() => {
			return !!catState.activeMethod
		})

		const disabled = computed(() => {
			const amountUsd = isNaN(parseInt(amountState.amountUsd, 10))
				? 0
				: Math.floor(parseInt(amountState.amountUsd, 10) * 100)

			return (
				!catState.activeMethod ||
				(!amountUsd && amountInput.value) ||
				(walletInput.value && !amountState.wallet)
			)
		})

		// validates the parameters on click
		const paymentCreate = async (e: Event) => {
			if (disabled.value) {
				return e.preventDefault()
			}

			const minUsdCard = 100
			const minUsdBtc = 1800
			const minUsdEth = 1000

			let amountUsd = isNaN(parseInt(amountState.amountUsd, 10))
				? 0
				: Math.floor(parseInt(amountState.amountUsd, 10) * 100)
			const amountRub = Math.floor((amountUsd + 1) * amountState.rates.RUB)
			const method = catState.activeMethod
			const wallet = getUnmaskedWallet()

			if (!amountInput.value) {
				amountUsd = 10
			}

			if (!amountUsd) {
				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.enterSum') })

				return e.preventDefault()
			}
			if (!method) {
				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.chooseMethod') })

				return e.preventDefault()
			}
			if (!wallet && walletMask.value) {
				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.enterWallet') })

				return e.preventDefault()
			}
			if (amountUsd < method.minSumUsd) {
				const min = `$${(method.minSumUsd / 100).toFixed(2)}`

				alert.pushAlert({ type: 'danger', text: t('DepositContainer.errors.minDeposit', [min]) })

				return e.preventDefault()
			}

			modal.deposit.loading = true
		}

		const banks = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.banks.length
		})

		const main = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.main.length
		})

		const sms = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.sms.length
		})

		const cryptoCategory = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.crypto.length
		})

		const skins = computed(() => {
			if (!Object.keys(catState.categories).length) {
				return false
			}

			return !!catState.categories.skins.length
		})

		const country = ref('')

		const getCountry = async () => {
			if (localStorage.getItem('country')) {
				country.value = localStorage.getItem('country') as string
				return
			}

			try {
				country.value = await api({ path: '/country', string: true })
			} catch (err) {
				// empty
			}
		}

		watch(country, (to, from) => {
			if (from) {
				localStorage.setItem('country', country.value)
			}

			getPaymentMethods()
		})

		onMounted(async () => {
			try {
				await getCountry()
				await getPaymentMethods()
				applyURLParams()
			} catch (error) {
				console.error(error)
			}
		})

		return {
			close,
			loading,
			paymentCreate,
			...toRefs(amountState),
			...toRefs(catState),
			setActive,
			setActiveCategory,
			href,
			methods,
			walletMask,
			walletPlaceholder,
			walletInput,
			amountInput,
			borderWallet,
			borderAmount,
			controls,
			disabled,
			t,
			banks,
			main,
			sms,
			cryptoCategory,
			skins,
			country,
			methodsLoading
		}
	}
})
</script>
<style scoped>
.deposit-container {
	width: 760px;
}

#itez-container {
	z-index: 2;
}

.spinner-wrap {
	margin: auto;
	color: rgba(175, 177, 192, 0.5);
	z-index: 1;
}

.deposit-main {
	display: grid;
	height: 548px;
	grid-template-columns: 250px 1fr;
	align-items: stretch;
}

.deposit-main.mt-10 {
	margin-top: 10px;
}

.main-menu {
	padding: 40px 20px;
	background: linear-gradient(
			270deg,
			rgba(26, 27, 34, 0.6) 0%,
			rgba(28, 29, 36, 0) 9.6%
		),
		#1c1d24;
	border-radius: 15px 0px 0px 15px;
}

.menu-title {
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;
	margin: 20px 20px 0 20px;
	color: #ffffff;
	cursor: pointer;
}

.menu-title.mobile {
	display: none;
}

.title-country {
	margin-left: auto;
	z-index: 10;
}

.menu-categories {
	display: grid;
	grid-template-rows: repeat(5, 54px);
	grid-gap: 8px;
	margin-top: 20px;
}

.menu-category {
	color: #a2a3ac;
	display: grid;
	grid-template-columns: 24px 1fr;
	grid-gap: 16px;
	align-items: center;
	padding: 12px 19px;
	border: 1px solid transparent;
	cursor: pointer;
}

.menu-category:hover {
	color: #97ed4c;
}

.menu-category.active {
	color: #97ed4c;
	font-weight: bold;
	background: #1f222b;
	border-radius: 10px;
}

.category-text {
	white-space: nowrap;
}

.menu-padding {
	display: none;
	padding-right: 50px;
}

.main-content {
	border-radius: 0px 15px 15px 0px;
	background: #1f222b;
	padding: 40px;
	display: flex;
	flex-direction: column;
	min-height: 0;
}

.content-methods {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 135px));
	grid-auto-rows: 95px;
	grid-gap: 10px;
	margin-bottom: auto;
	flex: 1;
	min-height: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	margin-right: -5px;
	padding-right: 5px;
}

.content-controls {
	margin-top: 10px;
	height: 44px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 10px;
}

.content-controls.one {
	grid-template-columns: 1fr;
}

.controls-link-wrap {
	text-decoration: none;
}

.input-icon-wrap {
	background: #1c1d24;
	grid-column: 1 / 2 span;
}

.controls-input {
	background: #1c1d24;
	border-radius: 14px;
	display: flex;
	height: 44px;
	min-width: 0;
	align-items: center;
}

.input-field {
	background: none;
	border: none;
	height: 100%;
	padding: 15.5px 10px 15.5px 20px;
	font-weight: 600;
	font-size: 16px;
	line-height: 11px;
	color: #ffffff;
	box-sizing: border-box;
	outline: none;
	cursor: text;
	flex: 1;
	min-width: 0;
}

.input-field::-webkit-outer-spin-button,
.input-field::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.input-field[type="number"] {
	-moz-appearance: textfield;
}

.input-field::placeholder {
	color: rgba(162, 163, 172, 0.5);
	opacity: 1;
}

.input-field:-ms-input-placeholder {
	color: rgba(162, 163, 172, 0.5);
}

.input-field::-ms-input-placeholder {
	color: rgba(162, 163, 172, 0.5);
}

.controls-currency {
	font-weight: normal;
	font-size: 12px;
	line-height: 70%;
	color: #a2a3ac;
}

.currency-dropdown-wrap {
	z-index: 2;
}

.menu-fade {
	display: none;
	position: absolute;
	bottom: 0;
	right: 0;
	background: linear-gradient(270deg, #1c1d24 30.21%, rgba(28, 29, 36, 0) 100%);
	width: 48px;
	height: 70px;
	pointer-events: none;
	border-radius: 16px;
}

@media (max-width: 950px) {
	.spinner-wrap {
		min-height: 200px;
	}

	.country-desktop {
		display: none;
	}

	.deposit-container {
		width: 100%;
		padding-bottom: 10px;
	}

	.warning-wrap {
		display: none;
	}

	.deposit-main {
		display: flex;
		flex-direction: column;
		height: unset;
		margin-top: 0 !important;
		overflow: unset;
	}

	.main-menu {
		background: none;
		padding: 0;
		position: relative;
	}

	.menu-title {
		margin: 0;
	}

	.menu-title.mobile {
		display: flex;
		align-items: center;
	}

	.title-btn {
		background: #1c1d24;
	}

	.title-text {
		font-weight: normal;
		font-size: 30px;
		line-height: 33px;
		color: #ffffff;
		margin-left: 10px;
	}

	.menu-title:not(.mobile) {
		display: none;
	}

	.menu-categories {
		background: #2b2e39;
		border-radius: 8px;
		flex-direction: row;
		display: flex;
		padding: 0 10px;
		flex: 1;
		overflow-x: scroll;
		overflow-y: hidden;
		grid-gap: 10px;
		margin-top: 25px;
	}

	.menu-categories::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	.menu-category {
		grid-gap: 5px;
		padding: 13px 10px;
		font-size: 14px;
		line-height: 18px;
		font-weight: 500;
	}

	.menu-category.active {
		color: #fff;
		font-weight: bold;
		background: none;
	}

	.menu-padding {
		display: unset;
	}

	.menu-fade {
		display: none;
	}

	.main-content {
		padding: 0;
		margin-top: 10px;
		background: none;
	}

	.content-methods {
		grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
		min-height: 200px;
		max-height: 200px;
	}

	.method-wrap {
		background: #2b2e39;
	}

	.content-controls-wrap {
		background: #242731;
		border-radius: 16px;
		padding: 0;
		margin-top: 0;
	}

	.content-controls {
		grid-template-columns: 1fr;
		height: unset;
		grid-auto-rows: 44px;
	}

	.input-icon-wrap {
		background: #1f222b;
	}

	.controls-input {
		background: #1f222b;
	}
}
</style>
