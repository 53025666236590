
import { useLocaleAsync } from './composables/useLocale'
import NavBar from './components/NavBar/index.vue'
import NavMenu from './components/NavMenu/index.vue'
import NavBarTopMobile from './components/NavBarTopMobile/index.vue'
import DepositContainer from './components/DepositContainer/index.vue'
import MiniProfile from './components/MiniProfile/index.vue'
import LogoHorizontal from './components/LogoHorizontal/index.vue'
import LangSelector from './components/LangSelector/index.vue'
import Alerts from './components/Alerts/index.vue'
import BaseButton from './components/BaseButton/index.vue'
import Modal from './components/Modal/index.vue'
import Footer from './components/Footer/index.vue'
import AuthModal from './components/AuthModal/index.vue'
import { useSuspenseErrorCatch } from './composables/useSuspenseErrorCatch'
import { defineComponent, computed, toRefs, ComponentPublicInstance, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useResponsiveStore } from './store/responsive'
import { useSubscription } from './composables/useSubscription'
import { useUserID } from './composables/getAuthToken'
import { useAlertStore } from './store/alert'
import { useModalStore } from './store/modal'
import { useUserStore } from './store/user'
import { useI18n } from 'vue-i18n'
import { useResizeTracking } from './composables/useResizeTracking'

interface ViewComponent extends ComponentPublicInstance<HTMLElement> {
	scrollHandler?: (container: string) => void
}

export default defineComponent({
	components: {
		BaseButton,
		NavBar,
		NavBarTopMobile,
		MiniProfile,
		LogoHorizontal,
		LangSelector,
		Alerts,
		Modal,
		Footer,
		NavMenu,
		AuthModal,
		DepositContainer
	},
	setup() {
		const route = useRoute()
		const alert = useAlertStore()
		const { t } = useI18n({ useScope: 'global' })

		useSuspenseErrorCatch()
		useResizeTracking()

		useLocaleAsync(route.query.lang as string)

		// subscription handlers
		const modal = useModalStore()
		const user = useUserStore()
		const userID = useUserID()

		user.get()

		if (userID) {
			useSubscription<any>(`user#${userID}`, ({ data, event }) => {
				if (event === 0) {

					alert.pushAlert({ type: 'deposit', text: t('AppFrame.depositAlert', [`$${(data.amountUsd / 100).toFixed(2)}`]) })

					// reset deposit container loading state
					modal.deposit.loading = false
				}
				if (event === 1) {
					user.balanceUsd = data.balanceUsd
				}
			})
		}

		// styles
		const leftBg = computed(() => {
			return (
				route.path === '/' ||
				route.path === '/auth' ||
				route.path.includes('/profile')
			)
		})
		const notPopup = computed(() => {
			return !(route.path === '/shop' || route.path === '/deposit')
		})

		// modal control
		const router = useRouter()

		const depositModalOpen = (): void => {
			modal.deposit.enabled = true
		}

		const depositModalClose = (test: any): void => {
			if (test && test.path) {
				const classes = test.path.map((el: HTMLElement) => el.className)

				if (classes.includes('alert-btn')) {
					return
				}
			}

			modal.deposit.enabled = false
		}

		onMounted(async () => {
			if (location.href.includes('country=')) {
				const url = new URL(location.href)

				const country = url.searchParams.get('country')

				if (country) {
					localStorage.setItem('country', country)
				}
			}
			if (location.href.includes('?open=deposit')) {
				depositModalOpen()
			}
			if (location.href.includes('lang=')) {
				const url = new URL(location.href)

				const lang = url.searchParams.get('lang')

				if (lang) {
					await useLocaleAsync(lang)
				}
			}
		})

		const authModal = computed(() => {
			return route.path === '/auth'
		})

		const authModalClose = () => {
			router.push('/')
		}

		const { mobile, vh } = toRefs(useResponsiveStore())

		const balanceUsd = computed(() => {
			return `$${user.balanceUsd / 100}`
		})

		const depositModal = computed(() => {
			return modal.deposit.enabled
		})

		const view = ref<ViewComponent>()
		const center = ref<HTMLElement>()

		const scrollHandler = () => {
			if (view.value && view.value.scrollHandler) {
				view.value.scrollHandler('center')
			}
		}

		return {
			user,
			mobile,
			depositModal,
			depositModalOpen,
			depositModalClose,
			leftBg,
			notPopup,
			authModal,
			authModalClose,
			t,
			balanceUsd,
			view,
			center,
			scrollHandler,
			userID,
			vh
		}
	}
})
