<template>
	<div class="alerts">
		<transition-group :duration="500">
			<Alert
				v-for="alert in alerts"
				:key="alert.id"
				:type="alert.type"
				:text="alert.text"
				:time="alert.time"
				:action="!!alert.action"
				:actionText="alert.actionText"
				@close="close(alert.id)"
				@action="action(alert.id)"
				class="alert-wrap"
				@mouseover="cancelAutoRemove(alert.id)"
			/>
		</transition-group>
	</div>
</template>
<script lang="ts">
import Alert from './Alert.vue'
import { defineComponent, onDeactivated } from '@vue/runtime-core'
import { useAlertStore } from '@/store/alert'
import { toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
	components: {
		Alert
	},
	setup() {
		const { t } = useI18n({ useScope: 'global' })
		const alert = useAlertStore()
		const { alerts } = toRefs(alert)

		// service worker update handler
		const updateHandler = () => alert.pushAlert({
			text: t('Alerts.update'),
			type: 'success',
			timeout: 0
		})

		window.addEventListener('update', updateHandler)

		onDeactivated(() => {
			window.addEventListener('update', updateHandler)
		})

		// action handling
		const openTrade = (id: number): void => {
			window.open(`https://steamcommunity.com/tradeoffer/${id}`, '_blank')
		}

		const router = useRouter()
		const openShop = (): void => {
			router.push('/')
		}

		const close = (id: number): void => {
			alert.removeAlert(id)
		}

		const action = (id: number): void => {
			let alert = null
			for (let i = 0; i < alerts.value.length; i++) {
				if (alerts.value[i].id === id) {
					alert = alerts.value[i]
					break
				}
			}

			if (!alert) {
				return
			}

			if (alert.action === 'trade') {
				openTrade(alert.meta?.tradeID)
				close(id)
			}
			if (alert.action === 'shop') {
				openShop()
				close(id)
			}
		}

		const cancelAutoRemove = (id: number): void => {
			alert.cancelAutoRemove(id)
		}

		return {
			alerts,
			close,
			action,
			cancelAutoRemove
		}
	}
})
</script>
<style scoped>
.alerts {
	position: relative;
	overflow: hidden;
}

.alert-wrap {
	margin-bottom: 10px;
}
</style>
