<template>
	<div class="filter-dropdown" :class="{ active }" @click="toggle" v-click-outside="close">
		<div class="dropdown-value">
			<div class="value-text">{{ activeValue }}</div>
			<svg width="11" height="8" viewBox="0 0 11 8" fill="none" class="value-caret">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.50628 1.19378C9.84799 0.852073 10.402 0.852073 10.7437 1.19378C11.0854 1.53549 11.0854 2.08951 10.7437 2.43122L6.36872 6.80622C6.02701 7.14793 5.47299 7.14793 5.13128 6.80622L0.756282 2.43122C0.414573 2.08951 0.414573 1.53549 0.756282 1.19378C1.09799 0.852073 1.65201 0.852073 1.99372 1.19378L5.75 4.95006L9.50628 1.19378Z"
					fill="currentColor"
				/>
			</svg>
		</div>
		<div class="dropdown-items" v-if="active">
			<div
				class="dropdown-item"
				v-for="item in filteredItems"
				:key="item"
				@click="select(item)"
			>{{ item }}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, reactive, toRefs } from 'vue'
import { directive as clickOutside } from 'click-outside-vue3'

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			required: true
		},
		items: {
			type: Object as PropType<string[]>,
			required: true
		}
	},
	directives: {
		clickOutside
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const state = reactive({
			active: false
		})

		const toggle = () => {
			state.active = !state.active
		}

		const close = () => {
			state.active = false
		}

		const activeValue = computed(() => {
			return props.modelValue ? props.modelValue : props.placeholder
		})

		const select = (item: string) => {
			emit('update:modelValue', item)
		}

		const filteredItems = computed(() => {
			return props.items.filter(item => item !== props.modelValue)
		})

		return {
			...toRefs(state),
			toggle,
			close,
			activeValue,
			select,
			filteredItems
		}
	}
})
</script>
<style scoped>
.filter-dropdown {
	border: 1px solid rgba(175, 177, 192, 0.1);
	box-sizing: border-box;
	border-radius: 8px;
	padding: 3px 0 0 0;
	cursor: pointer;
	position: relative;
	z-index: 13;
}

.filter-dropdown.active {
	background: #1a1b23;
	border: 1px solid #1a1b23;
	border-radius: 8px 8px 0 0;
}

.dropdown-value {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #a2a3ac;
	padding: 10px 20px;
}

.filter-dropdown:hover .dropdown-value,
.filter-dropdown.active .dropdown-value {
	color: #fff;
}

.value-caret {
	transition: transform 0.25s ease-in-out;
}

.filter-dropdown.active .value-caret {
	transform: rotate(180deg);
}

.dropdown-items {
	padding: 0 0 3px 0;
	background: #1a1b23;
	border: 1px solid #1a1b23;
	border-radius: 0 0 8px 8px;
	position: absolute;
	top: 100%;
	left: -1px;
	width: 100%;
	height: 300px;
	overflow-y: scroll;
}

.dropdown-item {
	font-weight: normal;
	font-size: 14px;
	line-height: 18px;
	color: #a2a3ac;
	padding: 10px 20px;
}

.dropdown-item:hover {
	color: #fff;
}
</style>
