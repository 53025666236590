
import { computed, defineComponent } from 'vue'
import ItemIcon from '../ItemIcon/index.vue'

export default defineComponent({
	props: {
		id: {
			type: Number,
			required: true
		},
		icon: {
			type: String,
			required: true
		},
		amount: {
			type: Number,
			required: true
		},
		userID: {
			type: Number,
			required: true
		},
		avatar: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		method: {
			type: Boolean,
			required: true
		}
	},
	components: {
		ItemIcon
	},
	setup(props) {
		const amountNormalized = computed(() => {
			return `$${props.amount / 100}`
		})

		const active = computed(() => {
			return props.amount >= 2000
		})

		const color = computed(() => {
			if (props.amount >= 10000) {
				return '#fe932a'
			}
			if (props.amount >= 5000) {
				return '#9130DE'
			}
			if (props.amount >= 2000) {
				return '#2AAAFE'
			}

			return '#000000'
		})

		const background = computed(() => {
			const r = parseInt(color.value.slice(1, 3), 16)
			const g = parseInt(color.value.slice(3, 5), 16)
			const b = parseInt(color.value.slice(5, 7), 16)

			return `rgba(${r}, ${g}, ${b}, 0.1)`
		})

		const border = computed(() => {
			const r = parseInt(color.value.slice(1, 3), 16)
			const g = parseInt(color.value.slice(3, 5), 16)
			const b = parseInt(color.value.slice(5, 7), 16)

			return `rgba(${r}, ${g}, ${b}, 0.2)`
		})

		return {
			amountNormalized,
			active,
			color,
			background,
			border
		}
	}
})
