
import countries from '../../composables/countriesList'
import Lang from './Lang.vue'
import { defineComponent, onMounted, reactive } from '@vue/runtime-core'
import { directive as clickOutside } from 'click-outside-vue3'
import { toRefs } from 'vue'

export default defineComponent({
	props: {
		right: {
			type: Boolean,
			default: false
		},
		full: {
			type: Boolean,
			default: false
		},
		fullList: {
			type: Boolean,
			default: false
		},
		modelValue: {
			type: String,
			required: true
		}
	},
	components: {
		Lang
	},
	directives: {
		clickOutside
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const state = reactive({
			active: false,
			langCodes: [] as string[]
		})

		onMounted(() => {
			countries.sort((a, b) => {
				if (a.name > b.name) return 1
				if (a.name < b.name) return -1
				return 0
			})

			state.langCodes = countries.map(({ code }) => code)
		})

		const close = () => {
			state.active = false
		}

		const trigger = () => {
			state.active = !state.active
		}

		const select = (code: string) => {
			emit('update:modelValue', code)
			close()
		}

		return {
			...toRefs(state),
			close,
			trigger,
			select
		}
	}
})
