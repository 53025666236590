<template>
	<div class="cart-stats">
		<div class="stats-item">
			<div class="item-title">{{ t('CartStats.selected') }}</div>
			<div class="item-value">{{ skins }}</div>
		</div>
		<div class="stats-item">
			<div class="item-title">{{ t('CartStats.total') }}</div>
			<div class="item-value bold">{{ totalNormalized }}</div>
		</div>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		skins: {
			type: Number,
			required: true
		},
		total: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const { t } = useI18n({ useScope: 'global' })

		const totalNormalized = computed(() => {
			return `$${props.total / 100}`
		})

		return { t, totalNormalized }
	}
})
</script>
<style scoped>
.cart-stats {
	padding: 0 20px;
	border: 1px solid #2e3039;
	border-radius: 8px;
}

.stats-item {
	display: flex;
	padding: 20px 0;
	justify-content: space-between;
}

.stats-item:not(:last-of-type) {
	border-bottom: 1px solid #2e3039;
}

.item-title {
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: RGBA(255, 255, 255, 0.5);
}

.item-value {
	font-weight: normal;
	font-size: 16px;
	line-height: 21px;
	color: #ffffff;
}

.item-value.bold {
	font-weight: bold;
}
</style>
