<template>
	<div class="check-box" @click="click">
		<div class="box" :class="{ active: modelValue }">
			<svg
				width="12"
				height="8"
				viewBox="0 0 12 8"
				fill="none"
				class="box-icon"
				v-if="modelValue"
			>
				<path
					d="M10.6154 0.826904L4.26926 7.17306L1.38464 4.28844"
					stroke="white"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
		<div class="box-text">
			<slot />
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	props: {
		modelValue: {
			type: Boolean,
			required: true
		}
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const click = () => {
			emit('update:modelValue', !props.modelValue)
		}

		return {
			click
		}
	}
})
</script>
<style scoped>
.check-box {
	display: grid;
	grid-template-columns: 20px 1fr;
	align-items: center;
	grid-gap: 10px;
	cursor: pointer;
	width: fit-content;
}

.box {
	background: none;
	box-sizing: border-box;
	border: 2px solid rgba(162, 163, 172, 0.3);
	border-radius: 6px;
	height: 20px;
	width: 20px;
	display: flex;
}

.box.active {
	background: #2aaafe;
	border: none;
}

.box-icon {
	margin: auto;
}

.box-text {
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: #fff;
	opacity: 0.5;
}

.check-box:hover .box-text {
	opacity: 1;
}
</style>
