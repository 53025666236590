import { createI18n } from 'vue-i18n'
import router from '../router'
import en from '../locales/en.json'
import { RouteRecordRaw } from 'vue-router'
import { WritableComputedRef } from '@vue/reactivity'

export const i18n = createI18n({
	legacy: false,
	locale: 'en',
	fallbackLocale: 'en',
	messages: { en },
	silentFallbackWarn: true
})

export type LangCode = 'en' |
	'ru' |
	'es' |
	'pt' |
	'tr' |
	'it' |
	'fr' |
	'cz' |
	'de' |
	'sv' |
	'ro' |
	'pl'

interface Lang {
	code: LangCode
	name: string
}

export function languages(): Lang[] {
	return [
		{
			code: 'en',
			name: 'English'
		},
		{
			code: 'ru',
			name: 'Russian'
		},
		{
			code: 'es',
			name: 'Spanish'
		},
		{
			code: 'pt',
			name: 'Portuguese'
		},
		{
			code: 'tr',
			name: 'Turkish'
		},
		{
			code: 'it',
			name: 'Italian'
		},
		{
			code: 'fr',
			name: 'French'
		},
		{
			code: 'cz',
			name: 'Czech'
		},
		{
			code: 'de',
			name: 'Deutsch'
		},
		{
			code: 'sv',
			name: 'Swedish'
		},
		{
			code: 'ro',
			name: 'Romanian'
		},
		{
			code: 'pl',
			name: 'Polish'
		}
	]
}
const loadedLanguages = ['en']

function saveLanguage(lang: string) {
	localStorage.setItem('lang', lang)
}

let loading = false
function setLoading(value: boolean) {
	loading = value

	if (!value && queue.length) {
		const [lang] = queue
		queue.splice(0, 1)
		useLocaleAsync(lang)
	}
}

function setI18nLanguage(lang: string) {
	(i18n.global.locale as WritableComputedRef<string>).value = lang
	saveLanguage((i18n.global.locale as WritableComputedRef<string>).value)
	setLoading(false)
	return lang
}

function getBrowserLang() {
	let langs = ''
	if (navigator.languages && navigator.languages.length) {
		langs = navigator.languages.toString().toLowerCase()
	} else if (navigator.language) {
		langs = navigator.language.toLowerCase()
	}

	const languagesLocal = languages()
	for (let i = 0; i < languagesLocal.length; i++) {
		const { code } = languagesLocal[i]
		if (langs.includes(code)) {
			return code
		}
	}

	if (langs.includes('en') || langs.includes('us')) {
		return 'en'
	}

	return null
}

export function initLang(): string {
	if (!localStorage.getItem('lang') && getBrowserLang()) {
		return getBrowserLang() as string
	} else if (!localStorage.getItem('lang')) {
		return 'en'
	}

	return localStorage.getItem('lang') as string
}

const queue: (string | null | undefined)[] = []

export async function useLocaleAsync(lang?: string | null) {
	if (loading) {
		return queue.push(lang)
	}
	
	if (!lang) {
		lang = initLang()
		console.log(`[i18n] init ${lang}`)
	} else {
		saveLanguage(lang)
	}

	if ((i18n.global.locale as WritableComputedRef<string>).value === lang) {
		console.log('[i18n] same locale')
		return Promise.resolve(setI18nLanguage(lang))
	}
	if (loadedLanguages.includes(lang as string)) {
		console.log('[i18n] already loaded')
		return Promise.resolve(setI18nLanguage(lang as string))
	}

	const tempLang = (i18n.global.locale as WritableComputedRef<string>).value + ''

	console.log(`[i18n] loading ${lang}...`)
	console.log(`[i18n] temp ${tempLang}`)

	setLoading(true)

	return import(
		/* webpackChunkName: "lang-[request]" */ `../locales/${lang}.json`
	).then((messages) => {
		console.log(`[i18n] loaded ${lang}`)
		i18n.global.setLocaleMessage(lang as string, messages.default)
		loadedLanguages.push(lang as string)
		setI18nLanguage(lang as string)
		return
	}).catch((err) => {
		console.error(`[i18n]: `, err)
		setLoading(false)
	})
}

export function generateLangRoutes(): RouteRecordRaw[] {
	return languages().map(({ code }) => {
		return {
			path: `/${code}`,
			name: code,
			redirect: '/',
			beforeEnter(to, from, next) {
				useLocaleAsync(code)
				next('/')
			}
		}
	})
}

router.afterEach((to, from) => {
	if (!['/', '/upgrade'].includes(to.path)) {
		return
	}

	useLocaleAsync()
})
