
import { useModalStore } from '@/store/modal'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import TooltipHover from '../TooltipHover/index.vue'

export default defineComponent({
	props: {
		size: {
			type: Number,
			default: 24
		}
	},
	components: {
		TooltipHover
	},
	setup(props) {
		const dim = computed(() => {
			return `${props.size}px`
		})

		const icon = computed(() => {
			return `${Math.max(props.size / 2 + 2, 12)}px`
		})

		const borderRadius = computed(() => {
			return `${props.size / 3 > 10 ? 10 : props.size / 3}px`
		})

		const modal = useModalStore()

		const openDeposit = () => {
			modal.deposit.enabled = true
		}

		const { t } = useI18n({ useScope: 'global' })

		return {
			dim,
			icon,
			borderRadius,
			openDeposit,
			t
		}
	}
})
