
import { InventoryEntityExtended } from '@/store/inventory'
import { defineComponent, PropType } from 'vue'
import Item from '../InventoryItem/index.vue'

export default defineComponent({
	props: {
		items: {
			type: Object as PropType<InventoryEntityExtended[]>,
			required: true
		},
		selectedItems: {
			type: Object as PropType<number[]>,
			required: true
		},
		imageSize: {
			type: Number,
			default: 466
		}
	},
	components: {
		Item
	},
	emits: {
		select: (payload: InventoryEntityExtended) => {
			return true
		}
	},
	setup(props, { emit }) {
		const select = (item: InventoryEntityExtended) => {
			if (typeof item.selectable !== 'undefined' && !item.selectable && !props.selectedItems.includes(item.id)) {
				return
			}

			emit('select', item)
		}

		return {
			select
		}
	}
})
