import jwt from 'jsonwebtoken'

export function getCookieValue(a: string): string | undefined {
	const b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)')
	return b ? b.pop() : ''
}

interface Token {
	exp: number
}

export function useAuthToken(): string | null {
	const token = getCookieValue('token') ? getCookieValue('token') : (localStorage ? localStorage.getItem('token') : null)

	if (!token) {
		return ''
	}

	try {
		const { exp } = jwt.decode(token) as Token

		if (exp < Math.floor(Date.now() / 1000)) {
			return null
		}

		return token
	} catch (err) {
		return null
	}
}

export function useUserID() {
	const token = useAuthToken()

	if (!token) {
		return null
	}

	const decoded = jwt.decode(token)

	if (!decoded) {
		return null
	}

	if (!decoded.sub) {
		return null
	}

	if (typeof decoded.sub === 'function') {
		return parseInt(decoded.sub(), 10)
	}

	return parseInt(decoded.sub, 10)
}

