import { defineStore } from 'pinia'

export const useResponsiveStore = defineStore({
	id: 'responsive',
	state() {
		return {
			mobile: false,
			vh: `0px`
		}
	}
})
