
import { computed, defineComponent, PropType, reactive, toRefs } from 'vue'
import { directive as clickOutside } from 'click-outside-vue3'

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			required: true
		},
		placeholder: {
			type: String,
			required: true
		},
		items: {
			type: Object as PropType<string[]>,
			required: true
		}
	},
	directives: {
		clickOutside
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const state = reactive({
			active: false
		})

		const toggle = () => {
			state.active = !state.active
		}

		const close = () => {
			state.active = false
		}

		const activeValue = computed(() => {
			return props.modelValue ? props.modelValue : props.placeholder
		})

		const select = (item: string) => {
			emit('update:modelValue', item)
		}

		const filteredItems = computed(() => {
			return props.items.filter(item => item !== props.modelValue)
		})

		return {
			...toRefs(state),
			toggle,
			close,
			activeValue,
			select,
			filteredItems
		}
	}
})
