<template>
	<div class="deposit-btn-container">
		<TooltipHover :fit="true">
			<template #root>
				<div
					class="deposit-btn"
					:style="{ width: dim, height: dim, 'border-radius': borderRadius }"
					@click="openDeposit"
				>
					<svg
						width="10"
						height="10"
						viewBox="0 0 10 10"
						fill="none"
						class="btn-icon"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M4.99988 0.624969C5.40259 0.624969 5.72904 0.951429 5.72904 1.35414V4.2708H8.64571C9.04842 4.2708 9.37488 4.59726 9.37488 4.99997C9.37488 5.40268 9.04842 5.72914 8.64571 5.72914H5.72904V8.6458C5.72904 9.04851 5.40259 9.37497 4.99988 9.37497C4.59717 9.37497 4.27071 9.04851 4.27071 8.6458V5.72914H1.35404C0.951337 5.72914 0.624878 5.40268 0.624878 4.99997C0.624878 4.59726 0.951337 4.2708 1.35404 4.2708H4.27071V1.35414C4.27071 0.951429 4.59717 0.624969 4.99988 0.624969Z"
							fill="currentColor"
						/>
					</svg>
				</div>
			</template>
			<template #text><span v-html="t('DepositBtn.title')" /></template>
		</TooltipHover>
	</div>
</template>
<script lang="ts">
import { useModalStore } from '@/store/modal'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import TooltipHover from '../TooltipHover/index.vue'

export default defineComponent({
	props: {
		size: {
			type: Number,
			default: 24
		}
	},
	components: {
		TooltipHover
	},
	setup(props) {
		const dim = computed(() => {
			return `${props.size}px`
		})

		const icon = computed(() => {
			return `${Math.max(props.size / 2 + 2, 12)}px`
		})

		const borderRadius = computed(() => {
			return `${props.size / 3 > 10 ? 10 : props.size / 3}px`
		})

		const modal = useModalStore()

		const openDeposit = () => {
			modal.deposit.enabled = true
		}

		const { t } = useI18n({ useScope: 'global' })

		return {
			dim,
			icon,
			borderRadius,
			openDeposit,
			t
		}
	}
})
</script>
<style scoped>
.deposit-btn {
	background: #97ed4c;
	display: flex;
	cursor: pointer;
	color: #202442;
	align-items: center;
	justify-content: center;
}

.deposit-btn:hover {
	background: #2AAAFE;
}

.btn-icon {
	max-width: 18px;
	max-height: 18px;
	color: #242731;
}

.deposit-btn:hover .btn-icon {
	color: #fff
}
</style>
