import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { InventoryEntity, ItemEntity } from '../api'
import { useAPI } from '../composables/api'
import { useAlertStore } from './alert'

export interface InventoryEntityExtended extends Partial<InventoryEntity> {
	id: number
	selectable?: boolean
	item: ItemEntity
}

export interface ShopEntity {
	id: number
	item: ItemEntity
}

export const useInventoryStore = defineStore({
	id: 'inventory',
	state() {
		return {
			items: [] as InventoryEntityExtended[],
			selectedItems: [] as ShopEntity[],
			loading: true
		}
	},
	actions: {
		async getItems() {
			this.loading = true

			const api = useAPI()

			try {
				const items = await api({ path: '/inventory' })

				this.items = items
			} catch (err) {
				const alert = useAlertStore()
				const { t } = useI18n({ useScope: 'global' })

				alert.pushAlert({ type: 'danger', text: `${t('Home.errors.getInventory')}: ${err.message}` })
			}

			this.loading = false
		},
		select(item: ShopEntity) {
			if (this.splice(item)) {
				return
			}

			this.push(item)
		},
		clear() {
			this.selectedItems = []
		},
		push(item: ShopEntity) {
			this.selectedItems.push(item)
		},
		splice(item: ShopEntity) {
			let index = null

			for (let i = 0; i < this.selectedItems.length; i++) {
				if (this.selectedItems[i].id === item.id) {
					index = i
					break
				}
			}

			if (index === null) {
				return false
			}

			this.selectedItems.splice(index, 1)

			return true
		}
	}
})
