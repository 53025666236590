<template>
	<div class="currency-dropdown" :class="{ active }">
		<div class="dropdown-btn" @click="active = !active">
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				v-if="modelValue === 'USD'"
			>
				<path
					d="M18.3549 17.087C21.8819 13.622 21.8819 8.063 18.3549 4.598C14.8279 1.133 9.17088 1.133 5.64488 4.598C2.11888 8.063 2.11788 13.622 5.64488 17.087"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M4.1001 21H20.1001H4.1001Z"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M12 6V7.25"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M12 16V14.75"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14.487 9C14.422 8.024 13.617 7.25 12.625 7.25H11.255C10.285 7.25 9.5 8.036 9.5 9.005C9.5 9.81 10.048 10.512 10.828 10.708L13.171 11.296C13.952 11.492 14.499 12.194 14.499 12.999C14.499 13.969 13.713 14.754 12.744 14.754H11.374C10.38 14.754 9.575 13.978 9.511 13"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				v-if="modelValue === 'RUB'"
			>
				<path
					d="M18.3549 17.087C21.8819 13.622 21.8819 8.063 18.3549 4.598C14.8279 1.133 9.17088 1.133 5.64488 4.598C2.11888 8.063 2.11788 13.622 5.64488 17.087"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M4.1001 21H20.1001H4.1001Z"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M13 14H8.5"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.199 16V7H13.376V7C14.549 7 15.5 7.951 15.5 9.124V9.124C15.5 10.297 14.549 11.248 13.376 11.248V11.248H8.5"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				v-if="modelValue === 'EUR'"
			>
				<path
					d="M18.3549 17.087C21.8819 13.622 21.8819 8.06299 18.3549 4.59799C14.8279 1.13299 9.17088 1.13299 5.64488 4.59799C2.11888 8.06299 2.11788 13.622 5.64488 17.087"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M15.2054 7.74508C13.5453 6.08497 10.8552 6.08497 9.19508 7.74508C8.92742 8.01274 8.70292 8.30717 8.52157 8.61975H12.75C13.1642 8.61975 13.5 8.95554 13.5 9.36975C13.5 9.78396 13.1642 10.1198 12.75 10.1198H7.99677C7.93446 10.5373 7.93441 10.9622 7.99663 11.3798H12.75C13.1642 11.3798 13.5 11.7155 13.5 12.1298C13.5 12.544 13.1642 12.8798 12.75 12.8798H8.52099C8.70244 13.1927 8.92714 13.4875 9.19508 13.7554C10.8552 15.4155 13.5453 15.4155 15.2054 13.7554C15.4983 13.4625 15.9732 13.4625 16.2661 13.7554C16.559 14.0483 16.559 14.5232 16.2661 14.8161C14.0202 17.062 10.3803 17.062 8.13442 14.8161C7.56374 14.2454 7.13807 13.5847 6.85741 12.8798H6.75C6.33579 12.8798 6 12.544 6 12.1298C6 11.8071 6.20377 11.532 6.48964 11.4262C6.43675 10.9769 6.43679 10.5226 6.48975 10.0734C6.20382 9.96757 6 9.69246 6 9.36975C6 8.95554 6.33579 8.61975 6.75 8.61975H6.8578C7.13846 7.91517 7.564 7.25483 8.13442 6.68442C10.3803 4.43853 14.0202 4.43853 16.2661 6.68442C16.559 6.97731 16.559 7.45219 16.2661 7.74508C15.9732 8.03797 15.4983 8.03797 15.2054 7.74508Z"
					fill="currentColor"
				/>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M4.1001 21H20.1001H4.1001Z"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			<svg width="8" height="5" viewBox="0 0 8 5" fill="none" class="btn-icon">
				<path
					d="M1 1L4 4L7 1"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
		<div class="dropdown-list" @click="active = false">
			<div class="list-item" :class="{ active: modelValue === 'RUB' }" @click="select('RUB')">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path
						d="M18.3549 17.087C21.8819 13.622 21.8819 8.063 18.3549 4.598C14.8279 1.133 9.17088 1.133 5.64488 4.598C2.11888 8.063 2.11788 13.622 5.64488 17.087"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4.1001 21H20.1001H4.1001Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M13 14H8.5"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M10.199 16V7H13.376V7C14.549 7 15.5 7.951 15.5 9.124V9.124C15.5 10.297 14.549 11.248 13.376 11.248V11.248H8.5"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<div class="item-text">Rubles</div>
			</div>
			<div class="list-item" :class="{ active: modelValue === 'USD' }" @click="select('USD')">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path
						d="M18.3549 17.087C21.8819 13.622 21.8819 8.063 18.3549 4.598C14.8279 1.133 9.17088 1.133 5.64488 4.598C2.11888 8.063 2.11788 13.622 5.64488 17.087"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4.1001 21H20.1001H4.1001Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 6V7.25"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M12 16V14.75"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						d="M14.487 9C14.422 8.024 13.617 7.25 12.625 7.25H11.255C10.285 7.25 9.5 8.036 9.5 9.005C9.5 9.81 10.048 10.512 10.828 10.708L13.171 11.296C13.952 11.492 14.499 12.194 14.499 12.999C14.499 13.969 13.713 14.754 12.744 14.754H11.374C10.38 14.754 9.575 13.978 9.511 13"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<div class="item-text">Dollars</div>
			</div>
			<div class="list-item" :class="{ active: modelValue === 'EUR' }" @click="select('EUR')">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path
						d="M18.3549 17.087C21.8819 13.622 21.8819 8.06299 18.3549 4.59799C14.8279 1.13299 9.17088 1.13299 5.64488 4.59799C2.11888 8.06299 2.11788 13.622 5.64488 17.087"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M15.2054 7.74508C13.5453 6.08497 10.8552 6.08497 9.19508 7.74508C8.92742 8.01274 8.70292 8.30717 8.52157 8.61975H12.75C13.1642 8.61975 13.5 8.95554 13.5 9.36975C13.5 9.78396 13.1642 10.1198 12.75 10.1198H7.99677C7.93446 10.5373 7.93441 10.9622 7.99663 11.3798H12.75C13.1642 11.3798 13.5 11.7155 13.5 12.1298C13.5 12.544 13.1642 12.8798 12.75 12.8798H8.52099C8.70244 13.1927 8.92714 13.4875 9.19508 13.7554C10.8552 15.4155 13.5453 15.4155 15.2054 13.7554C15.4983 13.4625 15.9732 13.4625 16.2661 13.7554C16.559 14.0483 16.559 14.5232 16.2661 14.8161C14.0202 17.062 10.3803 17.062 8.13442 14.8161C7.56374 14.2454 7.13807 13.5847 6.85741 12.8798H6.75C6.33579 12.8798 6 12.544 6 12.1298C6 11.8071 6.20377 11.532 6.48964 11.4262C6.43675 10.9769 6.43679 10.5226 6.48975 10.0734C6.20382 9.96757 6 9.69246 6 9.36975C6 8.95554 6.33579 8.61975 6.75 8.61975H6.8578C7.13846 7.91517 7.564 7.25483 8.13442 6.68442C10.3803 4.43853 14.0202 4.43853 16.2661 6.68442C16.559 6.97731 16.559 7.45219 16.2661 7.74508C15.9732 8.03797 15.4983 8.03797 15.2054 7.74508Z"
						fill="currentColor"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M4.1001 21H20.1001H4.1001Z"
						stroke="currentColor"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<div class="item-text">Euros</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue"

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			required: true
		}
	},
	emits: ['update:modelValue'],
	setup(props, { emit }) {
		const state = reactive({
			active: false
		})

		const select = (value: string) => {
			emit('update:modelValue', value)
		}

		return {
			...toRefs(state),
			select
		}
	}
})
</script>
<style scoped>
.currency-dropdown {
	position: relative;
	color: #5F6068;
	box-sizing: border-box;
	cursor: pointer;
	user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

.dropdown-btn {
	display: flex;
	align-items: center;
}

.dropdown-btn:hover,
.list-item:hover,
.list-item.active {
	color: #fff;
}

.currency-dropdown.active .dropdown-btn {
	border-radius: 10px 10px 0px 0px;
}

.btn-text {
	height: 11px;
	font-weight: 600;
	font-size: 16px;
	line-height: 11px;
	margin: auto 0;
}

.btn-icon {
	margin-left: 5px;
}

.currency-dropdown.active .btn-icon {
	transform: rotate(180deg);
}

.dropdown-list {
	display: none;
	position: absolute;
	top: 39px;
	left: -10px;
	padding: 10px 20px 10px 10px;
	background: #1c1d24;
	border-radius: 10px;
	grid-template-rows: repeat(24px, auto-fill);
	gap: 15px;
}

.currency-dropdown.active .dropdown-list {
	display: grid;
}

.list-item {
	display: flex;
	align-items: center;
}

.item-text {
	font-weight: 500;
	font-size: 13px;
	line-height: 16px;
	margin-left: 5px;
}
</style>
