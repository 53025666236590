
import { useInput } from '@/composables/useInput'
import { defineComponent } from 'vue'
import { mask } from 'vue-the-mask'

export default defineComponent({
	props: {
		placeholder: {
			type: String,
			default: ''
		},
		modelValue: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		mask: {
			type: [String, Array],
			default: ''
		}
	},
	directives: {
		mask
	},
	emits: ['update:modelValue', 'enter'],
	setup(_, ctx) {
		return useInput(ctx)
	}
})
