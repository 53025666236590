<template>
	<div class="cart-item" :style="{ '--color': color }">
		<div class="item-bg" />
		<svg width="12" height="12" viewBox="0 0 12 12" fill="none" class="item-caret">
			<path
				d="M4 12C1.79086 12 -6.7098e-08 10.2091 -1.49868e-07 8L-4.49603e-07 9.53674e-07L6 6L12 12L4 12Z"
				:fill="color"
			/>
		</svg>
		<div class="item-exterior" v-if="exterior">{{ exteriorNormalized }}</div>
		<ItemIcon :icon="icon" :size="120" :zoom="zoom" class="item-icon" />
		<div class="item-desc">
			<div class="desc-type">{{ typeNormalized }}</div>
			<div class="desc-name">{{ name }}</div>
		</div>
		<div class="item-price">{{ priceNormalized }}</div>
		<svg width="8" height="8" viewBox="0 0 8 8" fill="none" class="item-remove" @click="remove">
			<g opacity="0.5">
				<path
					d="M7 1L1 7"
					stroke="white"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M1 1L7 7"
					stroke="white"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue'
import ItemIcon from '../ItemIcon/index.vue'

export default defineComponent({
	props: {
		icon: {
			type: String,
			required: true
		},
		type: {
			type: String,
			required: true
		},
		stattrak: {
			type: Boolean,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		price: {
			type: Number,
			required: true
		},
		color: {
			type: String,
			required: true,
			validator: (color: string) => {
				if (color[0] !== "#") {
					return false;
				}
				if (color.length !== 7) {
					return false;
				}
				return true;
			}
		},
		exterior: {
			type: String
		}
	},
	emits: ["remove"],
	setup(props, { emit }) {
		const priceNormalized = computed(() => {
			return `$${(props.price / 100).toFixed(2)}`;
		});
		const exteriorNormalized = computed(() => {
			if (props.exterior === "Battle-Scarred") {
				return "BS";
			}
			if (props.exterior === "Well-Worn") {
				return "WW";
			}
			if (props.exterior === "Field-Tested") {
				return "FT";
			}
			if (props.exterior === "Minimal Wear") {
				return "MW";
			}
			if (props.exterior === "Factory New") {
				return "FN";
			}
			return "";
		});
		const typeNormalized = computed(() => {
			return `${props.stattrak ? "ST™ " : ""}${props.type}`;
		});
		const zoom = computed(() => {
			return props.type.includes("Graffiti") ? 1.5 : 1;
		});
		const remove = () => {
			emit("remove");
		};
		return {
			priceNormalized,
			exteriorNormalized,
			typeNormalized,
			zoom,
			remove
		};
	},
	components: { ItemIcon }
})
</script>
<style scoped>
.cart-item {
	position: relative;
	border: 1px solid #262833;
	box-sizing: border-box;
	border-radius: 6px;
	overflow: hidden;
	height: 90px;
	display: flex;
	padding: 15px;
	align-items: center;
}

.item-bg {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: linear-gradient(
			62.12deg,
			var(--color) 0%,
			rgba(32, 33, 42, 0) 65.4%
		),
		#1e2029;
	opacity: 0.3;
}

.item-caret {
	position: absolute;
	left: 5px;
	bottom: 5px;
}

.item-exterior {
	font-family: "Montserrat";
	position: absolute;
	top: 5px;
	left: 5px;
	font-weight: 700;
	font-size: 8px;
	line-height: 10px;
	color: #a2a3ac;
	padding: 2px 3px;
	text-align: center;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 4px;
	z-index: 2;
}

.item-remove {
	position: absolute;
	top: 10px;
	right: 10px;
	opacity: 0.5;
	cursor: pointer;
}

.item-remove:hover {
	opacity: 1;
}

.item-icon {
	width: 60px;
	height: 60px;
	position: relative;
	z-index: 1;
	flex-shrink: 0;
}

.item-desc {
	margin: 0 20px;
	position: relative;
	z-index: 1;
}

.desc-type {
	font-weight: normal;
	font-size: 11px;
	line-height: 15px;
	color: var(--color);
}

.desc-name {
	margin-top: 5px;
	font-weight: bold;
	font-size: 16px;
	line-height: 16px;
	color: #ffffff;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.item-price {
	font-weight: bold;
	font-size: 20px;
	line-height: 26px;
	color: #ffffff;
	margin-left: auto;
	position: relative;
	z-index: 1;
}
</style>
