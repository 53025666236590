<template>
	<router-link :to="link" custom v-slot="{ navigate }">
		<div class="user-avatar" @click="navigate">
			<div class="avatar-img-wrap">
				<img :src="avatar" class="avatar-img" :class="{ blur }" />
			</div>
		</div>
	</router-link>
</template>
<script lang="ts">
import { useUserID } from '@/composables/getAuthToken'
import { computed, defineComponent } from '@vue/runtime-core'

export default defineComponent({
	props: {
		avatar: {
			type: String,
			required: true
		},
		userID: {
			type: Number,
			required: true
		},
		prefix: {
			type: String,
			default: ''
		}
	},
	setup(props) {
		const userID = useUserID()

		const me = computed(() => {
			return props.userID !== userID
		})
		const link = computed(() => {
			return me.value ? `/user/${props.userID}` : '/profile'
		})
		const blur = computed(() => {
			return localStorage.getItem('streamerMode') === '1' && me.value
		})

		return {
			me,
			link,
			blur
		}
	}
})
</script>
<style scoped>
.user-avatar {
	position: relative;
	height: 44px;
	width: 44px;
	cursor: pointer;
}

.avatar-img-wrap {
	height: 44px;
	width: 44px;
	border-radius: 50%;
	overflow: hidden;
}

.avatar-img {
	height: 44px;
	width: 44px;
	border-radius: 50%;
}

.avatar-img.blur {
	filter: blur(4px);
}

.avatar-animation {
	height: 70px;
	width: 70px;
	position: absolute;
	top: -15px;
	left: -15px;
}

.avatar-lvl {
	position: absolute;
	top: 0;
	left: 0;
}
</style>
