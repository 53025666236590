
import { directive as clickOutside } from 'click-outside-vue3'
import UserAvatar from '../UserAvatar/index.vue'
import BaseButton from '../BaseButton/index.vue'
import DepositBtn from '../DepositBtn/index.vue'
import { useUserID } from '@/composables/getAuthToken'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
	props: {
		avatar: {
			type: String,
			required: true
		},
		balanceUsd: {
			type: Number,
			required: true
		}
	},
	components: {
		UserAvatar,
		BaseButton,
		DepositBtn
	},
	directives: {
		clickOutside
	},
	setup(props) {
		const { t } = useI18n()
		const userID = useUserID()

		const balanceNormalized = computed(() => {
			return `$${(props.balanceUsd / 100).toFixed(2)}`
		})

		return {
			userID,
			t,
			balanceNormalized
		}
	}
})
